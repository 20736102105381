import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import caretDown from '../../../assets/svg/directional/caret.svg'
import { TicketContainer, TicketTable } from './TicketTable'
import { FreshTicket, TicketStatus, TicketType } from '../../../api/tickets'
import { Trans, useTranslation } from 'react-i18next'
import { CountUp } from '../../shared/CountUp'
import { CustomIcon } from '../../shared/CustomIcon'
import { Button, ButtonStyle, ButtonType } from '../../shared/buttons/Button'
import { GradientText, GrayText, Heading3, Heading4 } from '../../shared/TextComponents'
import { SearchInput } from '../../shared/filters/SearchInput'
import { DateRangePicker, getMaxDate, getMinDate } from '../../shared/filters/DateRangePicker'

export const ticketStatusColor = {
  OPEN: { gradient: 'from-primary-400 to-primary-500', flat: 'bg-primary-500' },
  PENDING: { gradient: 'from-primary-500/90 to-primary-600/90', flat: 'bg-primary-600/90' },
  RESOLVED: { gradient: 'from-success-100 to-success-500', flat: 'bg-success-500' },
  CLOSED: { gradient: 'from-gray-200 to-gray-400', flat: 'bg-gray-400' }
}

interface TicketsByStatusProps {
  status: TicketStatus
  tickets: FreshTicket[]
}

export const TicketsStatusContainer = ({ status, tickets }: TicketsByStatusProps) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [filteredTickets, setFilteredTickets] = useState<FreshTicket[]>(tickets)
  const [searchText, setSearchText] = useState<string>('')
  const [createdMinDate, setCreatedMinDate] = useState<Date | null>(null)
  const [createdMaxDate, setCreatedMaxDate] = useState<Date>(new Date())
  const [dueMinDate, setDueMinDate] = useState<Date | null>(null)
  const [dueMaxDate, setDueMaxDate] = useState<Date>(new Date())
  const [createdStartDate, setCreatedStartDate] = useState<Date | null>(createdMinDate)
  const [createdEndDate, setCreatedEndDate] = useState<Date | null>(createdMaxDate)
  const [dueStartDate, setDueStartDate] = useState<Date | null>(dueMinDate)
  const [dueEndDate, setDueEndDate] = useState<Date | null>(dueMaxDate)

  useEffect(() => {
    setCreatedMinDate(getMinDate(tickets.map(ticket => ticket.ticketCreatedAt)))
    setCreatedMaxDate(getMaxDate(tickets.map(ticket => ticket.ticketCreatedAt)))
    setDueMinDate(getMinDate(tickets.map(ticket => ticket.dueBy)))
    setDueMaxDate(getMaxDate(tickets.map(ticket => ticket.dueBy)))
    createdMinDate?.setHours(0, 0, 0, 0)
    createdMaxDate?.setHours(23, 59, 59, 999)
    dueMinDate?.setHours(0, 0, 0, 0)
    dueMaxDate?.setHours(23, 59, 59, 999)
  }, [tickets])

  useEffect(() => {
    setCreatedStartDate(createdMinDate)
    setCreatedEndDate(createdMaxDate)
    setDueStartDate(dueMinDate)
    setDueEndDate(dueMaxDate)
  }, [createdMaxDate, createdMinDate, dueMinDate, dueMaxDate])

  useEffect(() => {
    if (searchText !== '' && filteredTickets.length > 0) {
      setCreatedStartDate(getMinDate(filteredTickets.map(ticket => ticket.ticketCreatedAt)))
      setCreatedEndDate(getMaxDate(filteredTickets.map(ticket => ticket.ticketCreatedAt)))
      setDueStartDate(getMinDate(filteredTickets.map(ticket => ticket.dueBy)))
      setDueEndDate(getMaxDate(filteredTickets.map(ticket => ticket.dueBy)))
    } else {
      setCreatedStartDate(createdMinDate)
      setCreatedEndDate(createdMaxDate)
      setDueStartDate(dueMinDate)
      setDueEndDate(dueMaxDate)
    }
  }, [searchText])

  useEffect(() => {
    searchText !== '' ||
    JSON.stringify(createdStartDate?.getTime()) !== JSON.stringify(createdMinDate?.getTime()) ||
    JSON.stringify(createdEndDate?.getTime()) !== JSON.stringify(createdMaxDate?.getTime()) ||
    JSON.stringify(dueStartDate?.getTime()) !== JSON.stringify(dueMinDate?.getTime()) ||
    JSON.stringify(dueEndDate?.getTime()) !== JSON.stringify(dueMaxDate?.getTime())
      ? setFilteredTickets(
          tickets
            .filter(
              ticket =>
                ticket.subject.toLowerCase().includes(searchText.toLowerCase()) ||
                ticket.ticketId.toString().includes(searchText)
            )
            .filter(ticket =>
              createdStartDate && createdEndDate
                ? ticket.ticketCreatedAt >= createdStartDate && ticket.ticketCreatedAt <= createdEndDate
                : ticket
            )
            .filter(ticket =>
              dueStartDate && dueEndDate ? ticket.dueBy >= dueStartDate && ticket.dueBy <= dueEndDate : ticket
            )
        )
      : setFilteredTickets(tickets)
  }, [createdEndDate, searchText, createdStartDate, tickets, dueStartDate, dueEndDate])

  return (
    <>
      <div className={'flex flex-col bg-gray-600/90 rounded-md'}>
        <div className={`w-full rounded-t-md pb-1 ${ticketStatusColor[status].flat}`} />
        <CollapsibleSummary setIsOpen={setIsOpen} isOpen={isOpen} status={status} tickets={tickets} />
        <Wrapper
          className={`${isOpen ? 'py-6 border-t border-dashed ' : 'py-0 border-none'} transition-all ease-in-out duration-150 border-gray-500`}
        >
          {isOpen && (
            <div className={'flex flex-col gap-2 w-full'}>
              <Heading4>{t('tickets.findTicket')}</Heading4>
              <TicketFilterWrapper>
                <div>
                  <GrayText className={'text-90'}>{t('tickets.searchByContent')}</GrayText>
                  <SearchInput
                    id={`${status}-tickets-search-input`}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    placeholder={t('tickets.searchPlaceholder')}
                  />
                </div>
                {createdMinDate && (
                  <DateRangePicker
                    label={t('tickets.createdAtDateRange')}
                    minDate={createdMinDate}
                    startDate={createdStartDate}
                    endDate={createdEndDate}
                    setStartDate={setCreatedStartDate}
                    setEndDate={setCreatedEndDate}
                  />
                )}
                {dueMinDate && (
                  <DateRangePicker
                    label={t('tickets.dueDateRange')}
                    minDate={dueMinDate}
                    startDate={dueStartDate}
                    endDate={dueEndDate}
                    setStartDate={setDueStartDate}
                    setEndDate={setDueEndDate}
                  />
                )}
              </TicketFilterWrapper>
              {filteredTickets.length !== tickets.length && (
                <GrayText className={'text-90 p-2 pb-0'}>
                  <Trans>{t('tickets.ticketsFound', { count: filteredTickets.length })}</Trans>
                </GrayText>
              )}
            </div>
          )}
        </Wrapper>
      </div>
      {isOpen && <TicketTable tickets={filteredTickets} />}
    </>
  )
}

const TicketFilterWrapper = styled.div`
  ${tw`flex flex-wrap items-center gap-8 w-full`}
  > div {
    ${tw`w-full sm:max-w-max sm:min-w-[300px]`}
  }
`

interface CollapsibleSummaryProps extends TicketsByStatusProps {
  setIsOpen: (value: boolean) => void
  isOpen: boolean
}

const CollapsibleSummary = ({ status, tickets, isOpen, setIsOpen }: CollapsibleSummaryProps) => {
  const { t } = useTranslation()
  const countStyles = `font-black text-300 ${ticketStatusColor[status].gradient}`
  const incidentCount = tickets.filter(ticket => ticket.type === TicketType.INCIDENT).length
  const majorIncidentCount = tickets.filter(ticket => ticket.type === TicketType.MAJOR_INCIDENT).length
  const serviceRequestCount = tickets.filter(ticket => ticket.type === TicketType.SERVICE_REQUEST).length
  const countsByType = []
  if (majorIncidentCount > 0) countsByType.push(t('tickets.ticketCounts.majorIncident', { count: majorIncidentCount }))
  if (incidentCount > 0) countsByType.push(t('tickets.ticketCounts.incident', { count: incidentCount }))
  if (serviceRequestCount > 0)
    countsByType.push(t('tickets.ticketCounts.serviceRequest', { count: serviceRequestCount }))

  return (
    <Wrapper className={'cursor-pointer py-6 hover:bg-gray-600'} onClick={() => setIsOpen(!isOpen)}>
      <div className={'flex items-center gap-4'}>
        <GradientText className={countStyles}>
          <CountUp countTo={tickets.length} id={`qa-tickets-${status}-count`} />
        </GradientText>
        <div className={'flex flex-col'}>
          <Heading3>{status}</Heading3>
          <GrayText>{countsByType.join(' - ')}</GrayText>
        </div>
      </div>
      <Button
        clickHandler={() => setIsOpen(!isOpen)}
        type={ButtonType.ICON}
        buttonStyle={ButtonStyle.GHOST}
        value={
          <CustomIcon
            path={caretDown}
            styles={`w-6 h-6 bg-gray-50 ${isOpen ? 'rotate-180' : 'rotate-0'}`}
            alt={t('common.open')}
          />
        }
      />
    </Wrapper>
  )
}

const Wrapper = styled(TicketContainer)`
  ${tw`flex justify-between items-center gap-5 w-full`}
`
