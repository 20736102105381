import React, { useContext, useEffect, useState } from 'react'
import { useCancelToken } from '../../../../api/client'
import { useErrorHandling } from '../../../../hooks/handleError'
import { ScrollTableRowItem } from '../../../shared/containers/ScrollTable'
import { formatDate } from '../../../../utils/formats'
import { useTranslation } from 'react-i18next'
import { CapitalizedText, ErrorText, GrayText } from '../../../shared/TextComponents'
import { Button, ButtonSize, ButtonStyle, ButtonType } from '../../../shared/buttons/Button'
import { ModalContext } from '../../../../state/context/ModalContext'
import { Controller, useForm } from 'react-hook-form'
import { ModalActions } from '../../../shared/modal/Modal'
import { CustomInput, CustomLabel } from '../../../shared/filters/FormComponents'
import {
  AwsIntegrationRequest,
  createAwsIntegration,
  getAwsRegionOptions
} from '../../../../api/admin/integrations/aws'
import Select, { ActionMeta, Props } from 'react-select'
import { MessageContext, MessageType } from '../../../../state/context/MessageContext'
import {
  selectControlStyles,
  SelectDropdownIndicator,
  SelectIndicatorSeparator,
  selectMenuListStyles,
  selectNoOptionsStyles,
  selectOptionStyles
} from '../../../shared/filters/ReactSelectStyles'
import { FilterOption } from '../../../shared/filters/FilterSelect'
import {
  IntegrationModalHighlight,
  IntegrationsModalBody,
  IntegrationsModalInputs,
  IntegrationsModalTitle
} from '../Shared'
import { IntegrationsLayout } from '../IntegrationsLayout'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { firstLogin } from '../../../../state/storage'
import { StepMeter } from '../../../shared/StepMeter'
import { AwsAccountType } from './AwsIntegrationDetails'
import { UserInfoContext } from '../../../../state/context/UserInfoContext'
import { CustomIcon } from '../../../shared/CustomIcon'
import alertIcon from '../../../../assets/svg/symbols/alert-2.svg'
import { AwsIntegrationsState, getIntegrationStates } from '../../../../api/settings/profile'

export const AwsIntegrations = () => {
  const { setModal } = useContext(ModalContext)
  const { setMessage } = useContext(MessageContext)
  const { integrationStates, setIntegrationStates } = useContext(UserInfoContext)
  const { createCancelToken } = useCancelToken()
  const { t } = useTranslation()
  const handleError = useErrorHandling()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const isFirst = Boolean(searchParams.get('first'))
  const [step, setStep] = useState(isFirst ? 1 : 0)
  const [stepTitle, setStepTitle] = useState<string>(
    t('admin.integrations.aws.accountDetails', { type: AwsAccountType.MASTER })
  )

  useEffect(() => {
    step === 2
      ? setStepTitle(t('admin.integrations.aws.accountDetails', { type: AwsAccountType.SECURITY }))
      : step === 3
        ? setStepTitle(t('admin.integrations.aws.accountDetails', { type: AwsAccountType.CONFIG }))
        : setStepTitle(t('admin.integrations.aws.accountDetails', { type: AwsAccountType.MASTER }))

    if (isFirst || step >= 1) {
      openCreateModal()
    }
  }, [step, stepTitle, createCancelToken, handleError])

  const createIntegration = (request: AwsIntegrationRequest) => {
    const cancelToken = createCancelToken()
    createAwsIntegration(request, cancelToken.token)
      .then(resp => {
        getIntegrationStates(cancelToken.token).then(setIntegrationStates).catch(handleError)
        setModal(null)
        setMessage({ type: MessageType.SUCCESS, message: t('admin.integrations.aws.addSuccessToast') })
        isFirst && searchParams.delete('first')
        !firstLogin() && navigate(resp.masterAccountId)
      })
      .catch(handleError)
  }

  const openCreateModal = () => {
    setModal({
      header: <StepMeter currentStep={step} totalSteps={3} description={t('admin.integrations.aws.createTitle')} />,
      returnAction: isFirst ? () => navigate(-1) : undefined,
      body: (
        <CreateIntegrationModal submitAction={createIntegration} step={step} setStep={setStep} stepTitle={stepTitle} />
      )
    })
  }

  return (
    <IntegrationsLayout
      noData={!integrationStates.aws}
      heading={t('admin.integrations.aws.title')}
      headerActions={
        <Button
          clickHandler={() => {
            setStep(1)
            openCreateModal()
          }}
          value={t('admin.integrations.addNew')}
        />
      }
      scrollTableProps={{
        smallScreenTitle: t('admin.integrations.integrations'),
        sortable: false,
        customColumns: 'repeat(5, 1fr) minmax(90px, auto)',
        titles: [
          t('admin.integrations.name'),
          t('admin.integrations.accountId'),
          t('admin.integrations.aws.role'),
          t('admin.integrations.lastIntegrationAt'),
          t('admin.integrations.status')
        ],
        rows:
          integrationStates?.aws?.map(account => (
            <>
              <ScrollTableRowItem>{account.masterAccountName}</ScrollTableRowItem>
              <ScrollTableRowItem>{account.masterAccountId}</ScrollTableRowItem>
              <ScrollTableRowItem>{account.role}</ScrollTableRowItem>
              <ScrollTableRowItem>
                {formatDate(account.lastOrganizationIntegrationAt, true, true, true)}
              </ScrollTableRowItem>
              <ScrollTableRowItem>
                {account.deletedAt ? (
                  <ErrorText>{t('common.status.removed')}</ErrorText>
                ) : (
                  <CapitalizedText className={'text-gray-100/90'}>{t('common.status.active')}</CapitalizedText>
                )}
                {hasAwsIntegrationErrors(integrationStates.aws || []) && (
                  <CustomIcon path={alertIcon} styles={'w-6 h-6 bg-primary-600'} />
                )}
              </ScrollTableRowItem>
              <Button
                value={t('admin.integrations.viewDetails')}
                clickHandler={() => navigate(account.masterAccountId)}
                buttonStyle={ButtonStyle.SECONDARY}
                size={ButtonSize.XSMALL}
              />
            </>
          )) || []
      }}
    />
  )
}

interface CreateIntegrationModalProps {
  step: number
  setStep: (step: number) => void
  stepTitle: string
  submitAction: (request: AwsIntegrationRequest) => void
}

const CreateIntegrationModal = ({ submitAction, step, setStep, stepTitle }: CreateIntegrationModalProps) => {
  const { createCancelToken } = useCancelToken()
  const { authInfo, integrationStates } = useContext(UserInfoContext)
  const handleError = useErrorHandling()
  const { t } = useTranslation()
  const [regions, setRegions] = useState<FilterOption[]>([])
  const [masterAccountId, setMasterAccountId] = useState<string | undefined>()
  const {
    handleSubmit,
    register,
    control,
    setError,
    clearErrors,
    formState: { isValid, isSubmitting, errors }
  } = useForm<AwsIntegrationRequest>()

  useEffect(() => {
    getAwsRegionOptions(createCancelToken().token).then(setRegions).catch(handleError)
  }, [createCancelToken, handleError])

  useEffect(() => {
    integrationStates.aws?.some(i => i.masterAccountId === masterAccountId)
      ? setError('master.accountId', {
          type: 'unique',
          message: t('admin.integrations.aws.accountExistsError', { type: AwsAccountType.MASTER })
        })
      : clearErrors('master.accountId')
  }, [masterAccountId, integrationStates.aws, setError, clearErrors, errors, t])

  const onSubmit = (data: AwsIntegrationRequest) => {
    Object.keys(errors).length === 0 && submitAction(data)
  }

  return (
    <form key={step} className={'max-w-116'}>
      <IntegrationsModalBody>
        <IntegrationsModalTitle>{stepTitle}</IntegrationsModalTitle>
        <IntegrationModalHighlight type={'create'} path={'aws-get-started-guide'} />
        {step === 1 ? (
          <IntegrationsModalInputs>
            <div>
              <CustomLabel>{t('admin.integrations.accountId')} *</CustomLabel>
              <CustomInput
                type={'number'}
                {...register('master.accountId', {
                  required: true,
                  valueAsNumber: true
                })}
                onChange={e => setMasterAccountId(e.target.value)}
                autoFocus={true}
              />
              {errors.master?.accountId && (
                <ErrorText className={'text-90 pt-1'}>{errors.master.accountId.message}</ErrorText>
              )}
            </div>
            <div>
              <CustomLabel>{t('admin.integrations.name')}</CustomLabel>
              <CustomInput
                {...register('master.name', {
                  required: false,
                  minLength: 1
                })}
              />
              <GrayText className={'text-75'}>{t('admin.integrations.aws.optionalName')}</GrayText>
            </div>
            <div>
              <CustomLabel>{t('admin.integrations.aws.roleName')} *</CustomLabel>
              <CustomInput
                {...register('master.role', {
                  required: true,
                  minLength: 1
                })}
              />
              <GrayText className={'text-75'}>{t('admin.integrations.aws.roleNameFootnote')}</GrayText>
            </div>
            <div>
              <CustomLabel>{t('admin.integrations.aws.externalId')}</CustomLabel>
              <CustomInput disabled={true} value={authInfo.tenant.id} />
              <GrayText className={'text-75'}>{t('admin.integrations.aws.externalIdFootnote')}</GrayText>
            </div>
          </IntegrationsModalInputs>
        ) : step === 2 ? (
          <IntegrationsModalInputs>
            <div>
              <CustomLabel>{t('admin.integrations.accountId')} *</CustomLabel>
              <CustomInput
                type={'number'}
                {...register('security.accountId', {
                  required: true
                })}
                autoFocus={true}
              />
            </div>
            <div>
              <CustomLabel>{t('common.region_one')} *</CustomLabel>
              <Controller
                name={'security.region'}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <AwsRegionMenu regions={regions} value={value} onChange={onChange} />
                )}
                rules={{ required: true }}
              />
            </div>
          </IntegrationsModalInputs>
        ) : (
          <IntegrationsModalInputs>
            <div>
              <CustomLabel>{t('admin.integrations.accountId')} *</CustomLabel>
              <CustomInput
                type={'number'}
                {...register('config.accountId', {
                  required: true
                })}
                autoFocus={true}
              />
            </div>
            <div>
              <CustomLabel>{t('common.region_one')} *</CustomLabel>
              <Controller
                name={'config.region'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AwsRegionMenu regions={regions} onChange={onChange} value={value} />
                )}
                rules={{ required: true }}
              />
            </div>
            <div>
              <CustomLabel>{t('admin.integrations.aws.aggregatorName')} *</CustomLabel>
              <CustomInput
                {...register('config.aggregatorName', {
                  required: true,
                  minLength: 1
                })}
              />
            </div>
          </IntegrationsModalInputs>
        )}
      </IntegrationsModalBody>
      <ModalActions>
        {step > 1 && (
          <Button
            value={t('common.prev')}
            buttonStyle={ButtonStyle.GHOST}
            size={ButtonSize.SMALL}
            type={ButtonType.FORM}
            clickHandler={event => {
              event.preventDefault()
              setStep(step - 1)
            }}
          />
        )}
        {step === 3 && (
          <Button
            size={ButtonSize.SMALL}
            type={ButtonType.FORM}
            disabled={!isValid || isSubmitting || Object.keys(errors).length > 0}
            value={t('admin.integrations.addNew')}
            clickHandler={handleSubmit(onSubmit)}
          />
        )}
        {step < 3 && (
          <Button
            value={t('common.next')}
            type={ButtonType.FORM}
            buttonStyle={ButtonStyle.GHOST}
            size={ButtonSize.SMALL}
            disabled={!isValid || Object.keys(errors).length > 0}
            clickHandler={event => {
              event.preventDefault()
              setStep(step + 1)
            }}
          />
        )}
      </ModalActions>
    </form>
  )
}

interface RegionMenuProps extends Props {
  regions: FilterOption[]
  setSelectedRegion?: (region: FilterOption) => void
}

export const AwsRegionMenu = (props: RegionMenuProps) => {
  const formatOptionLabel = (data: FilterOption) => {
    return (
      <div className={'flex gap-4 text-90 items-center'}>
        <div>{data.value}</div>
        <GrayText className={'text-80'}>{data.label}</GrayText>
      </div>
    )
  }

  return (
    <Select
      unstyled
      options={props.regions}
      onChange={selected => {
        props.onChange && props.onChange(selected, {} as ActionMeta<FilterOption>)
        props.setSelectedRegion && props.setSelectedRegion(selected as FilterOption)
      }}
      defaultValue={props.value}
      formatOptionLabel={option => formatOptionLabel(option as FilterOption)}
      classNames={{
        control: state =>
          selectControlStyles + `hover:border-gray-100 ${state.isFocused && ' border-gray-100 shadow-input-focus'}`,
        menuList: () => selectMenuListStyles + ' static',
        option: state => selectOptionStyles + `${(state.isSelected || state.isFocused) && ' bg-gray-600 text-gray-50'}`,
        input: () => 'text-gray-50',
        placeholder: () => 'text-gray-200',
        singleValue: () => 'text-gray-50',
        noOptionsMessage: () => selectNoOptionsStyles
      }}
      components={{
        DropdownIndicator: SelectDropdownIndicator,
        IndicatorSeparator: SelectIndicatorSeparator
      }}
    />
  )
}

export const hasAwsIntegrationErrors = (integrationStates: AwsIntegrationsState[] | null) =>
  integrationStates?.some(
    i =>
      !i.deletedAt &&
      (!i.lastOrganizationIntegrationAt ||
        (!i.billing.lastIntegrationAt && !i.billing.dismissed) ||
        (!i.recommendation.lastIntegrationAt && !i.recommendation.dismissed) ||
        (!i.usage.lastIntegrationAt && !i.usage.dismissed) ||
        i.resources.some(r => !r.lastIntegrationAt && !r.dismissed) ||
        i.compliance.some(c => !c.lastIntegrationAt && !c.dismissed))
  ) || false
