import styled from 'styled-components'
import tw from 'twin.macro'

export const CustomInput = styled.input`
  ${tw`w-full rounded py-1.5 px-4 bg-gray-700/60 text-gray-100 focus:text-gray-50 border border-gray-400/60 focus:border-gray-400 focus:outline-none focus:shadow-input-focus disabled:bg-gray-500/60`}
  ::placeholder {
    ${tw`text-90 text-gray-300/80`}
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }
`

export const CustomTextArea = styled.textarea`
  ${tw`w-full rounded-sm py-1.5 px-4 bg-gray-700 text-gray-100 focus:text-gray-50 border border-gray-400/60 
  focus:border-gray-100 focus:outline-none focus:shadow-input-focus
  disabled:bg-gray-500/60`}
  ::placeholder {
    ${tw`text-90 text-gray-300/80`}
  }
`

export const CustomLabel = styled.div`
  ${tw`inline-block mb-1 text-gray-200 font-semibold text-90 first-letter:capitalize`}
`
