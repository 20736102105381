import React, { useEffect, useState } from 'react'
import { TicketsHeader } from './TicketsHeader'
import { TicketsStatusContainer } from './ticket/TicketsStatusContainer'
import { getTicketsByStatus, TicketsByStatus, TicketStatus, TicketSummary } from '../../api/tickets'
import { Layout } from '../../layout/Layout'
import { useErrorHandling } from '../../hooks/handleError'
import { useCancelToken } from '../../api/client'
import { Loading } from '../shared/Loading'
import { MissingDataNotification } from '../shared/MissingDataNotification'
import { useTranslation } from 'react-i18next'
import { DataContainer } from '../shared/containers/DataContainer'
import { Heading2 } from '../shared/TextComponents'

export const Tickets = () => {
  const { t } = useTranslation()
  const handleError = useErrorHandling()
  const { createCancelToken } = useCancelToken()
  const [loading, setLoading] = useState<boolean>(false)
  const [tickets, setTickets] = useState<TicketsByStatus[]>([])
  const [ticketSummary, setTicketSummary] = useState<TicketSummary | null>(null)

  useEffect(() => {
    setLoading(true)
    const cancelToken = createCancelToken()
    getTicketsByStatus(cancelToken.token)
      .then(resp => {
        setTickets(resp)
        setTicketSummary({
          open: resp.find(byStatus => byStatus.status === TicketStatus.OPEN)?.tickets.length || 0,
          pending: resp.find(byStatus => byStatus.status === TicketStatus.PENDING)?.tickets.length || 0,
          resolved: resp.find(byStatus => byStatus.status === TicketStatus.RESOLVED)?.tickets.length || 0,
          closed: resp.find(byStatus => byStatus.status === TicketStatus.CLOSED)?.tickets.length || 0
        })
      })
      .catch(handleError)
      .finally(() => setLoading(false))

    return () => {
      cancelToken.cancel()
      setLoading(false)
      setTickets([])
      setTicketSummary(null)
    }
  }, [handleError, createCancelToken])

  if (!ticketSummary || loading)
    return (
      <Layout>
        <DataContainer flexCol={true}>
          <Heading2>{t('tickets.header.title')}</Heading2>
          {loading ? (
            <Loading paddingY={'1rem'} />
          ) : (
            <MissingDataNotification justify={'start'} displayText={t('tickets.noTickets')} />
          )}
        </DataContainer>
      </Layout>
    )

  return (
    <Layout>
      <TicketsHeader ticketCount={ticketSummary} />
      {tickets.map(byStatus => (
        <TicketsStatusContainer key={byStatus.status} status={byStatus.status} tickets={byStatus.tickets} />
      ))}
    </Layout>
  )
}
