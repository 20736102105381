import React, { useContext, useState } from 'react'
import { FilterOption } from '../shared/filters/FilterSelect'
import { ComplianceFilters } from './ComplianceFilters'
import { ComplianceTotalSummary } from './total-summary/ComplianceTotalSummary'
import { ComplianceTabs } from './tabs/ComplianceTabs'
import { Vendor } from '../../utils/vendors'
import { UserInfoContext } from '../../state/context/UserInfoContext'
import { useTranslation } from 'react-i18next'
import { Layout } from '../../layout/Layout'
import { getVendorFilterOptions } from '../shared/filters/Filters'
import { TimeframeOption } from '../../utils/classes'

export const Compliance = () => {
  const { t } = useTranslation()
  const { userSettings } = useContext(UserInfoContext)
  const [selectedVendors, setSelectedVendors] = useState<FilterOption[]>(getVendorFilterOptions(userSettings, t))
  const [selectedProjects, setSelectedProjects] = useState<FilterOption[]>([])
  const [selectedResourceGroups, setSelectedResourceGroups] = useState<FilterOption[]>([])
  const [selectedStandards, setSelectedStandards] = useState<FilterOption[]>([])
  const [selectedRegions, setSelectedRegions] = useState<FilterOption[]>([])
  const [chartTimeframe, setChartTimeframe] = useState<TimeframeOption>(TimeframeOption.THIRTY_DAYS)

  return (
    <Layout>
      <ComplianceFilters
        selectedVendors={selectedVendors}
        setSelectedVendors={setSelectedVendors}
        selectedProjects={selectedProjects}
        setSelectedProjects={setSelectedProjects}
        selectedResourceGroups={selectedResourceGroups}
        setSelectedResourceGroups={setSelectedResourceGroups}
        selectedStandards={selectedStandards}
        setSelectedStandards={setSelectedStandards}
        selectedRegions={selectedRegions}
        setSelectedRegions={setSelectedRegions}
      />
      <ComplianceTotalSummary
        selectedVendors={selectedVendors.map(v => v.value as Vendor)}
        selectedProjects={selectedProjects.map(p => p.value)}
        selectedResourceGroups={selectedResourceGroups.map(r => r.value)}
        selectedStandards={selectedStandards.map(s => s.value)}
        selectedRegions={selectedRegions.map(r => r.value)}
      />
      <ComplianceTabs
        selectedVendors={selectedVendors.map(v => v.value as Vendor)}
        selectedProjects={selectedProjects.map(p => p.value)}
        selectedResourceGroups={selectedResourceGroups.map(r => r.value)}
        selectedStandards={selectedStandards.map(s => s.value)}
        selectedRegions={selectedRegions.map(r => r.value)}
        chartTimeframe={chartTimeframe}
        setChartTimeframe={setChartTimeframe}
      />
    </Layout>
  )
}
