import React, { useEffect, useState } from 'react'
import { Vendor } from '../../../utils/vendors'
import { TabNoDataMessage } from '../../shared/tabs/TabSharedComponents'
import { MissingDataNotification } from '../../shared/MissingDataNotification'
import { TreeWrapper } from '../../shared/Tree'
import {
  AwsComplianceFindingsByControl,
  AzureComplianceFindingsByControl,
  GcpComplianceFindingsByControl
} from '../../../api/compliance/tabs'
import { SearchInput, SearchInputProps } from '../../shared/filters/SearchInput'
import { useTranslation } from 'react-i18next'
import { GrayText, WhiteText } from '../../shared/TextComponents'
import { Loading } from '../../shared/Loading'
import { ComplianceControl } from './ComplianceControl'
import { formatNumber } from '../../../utils/formats'
import tw from 'twin.macro'
import styled from 'styled-components'
import 'react-datepicker/dist/react-datepicker.css'
import { DateRangePicker, getMaxDate, getMinDate } from '../../shared/filters/DateRangePicker'
import { MeatballsMenuOption } from '../../shared/MeatballsMenu'

interface VendorTabProps {
  selectedVendors: Vendor[]
  findings: AwsComplianceFindingsByControl[] | AzureComplianceFindingsByControl[] | GcpComplianceFindingsByControl[]
  vendor: Vendor
  loading: boolean
}

export const ComplianceVendorTab = ({ selectedVendors, findings, vendor, loading }: VendorTabProps) => {
  const { t } = useTranslation()
  const [controlSearchText, setControlSearchText] = useState('')
  const [resourceSearchText, setResourceSearchText] = useState('')
  const [filteredFindingsByControl, setFilteredFindingsByControl] = useState<
    AwsComplianceFindingsByControl[] | AzureComplianceFindingsByControl[] | GcpComplianceFindingsByControl[]
  >(findings)
  const [matchingResources, setMatchingResources] = useState<number | null>(null)

  const datePickerPresets: MeatballsMenuOption[] = []
  const [minDate, setMinDate] = useState<Date | null>(null)
  const [maxDate, setMaxDate] = useState<Date | null>(null)
  const [startDate, setStartDate] = useState<Date | null>(minDate)
  const [endDate, setEndDate] = useState<Date | null>(maxDate)

  useEffect(() => {
    const initFindings = findings.map(control => control.findings.map(finding => finding)).flat()
    setMinDate(
      initFindings
        .map(finding =>
          'observedAt' in finding && finding.observedAt.first ? new Date(finding.observedAt.first) : new Date()
        )
        .sort((a, b) => a.getTime() - b.getTime())[0]
    )
    setMaxDate(
      initFindings
        .map(finding =>
          'observedAt' in finding && finding.observedAt.first ? new Date(finding.observedAt.first) : new Date()
        )
        .sort((a, b) => b.getTime() - a.getTime())[0]
    )
    minDate?.setHours(0, 0, 0, 0)
    maxDate?.setHours(23, 59, 59, 999)
  }, [findings])

  useEffect(() => {
    if (controlSearchText !== '' || resourceSearchText !== '') {
      const filtered = filterFindings(findings, resourceSearchText, controlSearchText, startDate, endDate)
      const filteredDates = filtered
        .map(control => control?.findings)
        .flat()
        .map(f => 'observedAt' in f && f.observedAt.first)
        .filter(date => date)
      if (filteredDates?.length) {
        setStartDate(getMinDate(filteredDates as Date[]))
        setEndDate(getMaxDate(filteredDates as Date[]))
      }
    } else {
      setStartDate(minDate)
      setEndDate(maxDate)
    }
  }, [controlSearchText, resourceSearchText])

  useEffect(() => {
    setStartDate(minDate)
    setEndDate(maxDate)
  }, [maxDate, minDate])

  useEffect(() => {
    if (
      controlSearchText !== '' ||
      resourceSearchText !== '' ||
      JSON.stringify(startDate?.getTime()) !== JSON.stringify(minDate?.getTime()) ||
      JSON.stringify(endDate?.getTime()) !== JSON.stringify(maxDate?.getTime())
    ) {
      const filtered = filterFindings(findings, resourceSearchText, controlSearchText, startDate, endDate)
      const filteredFindings = filtered?.map(control => control?.findings)?.flat()
      const resources = filteredFindings?.map(finding => finding?.resource?.id)
      const matches = new Set(resources).size
      setMatchingResources(matches)
      setFilteredFindingsByControl(filtered)
    } else {
      setMatchingResources(null)
      setFilteredFindingsByControl(findings)
    }
  }, [findings, controlSearchText, resourceSearchText, vendor, startDate, endDate])

  const yearFromNow = new Date(new Date().setFullYear(new Date().getFullYear() - 1))
  const sixMonthsFromNow = new Date(new Date().setMonth(new Date().getMonth() - 6))
  const last30Days = new Date(new Date().setDate(new Date().getDate() - 30))
  yearFromNow?.setHours(0, 0, 0, 0)
  sixMonthsFromNow?.setHours(0, 0, 0, 0)
  last30Days?.setHours(0, 0, 0, 0)

  if (minDate && minDate < yearFromNow) {
    datePickerPresets.push(
      {
        label: t('common.timeframeOptions.LAST_YEAR'),
        isRadio: true,
        type: 'secondary',
        checked: JSON.stringify(startDate?.getTime()) === JSON.stringify(yearFromNow.getTime()),
        clickHandler: () => {
          setStartDate(yearFromNow)
          setEndDate(new Date())
        }
      },
      {
        label: t('common.timeframeOptions.LAST_6_MONTHS'),
        isRadio: true,
        type: 'secondary',
        checked: JSON.stringify(startDate?.getTime()) === JSON.stringify(sixMonthsFromNow.getTime()),
        clickHandler: () => {
          setStartDate(sixMonthsFromNow)
          setEndDate(new Date())
        }
      },
      {
        label: t('common.timeframeOptions.LAST_30_DAYS'),
        isRadio: true,
        type: 'secondary',
        checked: JSON.stringify(startDate?.getTime()) === JSON.stringify(last30Days?.getTime()),
        clickHandler: () => {
          setStartDate(last30Days)
          setEndDate(new Date())
        }
      }
    )
  }

  if (loading || !selectedVendors.includes(vendor) || (selectedVendors.includes(vendor) && !findings.length))
    return (
      <TreeWrapper>
        <TabHeader
          vendor={vendor}
          controlSearchText={controlSearchText}
          setControlSearchText={setControlSearchText}
          resourceSearchText={resourceSearchText}
          setResourceSearchText={setResourceSearchText}
          minDate={minDate}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          matches={0}
          datePickerPresets={datePickerPresets}
        />
        {loading ? (
          <Loading />
        ) : !selectedVendors.includes(vendor) ? (
          <TabNoDataMessage vendor={vendor} selectedVendors={selectedVendors} />
        ) : (
          <MissingDataNotification />
        )}
      </TreeWrapper>
    )

  return (
    <TreeWrapper>
      <TabHeader
        vendor={vendor}
        controlSearchText={controlSearchText}
        setControlSearchText={setControlSearchText}
        resourceSearchText={resourceSearchText}
        setResourceSearchText={setResourceSearchText}
        matches={matchingResources}
        minDate={minDate}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        datePickerPresets={datePickerPresets}
      />
      <TreeWrapper className={'py-4'}>
        {filteredFindingsByControl.length > 0 ? (
          filteredFindingsByControl.map((control, index) => (
            <ComplianceControl
              key={`${index}-${vendor}-${control.control}`}
              id={`qa-compliance-${vendor}-tab-content-${index}`}
              vendor={vendor}
              findingsByControl={control}
            />
          ))
        ) : (
          <MissingDataNotification displayText={t('compliance.tabs.common.nothingFound')} />
        )}
      </TreeWrapper>
    </TreeWrapper>
  )
}

type TabHeaderProps = {
  vendor: Vendor
  minDate: Date | null
  startDate: Date | null
  setStartDate: (date: Date | null) => void
  endDate: Date | null
  setEndDate: (date: Date | null) => void
  controlSearchText: string
  setControlSearchText: (text: string) => void
  resourceSearchText: string
  setResourceSearchText: (text: string) => void
  matches: number | null
  datePickerPresets?: MeatballsMenuOption[]
}

const TabHeader = ({
  vendor,
  minDate,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  controlSearchText,
  setControlSearchText,
  resourceSearchText,
  setResourceSearchText,
  matches,
  datePickerPresets
}: TabHeaderProps) => {
  const { t } = useTranslation()
  return (
    <div className={'w-full border-b border-dotted border-gray-500'}>
      <TabFiltersWrapper>
        <TabFilter
          id={`${vendor}-control`}
          inputLabel={t('compliance.tabs.common.controlSearchInputLabel')}
          placeholder={t('compliance.tabs.common.controlSearchInputPlaceholder')}
          searchText={controlSearchText}
          setSearchText={setControlSearchText}
        />
        <TabFilter
          id={`${vendor}-resource`}
          inputLabel={t('compliance.tabs.common.resourceSearchInputLabel')}
          placeholder={t('compliance.tabs.common.resourceSearchInputPlaceholder')}
          searchText={resourceSearchText}
          setSearchText={setResourceSearchText}
        />
        {vendor === Vendor.AWS && minDate && (
          <DateRangePicker
            label={t('compliance.tabs.aws.dateRangeLabel')}
            minDate={minDate}
            startDate={startDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            presets={datePickerPresets}
          />
        )}
        {matches ? <ComplianceCountLabel count={matches} label={t('compliance.tabs.common.matchingResults')} /> : null}
      </TabFiltersWrapper>
    </div>
  )
}

const TabFiltersWrapper = styled.div`
  ${tw`flex gap-8 pt-4 pb-6 px-2 flex-wrap`}
  > div {
    ${tw`w-full max-w-[348px]`}
  }
`

type TabFilterProps = SearchInputProps & {
  inputLabel: string
}

const TabFilter = ({ inputLabel, id, placeholder, searchText, setSearchText }: TabFilterProps) => {
  return (
    <div className={'flex flex-col gap-0.5'}>
      <GrayText className={'text-90'}>{inputLabel}</GrayText>
      <SearchInput id={id} placeholder={placeholder} searchText={searchText} setSearchText={setSearchText} />
    </div>
  )
}

interface IndicatorLabelProps {
  count: number
  label: string
}

export const ComplianceCountLabel = ({ count, label }: IndicatorLabelProps) => {
  return (
    <div className={'flex gap-1.5 items-center text-gray-200 w-fit text-left text-90 lg:text-100 lg:w-max'}>
      <WhiteText className={'font-bold'}>{formatNumber(count)}</WhiteText>
      {` ${label}`}
    </div>
  )
}

const filterFindings = (
  findings: AwsComplianceFindingsByControl[] | AzureComplianceFindingsByControl[] | GcpComplianceFindingsByControl[],
  resourceSearchText: string,
  controlSearchText: string,
  startDate: Date | null,
  endDate: Date | null
) => {
  return findings
    .map(control => {
      const filteredFindings = control.findings
        .filter(finding => finding.resource.id.toLowerCase().includes(resourceSearchText.toLowerCase()))
        .filter(f =>
          'observedAt' in f && f.observedAt.first && startDate && endDate
            ? f.observedAt.first >= startDate && f.observedAt.first <= endDate
            : true
        )
      if (filteredFindings.length) {
        return {
          ...control,
          controlSeverity: 'severity' in filteredFindings[0] && filteredFindings[0].severity,
          failedCount: filteredFindings.length,
          findings: filteredFindings
        }
      } else return null
    })
    .filter(c => c?.findings)
    .filter(c => c?.control.toLowerCase().includes(controlSearchText.toLowerCase())) as
    | AwsComplianceFindingsByControl[]
    | AzureComplianceFindingsByControl[]
    | GcpComplianceFindingsByControl[]
}
