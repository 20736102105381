import React, { useContext, useEffect, useState } from 'react'
import { useCancelToken } from '../../../../api/client'
import { useErrorHandling } from '../../../../hooks/handleError'
import { ScrollTableRowItem } from '../../../shared/containers/ScrollTable'
import { formatDate, uuidRegExp } from '../../../../utils/formats'
import { useTranslation } from 'react-i18next'
import { AzureIntegrationRequest, createAzureIntegration } from '../../../../api/admin/integrations/azure'
import { MessageContext, MessageType } from '../../../../state/context/MessageContext'
import { ModalContext } from '../../../../state/context/ModalContext'
import { useForm } from 'react-hook-form'
import { ModalActions } from '../../../shared/modal/Modal'
import { IntegrationModalHighlight, IntegrationsModalBody, IntegrationsModalInputs } from '../Shared'
import { Button, ButtonSize, ButtonStyle, ButtonType } from '../../../shared/buttons/Button'
import { CustomInput, CustomLabel } from '../../../shared/filters/FormComponents'
import { IntegrationsLayout } from '../IntegrationsLayout'
import { CapitalizedText, ErrorText } from '../../../shared/TextComponents'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { firstLogin } from '../../../../state/storage'
import { UserInfoContext } from '../../../../state/context/UserInfoContext'
import { Alert } from '../../../shared/indicators/Alert'
import { AzureIntegrationsState, getIntegrationStates } from '../../../../api/settings/profile'

export const AzureIntegrations = () => {
  const { setModal } = useContext(ModalContext)
  const { setMessage } = useContext(MessageContext)
  const { integrationStates, setIntegrationStates } = useContext(UserInfoContext)
  const { createCancelToken } = useCancelToken()
  const { t } = useTranslation()
  const handleError = useErrorHandling()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const isFirst = Boolean(searchParams.get('first'))

  useEffect(() => {
    if (isFirst) {
      openCreateModal()
    }
  }, [createCancelToken, handleError])

  const openCreateModal = () => {
    const createIntegration = (request: AzureIntegrationRequest) => {
      const cancelToken = createCancelToken()
      createAzureIntegration(request, cancelToken.token)
        .then(resp => {
          getIntegrationStates(cancelToken.token).then(setIntegrationStates).catch(handleError)
          setModal(null)
          setMessage({ type: MessageType.SUCCESS, message: t('admin.integrations.azure.addSuccessToast') })
          !firstLogin() && navigate(resp.azureTenantId)
          isFirst && searchParams.delete('first')
        })
        .catch(handleError)
    }

    setModal({
      header: t('admin.integrations.azure.createTitle'),
      returnAction: isFirst ? () => navigate(-1) : undefined,
      body: <CreateModal submitAction={createIntegration} />
    })
  }

  return (
    <IntegrationsLayout
      noData={!integrationStates.azure}
      heading={t('admin.integrations.azure.title')}
      headerActions={<Button clickHandler={() => openCreateModal()} value={t('admin.integrations.addNew')} />}
      scrollTableProps={{
        smallScreenTitle: t('admin.integrations.integrations'),
        sortable: false,
        customColumns: '220px repeat(3, 1fr) minmax(90px, auto)',
        titles: [
          t('admin.integrations.name'),
          t('admin.integrations.azure.tenantId'),
          t('admin.integrations.lastIntegrationAt'),
          t('admin.integrations.status')
        ],
        rows:
          integrationStates?.azure?.map(azureTenant => (
            <>
              <ScrollTableRowItem>{azureTenant.name}</ScrollTableRowItem>
              <ScrollTableRowItem>{azureTenant.azureTenantId}</ScrollTableRowItem>
              <ScrollTableRowItem>
                {formatDate(azureTenant.lastOrganizationIntegrationAt, true, true, true)}
              </ScrollTableRowItem>
              <ScrollTableRowItem>
                {azureTenant.deletedAt ? (
                  <ErrorText>{t('common.status.removed')}</ErrorText>
                ) : (
                  <CapitalizedText className={'text-gray-100/90'}>{t('common.status.active')}</CapitalizedText>
                )}
                {hasAzureIntegrationErrors(integrationStates.azure) && <Alert />}
              </ScrollTableRowItem>
              <Button
                value={t('admin.integrations.viewDetails')}
                clickHandler={() => navigate(azureTenant.azureTenantId)}
                buttonStyle={ButtonStyle.SECONDARY}
                size={ButtonSize.XSMALL}
              />
            </>
          )) || []
      }}
    />
  )
}

interface FormModalProps {
  submitAction: (request: AzureIntegrationRequest) => void
}

const CreateModal = ({ submitAction }: FormModalProps) => {
  const { setModal } = useContext(ModalContext)
  const { integrationStates } = useContext(UserInfoContext)
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [azureTenantId, setAzureTenantId] = useState<string>()
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { isValid, isSubmitting, isDirty, errors }
  } = useForm<AzureIntegrationRequest>()

  useEffect(() => {
    integrationStates.azure?.some(i => i.azureTenantId === azureTenantId)
      ? setError('azureTenantId', { type: 'unique', message: t('admin.integrations.azure.tenantExistsError') })
      : clearErrors('azureTenantId')
  }, [azureTenantId, clearErrors, integrationStates.azure, setError, t])

  return (
    <form className={'max-w-116'}>
      <IntegrationsModalBody>
        <IntegrationModalHighlight type={'create'} path={'azure-get-started-guide'} />
        <IntegrationsModalInputs>
          <div>
            <CustomLabel>{t('admin.integrations.azure.tenantId')} *</CustomLabel>
            <CustomInput
              {...register('azureTenantId', {
                required: true,
                validate: value => uuidRegExp.test(value)
              })}
              onChange={e => setAzureTenantId(e.target.value)}
              autoFocus={true}
            />
          </div>
          <div>
            <CustomLabel>{t('admin.integrations.name')} *</CustomLabel>
            <CustomInput
              {...register('name', {
                required: true,
                minLength: 1
              })}
            />
          </div>
          {errors.azureTenantId?.message && <ErrorText>{errors.azureTenantId.message}</ErrorText>}
        </IntegrationsModalInputs>
      </IntegrationsModalBody>
      <ModalActions>
        {!searchParams.get('first') && (
          <Button
            buttonStyle={ButtonStyle.SECONDARY}
            type={ButtonType.FORM}
            value={t('common.cancel')}
            clickHandler={() => setModal(null)}
          />
        )}
        <Button
          type={ButtonType.FORM}
          disabled={!isValid || isSubmitting || !isDirty || !!errors.azureTenantId?.message}
          value={t('admin.integrations.addNew')}
          clickHandler={handleSubmit(submitAction)}
        />
      </ModalActions>
    </form>
  )
}

export const hasAzureIntegrationErrors = (integrationStates: AzureIntegrationsState[] | null) =>
  integrationStates?.some(
    integration =>
      !integration.deletedAt &&
      (!integration.lastOrganizationIntegrationAt ||
        (!integration.billing.lastIntegrationAt && !integration.billing.dismissed) ||
        (!integration.compliance.lastIntegrationAt && !integration.compliance.dismissed) ||
        (!integration.recommendation.lastIntegrationAt && !integration.recommendation.dismissed) ||
        (!integration.usage.lastIntegrationAt && !integration.usage.dismissed))
  ) || false
