import { client } from '../client'
import { Vendor } from '../../utils/vendors'
import { CancelToken } from 'axios'
import { PermittedVendorProject } from '../admin/users'
import { CurrencyId } from '../../utils/Currency'
import { CostAnomalyLimitData } from './notifications'
import { AuthInfo } from '../auth'
import { Tenant } from '../system-admin'
import { FilterOption } from '../../components/shared/filters/FilterSelect'

export const getUserContextData = async (cancelToken: CancelToken): Promise<UserContextData> => {
  const resp = await client.get<UserContextData>('/user-data', { cancelToken }).then(resp => ({
    authInfo: {
      ...resp.data.authInfo,
      site247Integration: resp.data.authInfo.site247Integration
        ? new Date(resp.data.authInfo.site247Integration)
        : undefined,
      freshIntegration: resp.data.authInfo.freshIntegration ? new Date(resp.data.authInfo.freshIntegration) : undefined,
      lastLoginAt: resp.data.authInfo.lastLoginAt ? new Date(resp.data.authInfo.lastLoginAt) : null,
      isLoggedIn: true
    },
    userSettings: resp.data.userSettings,
    integrationStates: getDateFormattedResponse(resp.data.integrationStates),
    tenants: resp.data.tenants
  }))
  localStorage.setItem('authInfo', JSON.stringify(resp.authInfo))
  return resp
}

export const getUserPermittedProjects = async (
  vendor: Vendor,
  cancelToken: CancelToken
): Promise<PermittedVendorProject[]> => {
  return await client
    .get<PermittedVendorProject[]>(`settings/permitted-projects/${vendor.toLowerCase()}`, { cancelToken })
    .then(resp => resp.data)
}

export const changeUserPreferredCurrency = async (
  currency: CurrencyId,
  cancelToken: CancelToken
): Promise<UserSettings> => {
  return await client.post('settings/currency', currency, { cancelToken }).then(resp => resp.data)
}

export const sendContactSupportForm = async (
  contactForm: ContactSupportForm,
  cancelToken: CancelToken
): Promise<void> => {
  return await client.post('/contact-support', contactForm, { cancelToken }).then(() => {})
}

export const getIntegrationStates = async (cancelToken: CancelToken): Promise<TenantIntegrationStates> => {
  return await client.get('/integration-states', { cancelToken }).then(resp => getDateFormattedResponse(resp.data))
}

export interface UserSettings {
  currency: CurrencyId
  defaultCostAnomalyLimits: CostAnomalyLimitData
  visibleVendors: Vendor[]
}

export interface ContactSupportForm {
  category: ContactCategory
  subject: string
  message: string
}

export enum ContactCategory {
  BUG_REPORT = 'BUG_REPORT',
  FEATURE_REQUEST = 'FEATURE_REQUEST',
  OTHER = 'OTHER'
}

export interface TenantIntegrationStates {
  aws: AwsIntegrationsState[] | null
  azure: AzureIntegrationsState[] | null
  gcp: GcpIntegrationsState[] | null
}

export interface AwsIntegrationsState {
  masterAccountId: string
  masterAccountName: string
  role: string
  lastOrganizationIntegrationAt: Date | null
  resources: AwsConfigAccountIntegration[]
  compliance: AwsSecurityAccountIntegration[]
  billing: IntegrationState
  recommendation: IntegrationState
  usage: IntegrationState
  deletedAt: Date | null
}

export interface AwsSecurityAccountIntegration {
  accountId: string
  region: FilterOption
  lastIntegrationAt: Date | null
  dismissed: boolean
  deletedAt: Date | null
}

export interface AwsConfigAccountIntegration {
  accountId: string
  aggregatorName: string
  region: FilterOption
  lastIntegrationAt: Date | null
  dismissed: boolean
  deletedAt: Date | null
}

export interface AzureIntegrationsState {
  azureTenantId: string
  name: string
  lastOrganizationIntegrationAt: Date | null
  billing: IntegrationState
  compliance: IntegrationState
  recommendation: IntegrationState
  usage: IntegrationState
  deletedAt: Date | null
}

export interface GcpIntegrationsState {
  organizationId: string
  organizationName: string
  lastOrganizationIntegrationAt: Date | null
  compliance: IntegrationState
  recommendation: IntegrationState
  billingAccounts: GcpBillingAccountIntegration[]
  deletedAt: Date | null
}

export interface GcpBillingAccountIntegration {
  accountId: string
  projectId: string
  tableId: string
  billing: IntegrationState
  usage: IntegrationState
  deletedAt: Date | null
}

interface IntegrationState {
  lastIntegrationAt: Date | null
  dismissed: boolean
}

export interface UserContextData {
  authInfo: AuthInfo
  userSettings: UserSettings
  integrationStates: TenantIntegrationStates
  tenants: Tenant[] | null
}

const getDateFormattedResponse = (resp: TenantIntegrationStates): TenantIntegrationStates => ({
  aws:
    resp.aws &&
    resp.aws.map(integration => ({
      ...integration,
      lastOrganizationIntegrationAt:
        integration.lastOrganizationIntegrationAt && new Date(integration.lastOrganizationIntegrationAt),
      resources: integration.resources.map(account => ({
        ...account,
        lastIntegrationAt: account.lastIntegrationAt && new Date(account.lastIntegrationAt),
        deletedAt: account.deletedAt && new Date(account.deletedAt)
      })),
      compliance: integration.compliance.map(account => ({
        ...account,
        lastIntegrationAt: account.lastIntegrationAt && new Date(account.lastIntegrationAt),
        deletedAt: account.deletedAt && new Date(account.deletedAt)
      })),
      billing: {
        ...integration.billing,
        lastIntegrationAt: integration.billing.lastIntegrationAt && new Date(integration.billing.lastIntegrationAt)
      },
      recommendation: {
        ...integration.recommendation,
        lastIntegrationAt:
          integration.recommendation.lastIntegrationAt && new Date(integration.recommendation.lastIntegrationAt)
      },
      usage: {
        ...integration.usage,
        lastIntegrationAt: integration.usage.lastIntegrationAt && new Date(integration.usage.lastIntegrationAt)
      },
      deletedAt: integration.deletedAt && new Date(integration.deletedAt)
    })),
  azure:
    resp.azure &&
    resp.azure.map(integration => ({
      ...integration,
      lastOrganizationIntegrationAt:
        integration.lastOrganizationIntegrationAt && new Date(integration.lastOrganizationIntegrationAt),
      billing: {
        ...integration.billing,
        lastIntegrationAt: integration.billing.lastIntegrationAt && new Date(integration.billing.lastIntegrationAt)
      },
      compliance: {
        ...integration.compliance,
        lastIntegrationAt:
          integration.compliance.lastIntegrationAt && new Date(integration.compliance.lastIntegrationAt)
      },
      recommendation: {
        ...integration.recommendation,
        lastIntegrationAt:
          integration.recommendation.lastIntegrationAt && new Date(integration.recommendation.lastIntegrationAt)
      },
      usage: {
        ...integration.usage,
        lastIntegrationAt: integration.usage.lastIntegrationAt && new Date(integration.usage.lastIntegrationAt)
      },
      deletedAt: integration.deletedAt && new Date(integration.deletedAt)
    })),
  gcp:
    resp.gcp &&
    resp.gcp.map(integration => ({
      ...integration,
      lastOrganizationIntegrationAt:
        integration.lastOrganizationIntegrationAt && new Date(integration.lastOrganizationIntegrationAt),
      compliance: {
        ...integration.compliance,
        lastIntegrationAt:
          integration.compliance.lastIntegrationAt && new Date(integration.compliance.lastIntegrationAt)
      },
      recommendation: {
        ...integration.recommendation,
        lastIntegrationAt:
          integration.recommendation.lastIntegrationAt && new Date(integration.recommendation.lastIntegrationAt)
      },
      billingAccounts: integration.billingAccounts.map(billingAccount => ({
        ...billingAccount,
        billing: {
          ...billingAccount.billing,
          lastIntegrationAt:
            billingAccount.billing.lastIntegrationAt && new Date(billingAccount.billing.lastIntegrationAt)
        },
        usage: {
          ...billingAccount.usage,
          lastIntegrationAt: billingAccount.usage.lastIntegrationAt && new Date(billingAccount.usage.lastIntegrationAt)
        },
        deletedAt: billingAccount.deletedAt && new Date(billingAccount.deletedAt)
      })),
      deletedAt: integration.deletedAt && new Date(integration.deletedAt)
    }))
})
