import {
  IntegrationAccountStatusRow,
  IntegrationDetailRow,
  IntegrationDetailRowsWrapper,
  IntegrationDetailsHeader,
  IntegrationTimestampsSection
} from '../Shared'
import { AwsAccountType } from './AwsIntegrationDetails'
import React, { useContext, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { formatDate } from '../../../../utils/formats'
import { AdminTabContent } from '../../adminStyles'
import { UserInfoContext } from '../../../../state/context/UserInfoContext'
import { SpotterDocumentationLink } from '../../../login/Shared'
import { CapitalizedText } from '../../../shared/TextComponents'
import { AwsIntegrationsState } from '../../../../api/settings/profile'

interface AwsIntegrationTimeBlocksProps {
  selectedIntegration: AwsIntegrationsState
}

export const AwsIntegrationTimestamps = ({ selectedIntegration }: AwsIntegrationTimeBlocksProps) => {
  return (
    <>
      <MasterAccountSection selectedIntegration={selectedIntegration} />
      <SecurityAccountSection selectedIntegration={selectedIntegration} />
      <ConfigAccountSection selectedIntegration={selectedIntegration} />
    </>
  )
}

interface AccountSectionProps {
  selectedIntegration: AwsIntegrationsState
}

const MasterAccountSection = ({ selectedIntegration }: AccountSectionProps) => {
  const { t } = useTranslation()
  const { integrationStates } = useContext(UserInfoContext)
  const errorSolutions = []
  !selectedIntegration.lastOrganizationIntegrationAt &&
    errorSolutions.push(
      <Trans
        i18nKey='admin.integrations.aws.missingOrganizationData'
        components={{
          CustomLink: <SpotterDocumentationLink path={'aws-access-management'} />
        }}
      />
    )
  !selectedIntegration.usage.lastIntegrationAt &&
    !selectedIntegration.usage.dismissed &&
    errorSolutions.push(<CapitalizedText>{t('admin.integrations.aws.missingUsageData')}</CapitalizedText>)

  return (
    <IntegrationTimestampsSection
      timestampComponent={
        <AdminTabContent>
          <IntegrationDetailsHeader header={t('admin.integrations.aws.account', { type: AwsAccountType.MASTER })} />
          <IntegrationDetailRowsWrapper
            hasActions={false}
            detailRows={
              <>
                <IntegrationDetailRow
                  label={t('admin.integrations.organization')}
                  value={formatDate(selectedIntegration.lastOrganizationIntegrationAt, true, true, true)}
                  error={integrationStates.aws?.some(
                    i => i.masterAccountId === selectedIntegration.masterAccountId && !i.lastOrganizationIntegrationAt
                  )}
                />
                <IntegrationDetailRow
                  label={t('sideNav.costs')}
                  value={formatDate(selectedIntegration.billing.lastIntegrationAt, true, true, true)}
                  error={!selectedIntegration.billing.lastIntegrationAt && !selectedIntegration.billing.dismissed}
                />
                <IntegrationDetailRow
                  label={t('sideNav.optimization')}
                  value={formatDate(selectedIntegration.recommendation.lastIntegrationAt, true, true, true)}
                  error={
                    !selectedIntegration.recommendation.lastIntegrationAt &&
                    !selectedIntegration.recommendation.dismissed
                  }
                />
                <IntegrationDetailRow
                  label={t('sideNav.sustainability')}
                  value={formatDate(selectedIntegration.usage.lastIntegrationAt, true, true, true)}
                  error={!selectedIntegration.usage.lastIntegrationAt && !selectedIntegration.usage.dismissed}
                />
              </>
            }
          />
        </AdminTabContent>
      }
      errorSolutions={errorSolutions}
    />
  )
}

const SecurityAccountSection = ({ selectedIntegration }: AccountSectionProps) => {
  const { t } = useTranslation()
  const { integrationStates } = useContext(UserInfoContext)
  const [collapsedSecurityAccounts, setCollapsedSecurityAccounts] = useState<number[]>([])

  return (
    <IntegrationTimestampsSection
      timestampComponent={
        <AdminTabContent>
          <IntegrationDetailsHeader header={t('admin.integrations.aws.account', { type: AwsAccountType.SECURITY })} />
          {selectedIntegration.compliance.map((account, index) => (
            <IntegrationDetailRowsWrapper
              key={index}
              hasActions={false}
              detailRows={
                <>
                  <IntegrationDetailRow
                    label={t('sideNav.compliance')}
                    value={formatDate(account.lastIntegrationAt, true, true, true)}
                    error={!account.lastIntegrationAt}
                  />
                  <IntegrationDetailRow
                    label={t('admin.integrations.accountId')}
                    isCollapsed={collapsedSecurityAccounts.includes(index)}
                    collapseHandler={() =>
                      !collapsedSecurityAccounts.includes(index)
                        ? setCollapsedSecurityAccounts([...collapsedSecurityAccounts, index])
                        : setCollapsedSecurityAccounts(collapsedSecurityAccounts.filter(item => item !== index))
                    }
                    value={account.accountId}
                  />
                  {collapsedSecurityAccounts.includes(index) && (
                    <>
                      <IntegrationDetailRow label={t('common.region_one')} value={account.region} />
                      <IntegrationAccountStatusRow accountDeleteDate={account.deletedAt} />
                    </>
                  )}
                </>
              }
            />
          ))}
        </AdminTabContent>
      }
      errorSolutions={
        selectedIntegration.compliance.some(account => !account.lastIntegrationAt && !account.dismissed) && (
          <Trans
            i18nKey='admin.integrations.aws.missingComplianceData'
            components={{
              CustomLink: <SpotterDocumentationLink path={'aws-access-management'} />
            }}
          />
        )
      }
    />
  )
}

const ConfigAccountSection = ({ selectedIntegration }: AccountSectionProps) => {
  const { t } = useTranslation()
  const { integrationStates } = useContext(UserInfoContext)
  const [collapsedConfigAccounts, setCollapsedConfigAccounts] = useState<number[]>([])

  return (
    <IntegrationTimestampsSection
      timestampComponent={
        <AdminTabContent>
          <IntegrationDetailsHeader header={t('admin.integrations.aws.account', { type: AwsAccountType.CONFIG })} />
          {selectedIntegration.resources.map((account, index) => (
            <IntegrationDetailRowsWrapper
              key={index}
              hasActions={false}
              detailRows={
                <>
                  <IntegrationDetailRow
                    label={t('sideNav.infra')}
                    value={formatDate(account.lastIntegrationAt, true, true, true)}
                    error={!account.lastIntegrationAt}
                  />
                  <IntegrationDetailRow
                    label={t('admin.integrations.accountId')}
                    isCollapsed={collapsedConfigAccounts.includes(index)}
                    collapseHandler={() =>
                      !collapsedConfigAccounts.includes(index)
                        ? setCollapsedConfigAccounts([...collapsedConfigAccounts, index])
                        : setCollapsedConfigAccounts(collapsedConfigAccounts.filter(item => item !== index))
                    }
                    value={account.accountId}
                  />
                  {collapsedConfigAccounts.includes(index) && (
                    <>
                      <IntegrationDetailRow label={t('common.region_one')} value={account.region} />
                      <IntegrationDetailRow
                        label={t('admin.integrations.aws.aggregatorName')}
                        value={account.aggregatorName}
                      />
                      <IntegrationAccountStatusRow accountDeleteDate={account.deletedAt} />
                    </>
                  )}
                </>
              }
            />
          ))}
        </AdminTabContent>
      }
      errorSolutions={
        selectedIntegration.resources.some(account => !account.lastIntegrationAt && !account.dismissed) && (
          <Trans
            i18nKey='admin.integrations.aws.missingOrganizationData'
            components={{
              CustomLink: <SpotterDocumentationLink path={'aws-access-management'} />
            }}
          />
        )
      }
    />
  )
}
