import React, { useContext } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Sidenav } from './navigation/Sidenav'
import { ScrollToTop } from '../components/shared/ScrollToTop'
import { Modal } from '../components/shared/modal/Modal'
import { TopNav } from './navigation/Topnav'
import { DataContainer } from '../components/shared/containers/DataContainer'
import { BackgroundOverlay } from '../components/shared/layout/BackgroundOverlay'
import { NavbarContext } from '../state/context/NavbarContext'
import { NotificationsContext } from '../state/context/NotificationsContext'

interface Props {
  children?: React.ReactNode
  type?: 'main' | 'sub'
}

export const Layout = ({ children, type = 'main' }: Props) => {
  return (
    <LayoutWrapper>
      {type === 'main' ? (
        <ViewWrapper>{children}</ViewWrapper>
      ) : (
        <DataContainer subLayout={true}>
          <div className={'w-full h-full lg:min-h-[92vh] px-3 md:px-10'}>{children}</div>
        </DataContainer>
      )}
    </LayoutWrapper>
  )
}

const LayoutWrapper = ({ children }: Props) => {
  const { notificationsOpen } = useContext(NotificationsContext)
  const { navOpen } = useContext(NavbarContext)
  return (
    <>
      <LayoutContainer>
        <TopNav />
        <ContentWrapper>
          <Sidenav />
          <Content className={'no-scrollbar'}>
            {children}
            <ScrollToTop />
          </Content>
        </ContentWrapper>
        <BackgroundOverlay visible={notificationsOpen || navOpen} />
      </LayoutContainer>
      <Modal />
    </>
  )
}

interface LayoutContainerProps {
  loading?: boolean
}

export const LayoutContainer = styled.div<LayoutContainerProps>`
  ${tw`text-gray-50 min-h-[100%]`}
  & > div:first-child {
    ${tw`max-w-[1980px] mx-auto`}
  }
`

const ContentWrapper = styled.div`
  ${tw`flex max-w-[1980px] mx-auto`}
  & > div:first-child:not(:last-child) {
    ${tw`hidden h-full sticky top-14 z-[999] xl:block xl:w-50`}
  }
`

const Content = styled.div`
  ${tw`flex-1 p-2 items-start transition transition-all ease-in-out w-full`}
`

const ViewWrapper = styled.div`
  ${tw`flex flex-col gap-6 pb-10 items-stretch`}
`

interface SubWrapperProps {
  divider?: boolean
}

export const SubLayoutContentWrapper = styled.div<SubWrapperProps>`
  ${tw`flex flex-col w-full py-10`}
  ${({ divider }) => (divider ? tw`divide-y divide-gray-500 gap-0` : tw`gap-5 lg:gap-8`)}
  > div {
    ${({ divider }) => divider && tw`py-8 first:pt-0 last:pb-0`}
  }
`
