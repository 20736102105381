import React from 'react'
import { useTranslation } from 'react-i18next'
import { IndicatorWrapper } from '../shared/indicators/SeverityLevelIndicator'
import { AwsWorkflowStatus } from '../../api/compliance/tabs'

export enum ComplianceStatus {
  FAILED = 'FAILED',
  NonCompliant = 'NonCompliant',
  ACTIVE = 'ACTIVE',
  PASSED = 'PASSED',
  Compliant = 'Compliant',
  INACTIVE = 'INACTIVE'
}

export interface StatusIndicatorProps {
  status: ComplianceStatus | AwsWorkflowStatus
}

const passedStatus = [ComplianceStatus.PASSED, ComplianceStatus.Compliant, ComplianceStatus.INACTIVE]

const failedStatus = [ComplianceStatus.FAILED, ComplianceStatus.NonCompliant, ComplianceStatus.ACTIVE]

export const ComplianceStatusIndicator = ({ status }: StatusIndicatorProps) => {
  const { t } = useTranslation()
  const color =
    failedStatus.includes(status as ComplianceStatus) || status === AwsWorkflowStatus.NEW
      ? { bg: 'bg-error-500/50', text: 'text-primary-500' }
      : status === AwsWorkflowStatus.NOTIFIED
        ? { bg: 'bg-tertiary-500/35', text: 'text-tertiary-500' }
        : passedStatus.includes(status as ComplianceStatus) || status === AwsWorkflowStatus.RESOLVED
          ? { bg: 'bg-success-500/40', text: 'text-tertiary-200' }
          : { bg: 'bg-gray-300/40', text: 'text-gray-200' }

  return (
    <IndicatorWrapper className={`${color.bg} ${color.text}`}>
      {passedStatus.includes(status as ComplianceStatus)
        ? t('compliance.status.pass')
        : failedStatus.includes(status as ComplianceStatus)
          ? t('compliance.status.fail')
          : status}
    </IndicatorWrapper>
  )
}
