import { AdminContentWrapper } from '../../adminStyles'
import React, { useContext, useEffect, useState } from 'react'
import { useCancelToken } from '../../../../api/client'
import { useTranslation } from 'react-i18next'
import { MessageContext, MessageType } from '../../../../state/context/MessageContext'
import { ModalContext } from '../../../../state/context/ModalContext'
import { useErrorHandling } from '../../../../hooks/handleError'
import {
  addGcpBillingAccount,
  deleteGcpIntegration,
  editGcpBillingAccount,
  editGcpIntegration,
  GcpIntegrationRequest,
  recoverGcpBillingAccount,
  recoverGcpIntegration,
  softDeleteGcpBillingAccount
} from '../../../../api/admin/integrations/gcp'
import { useNavigate, useParams } from 'react-router-dom'
import {
  DetailedIntegrationModal,
  IntegrationDetailsInfoSection,
  IntegrationDetailsPageHeader,
  IntegrationDetailsWrapper,
  IntegrationsTab,
  IntegrationsTabs
} from '../Shared'
import { GcpIntegrationsAddModal, hasGcpIntegrationErrors } from './GcpIntegrations'
import { GcpIntegrationAccounts } from './GcpIntegrationAccounts'
import { GcpIntegrationTimestamps } from './GcpIntegrationTimestamps'
import { UserInfoContext } from '../../../../state/context/UserInfoContext'
import {
  GcpBillingAccountIntegration,
  GcpIntegrationsState,
  getIntegrationStates
} from '../../../../api/settings/profile'
import { Layout } from '../../../../layout/Layout'
import { MissingDataNotification } from '../../../shared/MissingDataNotification'

export const GcpIntegrationDetails = () => {
  const { createCancelToken } = useCancelToken()
  const { integrationStates, setIntegrationStates } = useContext(UserInfoContext)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { organizationId } = useParams<{
    organizationId: string
  }>()
  const { setMessage } = useContext(MessageContext)
  const { setModal } = useContext(ModalContext)
  const handleError = useErrorHandling()
  const [selectedIntegration, setSelectedIntegration] = useState<GcpIntegrationsState | null>(null)

  useEffect(() => {
    if (organizationId) {
      setSelectedIntegration(
        integrationStates.gcp?.find(integration => integration.organizationId === organizationId) || null
      )
    }
  }, [integrationStates, createCancelToken, handleError, organizationId])

  if (!selectedIntegration || !organizationId) return <MissingDataNotification />

  const editIntegration = (request: string) => {
    editGcpIntegration(selectedIntegration.organizationId, request, createCancelToken().token)
      .then(() => {
        getIntegrationStates(createCancelToken().token)
          .then(resp => {
            setIntegrationStates(resp)
            setSelectedIntegration(
              resp?.gcp?.find(integration => integration.organizationId === organizationId) || null
            )
            setModal(null)
            setMessage({ type: MessageType.SUCCESS, message: t('admin.integrations.gcp.organizationEditSuccessToast') })
          })
          .catch(handleError)
      })
      .catch(handleError)
  }

  const editBillingAccount = (request: GcpBillingAccountIntegration) => {
    editGcpBillingAccount(selectedIntegration.organizationId, request, createCancelToken().token)
      .then(() => {
        getIntegrationStates(createCancelToken().token)
          .then(resp => {
            setIntegrationStates(resp)
            setSelectedIntegration(
              resp?.gcp?.find(integration => integration.organizationId === organizationId) || null
            )
            setModal(null)
            setMessage({ type: MessageType.SUCCESS, message: t('admin.integrations.gcp.accountEditSuccessToast') })
          })
          .catch(handleError)
      })
      .catch(handleError)
  }

  const recoverIntegration = () => {
    recoverGcpIntegration(organizationId)
      .then(() => {
        getIntegrationStates(createCancelToken().token)
          .then(resp => {
            setIntegrationStates(resp)
            setSelectedIntegration(
              resp?.gcp?.find(integration => integration.organizationId === organizationId) || null
            )
            setModal(null)
            setMessage({
              type: MessageType.SUCCESS,
              message: t('admin.integrations.gcp.orgRecoverSuccessToast')
            })
          })
          .catch(handleError)
      })
      .catch(handleError)
  }

  const openAccountAddModal = (billingAccount?: GcpBillingAccountIntegration) => {
    const recoverAccount = (billingAccount: GcpBillingAccountIntegration) => {
      recoverGcpBillingAccount(organizationId, billingAccount, createCancelToken().token)
        .then(() => {
          getIntegrationStates(createCancelToken().token)
            .then(resp => {
              setIntegrationStates(resp)
              setSelectedIntegration(
                resp?.gcp?.find(integration => integration.organizationId === organizationId) || null
              )
              setModal(null)
              setMessage({ type: MessageType.SUCCESS, message: t('admin.integrations.gcp.accountRecoverSuccessToast') })
            })
            .catch(handleError)
        })
        .catch(handleError)
    }

    const addBillingAccount = (request: GcpIntegrationRequest) => {
      const account = integrationStates?.gcp
        ?.find(o => o.organizationId === organizationId)
        ?.billingAccounts.find(
          account => account.accountId === request.accountId && account.projectId === request.projectId
        )
      if (account?.deletedAt) {
        setModal({
          header: t('admin.integrations.gcp.accountRecoveryHeader'),
          body: (
            <DetailedIntegrationModal
              description={t('admin.integrations.gcp.accountRecoveryMessage')}
              detailRows={[
                { label: t('admin.integrations.accountId'), value: account.accountId },
                { label: t('admin.integrations.gcp.projectId'), value: account.projectId },
                { label: t('admin.integrations.gcp.bigQueryTableId'), value: account.tableId }
              ]}
              action={() => recoverAccount(account)}
              actionLabel={t('admin.integrations.recover')}
            />
          )
        })
      } else {
        const cancelToken = createCancelToken()
        addGcpBillingAccount(request, cancelToken.token)
          .then(() => {
            getIntegrationStates(cancelToken.token)
              .then(resp => {
                setIntegrationStates(resp)
                setSelectedIntegration(
                  resp?.gcp?.find(integration => integration.organizationId === organizationId) || null
                )
                setModal(null)
                setMessage({ type: MessageType.SUCCESS, message: t('admin.integrations.gcp.accountAddSuccessToast') })
              })
              .catch(handleError)
          })
          .catch(handleError)
      }
    }

    if (billingAccount) {
      recoverAccount(billingAccount)
    } else {
      setModal({
        header: t('admin.integrations.gcp.setAccountDetails'),
        body: (
          <GcpIntegrationsAddModal
            selectedIntegration={selectedIntegration}
            submitAction={addBillingAccount}
            accountOnly={true}
          />
        )
      })
    }
  }

  const openOrganizationDeleteModal = () => {
    const deleteAction = () =>
      deleteGcpIntegration(selectedIntegration.organizationId)
        .then(() => {
          navigate('/admin/integrations/gcp')
          getIntegrationStates(createCancelToken().token).then(setIntegrationStates).catch(handleError)
          setModal(null)
          setMessage({
            type: MessageType.SUCCESS,
            message: t('admin.integrations.gcp.removeSuccessToast')
          })
        })
        .catch(handleError)

    setModal({
      header: t('admin.integrations.confirmRemoveRequest'),
      body: (
        <DetailedIntegrationModal
          description={t('admin.integrations.integrationStopMessage', {
            target: t('admin.integrations.gcp.organization')
          })}
          detailRows={[
            { label: t('admin.integrations.gcp.organizationId'), value: selectedIntegration.organizationId },
            { label: t('admin.integrations.name'), value: selectedIntegration.organizationName }
          ]}
          action={deleteAction}
          actionLabel={t('admin.integrations.remove')}
        />
      )
    })
  }

  const openAccountDeleteModal = (billingAccount: GcpBillingAccountIntegration) => {
    const deleteAction = () => {
      softDeleteGcpBillingAccount(selectedIntegration.organizationId, billingAccount)
        .then(resp => {
          setSelectedIntegration(resp)
          getIntegrationStates(createCancelToken().token).then(setIntegrationStates).catch(handleError)
          setModal(null)
          setMessage({
            type: MessageType.SUCCESS,
            message: t('admin.integrations.gcp.accountRemoveSuccessToast')
          })
        })
        .catch(handleError)
    }

    setModal({
      header: t('admin.integrations.confirmRemoveRequest'),
      body: (
        <DetailedIntegrationModal
          description={t('admin.integrations.integrationStopMessage', {
            target: t('admin.integrations.gcp.billingAccount_one')
          })}
          detailRows={[
            { label: t('admin.integrations.gcp.projectId'), value: billingAccount.projectId },
            { label: t('admin.integrations.accountId'), value: billingAccount.accountId },
            { label: t('admin.integrations.gcp.bigQueryTableId'), value: billingAccount.tableId }
          ]}
          action={deleteAction}
          actionLabel={t('admin.integrations.remove')}
        />
      )
    })
  }

  return (
    <Layout type={'sub'}>
      <IntegrationDetailsPageHeader
        name={selectedIntegration.organizationName}
        lastIntegrationAt={selectedIntegration.lastOrganizationIntegrationAt}
        buttonValue={selectedIntegration.deletedAt && t('admin.integrations.recover')}
        buttonAction={!!selectedIntegration?.deletedAt && recoverIntegration}
        deleteAction={!selectedIntegration?.deletedAt && openOrganizationDeleteModal}
        backButtonPath={'/admin/integrations/gcp'}
      />
      <AdminContentWrapper>
        <IntegrationDetails
          key={selectedIntegration?.billingAccounts?.length}
          selectedIntegration={selectedIntegration}
          setSelectedIntegration={setSelectedIntegration}
          editIntegrationAction={editIntegration}
          editBillingAccountAction={editBillingAccount}
          openAccountAddModal={openAccountAddModal}
          openAccountDeleteModal={openAccountDeleteModal}
        />
      </AdminContentWrapper>
    </Layout>
  )
}

interface IntegrationDetailsProps {
  selectedIntegration: GcpIntegrationsState
  setSelectedIntegration: (integration: GcpIntegrationsState | null) => void
  openAccountAddModal: (account?: GcpBillingAccountIntegration) => void
  openAccountDeleteModal: (account: GcpBillingAccountIntegration) => void
  editIntegrationAction: (request: string) => void
  editBillingAccountAction: (request: GcpBillingAccountIntegration) => void
}

const IntegrationDetails = ({
  selectedIntegration,
  setSelectedIntegration,
  openAccountAddModal,
  openAccountDeleteModal,
  editIntegrationAction,
  editBillingAccountAction
}: IntegrationDetailsProps) => {
  const { integrationStates } = useContext(UserInfoContext)
  const [selectedTab, setSelectedTab] = useState(IntegrationsTab.DETAILS)

  return (
    <>
      <IntegrationsTabs
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        hasErrors={hasGcpIntegrationErrors(integrationStates.gcp)}
      />
      <IntegrationDetailsWrapper>
        <IntegrationDetailsInfoSection removeDate={selectedIntegration.deletedAt} selectedTab={selectedTab} />
        {selectedTab === IntegrationsTab.DETAILS ? (
          <GcpIntegrationAccounts
            selectedIntegration={selectedIntegration}
            openAccountAddModal={openAccountAddModal}
            openAccountDeleteModal={openAccountDeleteModal}
            editIntegrationAction={editIntegrationAction}
            editBillingAccountAction={editBillingAccountAction}
          />
        ) : (
          <GcpIntegrationTimestamps
            selectedIntegration={selectedIntegration}
            setSelectedIntegration={setSelectedIntegration}
          />
        )}
      </IntegrationDetailsWrapper>
    </>
  )
}
