import serviceRequest from '../../../assets/svg/flat-colored/service-request.svg'
import incident from '../../../assets/svg/flat-colored/incident.svg'
import majorIncident from '../../../assets/svg/flat-colored/fire.svg'
import open from '../../../assets/svg/actions/open-in-new-tab.svg'
import styled from 'styled-components'
import tw from 'twin.macro'
import React, { useContext, useRef, useState } from 'react'
import { FreshTicket, TicketPriority, TicketStatus, TicketType } from '../../../api/tickets'
import { useTranslation } from 'react-i18next'
import { UserInfoContext } from '../../../state/context/UserInfoContext'
import { CustomIcon, IconType } from '../../shared/CustomIcon'
import { GrayText, Heading4, WhiteText } from '../../shared/TextComponents'
import { SeverityLevelIndicator } from '../../shared/indicators/SeverityLevelIndicator'
import { formatDate } from '../../../utils/formats'
import { MissingDataNotification } from '../../shared/MissingDataNotification'

interface TableProps {
  tickets: FreshTicket[]
}

export const TicketTable = ({ tickets }: TableProps) => {
  const { t } = useTranslation()

  if (!tickets.length)
    return (
      <TicketSummary>
        <MissingDataNotification paddingY={6} justify={'start'} displayText={t('tickets.noTickets')} />
      </TicketSummary>
    )

  return (
    <Table>
      {tickets.map(ticket => (
        <Ticket key={ticket.ticketId} ticket={ticket} />
      ))}
    </Table>
  )
}

const Table = styled.div`
  ${tw`grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 w-full gap-x-3 gap-y-4`}
`

interface TicketProps {
  ticket: FreshTicket
}

const Ticket = ({ ticket }: TicketProps) => {
  const ref = useRef(null)
  const [selected, setSelected] = useState<number[]>([])
  const isOpen = selected.includes(ticket.ticketId)

  return (
    <div key={ticket.ticketId} ref={ref}>
      <TicketSummary
        onClick={() =>
          !selected?.includes(ticket.ticketId)
            ? setSelected([...selected, ticket.ticketId])
            : setSelected(selected.filter(id => id !== ticket.ticketId))
        }
      >
        <div className={'flex flex-row items-center w-full gap-4 justify-between'}>
          <div className={'flex gap-2 items-center'}>
            <TicketTypeIcon type={ticket.type} priority={ticket.priority} />
            <WhiteText>{ticket.type}</WhiteText>
          </div>
          <SeverityLevelIndicator level={ticket.priority} />
        </div>
        <Heading4>{ticket.subject}</Heading4>
        <GrayText className={'self-start font-light'}>
          {ticket.description.substring(0, 100)}
          {'...'}
        </GrayText>
      </TicketSummary>
      <TicketDetails ticket={ticket} isOpen={isOpen} />
    </div>
  )
}

export const TicketContainer = styled.div`
  ${tw`px-5 sm:px-8`}
`

const TicketSummary = styled(TicketContainer)`
  ${tw`flex flex-col w-full gap-6 bg-gray-600/90 hover:bg-gray-600 items-center rounded-md cursor-pointer py-6`}
`

interface TicketDetailsProps {
  ticket: FreshTicket
  isOpen: boolean
}

const TicketDetails = ({ ticket, isOpen }: TicketDetailsProps) => {
  const { t } = useTranslation()
  const { authInfo } = useContext(UserInfoContext)
  const ticketDomain = authInfo.emailAddress.includes('@cloud2.fi') ? 'cloud2oy.freshservice.com' : ticket.domain
  const hasDueDate = ticket.status === TicketStatus.OPEN || ticket.status === TicketStatus.PENDING
  const hasResolvedDate = ticket.status === TicketStatus.RESOLVED || ticket.status === TicketStatus.CLOSED

  return (
    <TicketContainer
      className={`w-full text-90 transition-all ease-in-out border-t border-dashed ${isOpen ? 'flex flex-col py-6 gap-6 border-gray-500 bg-gray-600/90' : 'py-0 border-transparent'}`}
    >
      {isOpen && (
        <>
          <ResponsiveWrapper>
            <DetailsWrapper>
              <InnerWrapper>
                <div>{t('tickets.ticketDetails.id')}</div>
                <div>{t('tickets.ticketDetails.created')}</div>
                {hasDueDate ? (
                  <div>{t('tickets.ticketDetails.due')}</div>
                ) : hasResolvedDate ? (
                  <div>{t('tickets.ticketDetails.resolved')}</div>
                ) : null}
                <div>{t('tickets.ticketDetails.category')}</div>
              </InnerWrapper>
              <InnerWrapper>
                <GrayText>{ticket.ticketId}</GrayText>
                <GrayText>{formatDate(ticket.ticketCreatedAt)}</GrayText>
                {hasDueDate ? (
                  <GrayText>{formatDate(ticket.dueBy)}</GrayText>
                ) : hasResolvedDate ? (
                  <GrayText>{formatDate(ticket.ticketUpdatedAt)}</GrayText>
                ) : null}
                {ticket.category === null ? <GrayText>{' -'}</GrayText> : <GrayText>{ticket.category}</GrayText>}
              </InnerWrapper>
            </DetailsWrapper>
            {ticket.domain && (
              <a
                target={'_blank'}
                rel={'noreferrer'}
                href={`https://${ticketDomain}/helpdesk/tickets/${ticket.ticketId}`}
              >
                <LinkToTab>
                  {t('tickets.ticketDetails.openFreshTicket')}
                  <CustomIcon iconType={IconType.VECTOR} path={open} styles={'w-3 h-3 bg-gray-50'} />
                </LinkToTab>
              </a>
            )}
          </ResponsiveWrapper>
          <DescriptionWrapper>
            <Heading4>{t('tickets.ticketDetails.fullDescription')}</Heading4>
            <FullDescription>{ticket.description}</FullDescription>
          </DescriptionWrapper>
        </>
      )}
    </TicketContainer>
  )
}

const ResponsiveWrapper = styled.div`
  ${tw`flex flex-col xl:flex-row gap-4 xl:gap-0 items-start justify-between`}
`

const DetailsWrapper = styled.div`
  ${tw`flex flex-row gap-4`}
`

const InnerWrapper = styled.div`
  ${tw`flex flex-col items-start font-light`}
`

const DescriptionWrapper = styled.div`
  ${tw`flex flex-col gap-4`}
`

const LinkToTab = styled.span`
  ${tw`flex flex-row text-75 gap-1`}
`

const FullDescription = styled.div`
  ${tw`font-light text-gray-200 break-words`}
`

interface TicketTypeIconProps {
  type: TicketType
  priority: TicketPriority
}

const TicketTypeIcon = ({ type, priority }: TicketTypeIconProps) => {
  const color =
    priority === TicketPriority.URGENT
      ? 'bg-primary-600/70'
      : priority === TicketPriority.HIGH
        ? 'bg-primary-500/70'
        : priority === TicketPriority.MEDIUM
          ? 'bg-warn-500/70'
          : priority === TicketPriority.LOW
            ? 'bg-tertiary-500/70'
            : ''
  return (
    <CustomIcon
      path={
        type === 'Incident'
          ? incident
          : type === 'Major Incident'
            ? majorIncident
            : type === 'Service Request'
              ? serviceRequest
              : ''
      }
      styles={`w-7 h-8 ${color}`}
    />
  )
}
