import { createContext, useMemo, useState } from 'react'

export interface NavbarState {
  navOpen: boolean
  setNavOpen: (isOpen: boolean) => void
}

const defaultSidebarDrawerState: NavbarState = {
  navOpen: false,
  setNavOpen: () => undefined
}

export const NavbarContext = createContext<NavbarState>(defaultSidebarDrawerState)

interface Props {
  children: JSX.Element
}

export const NavbarContextProvider = ({ children }: Props) => {
  const [navOpen, setNavOpen] = useState<boolean>(defaultSidebarDrawerState.navOpen)
  const drawerValue = useMemo(() => ({ navOpen, setNavOpen }), [navOpen])

  return <NavbarContext.Provider value={drawerValue}>{children}</NavbarContext.Provider>
}
