import { Vendor } from '../../utils/vendors'
import { CancelToken } from 'axios'
import { client } from '../client'
import { SeverityLevel } from '../../components/shared/indicators/SeverityLevelIndicator'
import { CloudProject, CloudRegion, CloudResource } from '../../utils/classes'
import { ComplianceStatus } from '../../components/compliance/ComplianceStatusIndicator'
import { DataBlockData } from '../../components/shared/containers/DataBlock'

export const getComplianceSummaryTabData = async (
  vendors: Vendor[],
  projects: string[],
  resourceGroups: string[],
  standards: string[],
  regions: string[],
  cancelToken: CancelToken
): Promise<ComplianceSummaryTabData> => {
  return await client
    .post(
      '/compliance/tabs/summary-tab',
      {
        vendors,
        projects,
        resourceGroups,
        standards,
        regions
      },
      {
        cancelToken: cancelToken
      }
    )
    .then(resp => resp.data)
}

export const getComplianceAwsTabFindings = async (
  vendors: Vendor[],
  projects: string[],
  resourceGroups: string[],
  standards: string[],
  regions: string[],
  cancelToken: CancelToken
): Promise<AwsComplianceFindingsByControl[]> => {
  return await client
    .post<AwsComplianceFindingsByControl[]>(
      '/compliance/tabs/aws-tab',
      {
        vendors,
        projects,
        resourceGroups,
        standards,
        regions
      },
      {
        cancelToken: cancelToken
      }
    )
    .then(resp =>
      resp.data.map(c => ({
        ...c,
        findings: c.findings.map(f => ({
          ...f,
          observedAt: {
            first: f.observedAt.first ? new Date(f.observedAt.first) : null,
            last: f.observedAt.last ? new Date(f.observedAt.last) : null
          }
        }))
      }))
    )
}

export const getComplianceAzureTabFindings = async (
  vendors: Vendor[],
  projects: string[],
  resourceGroups: string[],
  standards: string[],
  regions: string[],
  cancelToken: CancelToken
): Promise<AzureComplianceFindingsByControl[]> => {
  const resp = await client.post(
    '/compliance/tabs/azure-tab',
    {
      vendors,
      projects,
      resourceGroups,
      standards,
      regions
    },
    {
      cancelToken: cancelToken
    }
  )
  return resp.data
}

export const getComplianceGcpTabFindings = async (
  vendors: Vendor[],
  projects: string[],
  resourceGroups: string[],
  standards: string[],
  regions: string[],
  cancelToken: CancelToken
): Promise<GcpComplianceFindingsByControl[]> => {
  const resp = await client.post(
    'compliance/tabs/gcp-tab',
    {
      vendors,
      projects,
      resourceGroups,
      standards,
      regions
    },
    {
      cancelToken: cancelToken
    }
  )
  return resp.data
}

export const downloadComplianceCsv = async (
  vendors: Vendor[],
  projects: string[],
  resourceGroups: string[],
  standards: string[],
  regions: string[],
  cancelToken: CancelToken
) => {
  const resp = await client.post<string>(
    `/compliance/download-csv`,
    {
      vendors,
      projects,
      resourceGroups,
      standards,
      regions
    },
    {
      cancelToken: cancelToken,
      responseType: 'blob'
    }
  )
  const url = window.URL.createObjectURL(new Blob([resp.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `spotter_compliance_findings_${new Date().toLocaleDateString()}.csv`)
  document.body.appendChild(link)
  link.click()
}

export interface ComplianceSummaryTabData {
  awsAzure: AwsAzureComplianceSummaryTabData | null
  gcp: GcpComplianceSummaryTabData | null
}

export interface AwsAzureComplianceSummaryTabData {
  accountCount: number
  subCount: number
  leastCompliant: DataBlockData[]
  mostCompliant: DataBlockData[]
  mostFailedChecks: DataBlockData[]
}

export interface GcpComplianceSummaryTabData {
  projectCount: number
  mostFindings: DataBlockData[]
}

export interface AwsComplianceFindingsByControl {
  control: string
  explanation: string
  remediation: string | null
  remediationUrl: string | null
  severity: SeverityLevel
  failedCount: number
  findings: AwsFinding[]
}

export interface AwsFinding {
  project: CloudProject
  resource: CloudResource
  region: CloudRegion
  observedAt: ObservedAt
  severity: SeverityLevel
  status: ComplianceStatus
  statusReasons: string[]
  workflowStatus: AwsWorkflowStatus
  recordState: string
  standards: string[]
  relatedRequirements: string[]
}

export interface ObservedAt {
  first: Date | null
  last: Date | null
}

export interface AzureComplianceFindingsByControl {
  control: string
  severity: SeverityLevel
  explanation: string
  explanationUrl: HtmlLink[]
  remediation: string | null
  remediationUrl: HtmlLink[]
  failedCount: number
  findings: AzureFinding[]
}

export interface AzureFinding {
  project: CloudProject
  resource: CloudResource
  region: CloudRegion | null
  standards: string[]
  control: string
  severity: SeverityLevel
  status: ComplianceStatus
}

export interface GcpComplianceFindingsByControl {
  control: string
  explanation: string
  remediation: string
  remediationUrl: string
  severity: SeverityLevel
  failedCount: number
  findings: GcpFinding[]
}

export interface GcpFinding {
  project: CloudProject
  resource: CloudResource
  region: CloudRegion
  status: ComplianceStatus
  severity: SeverityLevel
  standards: GcpStandard[]
}

interface GcpStandard {
  standard: string
  versions: GcpStandardVersion[]
}

interface GcpStandardVersion {
  version: string
  controls: string[]
}

export interface HtmlLink {
  text: string
  href: string
}

export enum AwsWorkflowStatus {
  NEW = 'NEW',
  NOTIFIED = 'NOTIFIED',
  RESOLVED = 'RESOLVED',
  SUPPRESSED = 'SUPPRESSED'
}
