import React, { useContext, useState } from 'react'
import { ComplianceChartData, ComplianceChartEntry } from '../../../api/compliance/common'
import { Vendor } from '../../../utils/vendors'
import { formatNumber } from '../../../utils/formats'
import { useTranslation } from 'react-i18next'
import { Loading } from '../../shared/Loading'
import { ToggleGroup } from '../../shared/buttons/ToggleGroup'
import { SwitchButton } from '../../shared/buttons/SwitchButton'
import {
  CustomLineChart,
  LineChartContainer,
  LineChartHeader,
  LineChartTooltip
} from '../../shared/charts/CustomLineChart'
import { UserInfoContext } from '../../../state/context/UserInfoContext'
import { TimeframeOption } from '../../../utils/classes'

interface ChartProps {
  selectedVendors: Vendor[]
  chartData: ComplianceChartData[]
  timeframe: TimeframeOption
  setChartTimeframe: (timeFrame: TimeframeOption) => void
  loading: boolean
}

export const ComplianceHistoryChart = ({
  selectedVendors,
  chartData,
  timeframe,
  setChartTimeframe,
  loading
}: ChartProps) => {
  const { t } = useTranslation()
  const { userSettings } = useContext(UserInfoContext)
  const [hideComboLine, setHideComboLine] = useState(true)
  const timeFrameOptions = [
    {
      clickHandler: () => setChartTimeframe(TimeframeOption.THIRTY_DAYS),
      checked: timeframe === TimeframeOption.THIRTY_DAYS,
      value: t('compliance.tabs.summary.MONTH')
    },
    {
      clickHandler: () => setChartTimeframe(TimeframeOption.TWELVE_MONTHS),
      checked: timeframe === TimeframeOption.TWELVE_MONTHS,
      value: t('compliance.tabs.summary.YEAR')
    }
  ]

  const gcpCounts = chartData
    ?.filter(vendor => vendor.vendor === Vendor.GCP)
    ?.flatMap(vendor => vendor.chartEntries?.map(entry => entry.totalCount ?? 0)) ?? [0]
  const gcpMaxDomain = Math.max(...gcpCounts) < 1 ? 4 : Math.max(...gcpCounts)

  const comboLineToggleIsUsable = selectedVendors.includes(Vendor.AWS) && selectedVendors.includes(Vendor.AZURE)
  const showComboLine = comboLineToggleIsUsable && !hideComboLine
  const percentAxisTicks = [0, 0.25, 0.5, 0.75, 1]

  if (loading)
    return (
      <LineChartContainer>
        <Loading paddingY={'20%'} />
      </LineChartContainer>
    )

  return (
    <LineChartContainer>
      <LineChartHeader
        heading={`${t('compliance.tabs.summary.summaryHeading')}`}
        actions={
          <>
            <ToggleGroup minWidth={'12rem'} maxWidth={'12rem'} buttons={timeFrameOptions} />
            {userSettings.visibleVendors.includes(Vendor.AWS) && userSettings.visibleVendors.includes(Vendor.AZURE) && (
              <SwitchButton
                clickHandler={() => setHideComboLine(!hideComboLine)}
                checked={showComboLine}
                label={`${t(`vendors.AWS.short`)} & ${t('vendors.AZURE.short')} ${t('common.total.reg')}`}
                disabled={!comboLineToggleIsUsable}
              />
            )}
          </>
        }
      />
      <CustomLineChart
        chartData={chartData}
        domain={[0, 1]}
        tooltip={<ComplianceTooltip />}
        tooltipLabel={({ datum }) => (datum.vendor === Vendor.GCP ? datum.totalCount : datum.compliantPercent)}
        selectedVendors={selectedVendors}
        showVendorLegend={true}
        timeframe={timeframe}
        showLeftAxis={selectedVendors.includes(Vendor.AWS) || selectedVendors.includes(Vendor.AZURE)}
        leftAxisTicks={percentAxisTicks}
        formatLeftTicks={t => `${Math.round(t * 100)} %`}
        leftYAxisDataKey={datum =>
          datum.vendor === Vendor.GCP
            ? datum.totalCount && datum.totalCount / gcpMaxDomain
            : datum.compliantPercent && datum.compliantPercent / 100
        }
        showRightYAxis={selectedVendors.includes(Vendor.GCP)}
        rightAxisTicks={
          gcpMaxDomain === 1 ? [1] : gcpMaxDomain === 2 || gcpMaxDomain === 3 ? [0, 0.5, 1] : [0, 0.25, 0.5, 0.75, 1]
        }
        formatRightTicks={t => (!chartData.length ? '' : `${Math.ceil(t * gcpMaxDomain)}`)}
        hideComboLine={hideComboLine}
      />
    </LineChartContainer>
  )
}

interface TooltipProps {
  active?: boolean
  x?: number
  y?: number
  datum?: ComplianceChartEntry
}

const ComplianceTooltip = ({ active, x, y, datum }: TooltipProps) => {
  const { t } = useTranslation()
  if (!datum) return null

  return (
    <LineChartTooltip
      active={active}
      x={x}
      y={y}
      width={datum.vendor === Vendor.GCP ? 120 : 168}
      height={68}
      label={
        datum.vendor === Vendor.GCP
          ? t('compliance.tabs.summary.failedControls')
          : `${t('compliance.tabs.compliantResources')}`
      }
      value={
        datum.vendor === Vendor.GCP
          ? formatNumber(datum.totalCount)
          : `${formatNumber(datum.compliantCount)} / ${formatNumber(datum.totalCount)} (${datum.compliantPercent} %)`
      }
    />
  )
}
