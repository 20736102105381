import {
  DetailedIntegrationModal,
  IntegrationDetailRow,
  IntegrationDetailRowsWrapper,
  IntegrationDetailsHeader,
  IntegrationDetailsInfoSection,
  IntegrationDetailsPageHeader,
  IntegrationDetailsWrapper,
  IntegrationsTab,
  IntegrationsTabs,
  IntegrationTimestampsSection
} from '../Shared'
import { AdminContentWrapper, AdminTabContent } from '../../adminStyles'
import React, { useContext, useEffect, useState } from 'react'
import {
  AzureIntegrationRequest,
  deleteAzureIntegration,
  editAzureIntegration,
  recoverAzureIntegration
} from '../../../../api/admin/integrations/azure'
import { Trans, useTranslation } from 'react-i18next'
import { MessageContext, MessageType } from '../../../../state/context/MessageContext'
import { useNavigate, useParams } from 'react-router-dom'
import { useErrorHandling } from '../../../../hooks/handleError'
import { ModalContext } from '../../../../state/context/ModalContext'
import { useCancelToken } from '../../../../api/client'
import { useForm } from 'react-hook-form'
import { CustomInput, CustomLabel } from '../../../shared/filters/FormComponents'
import { formatDate } from '../../../../utils/formats'
import { UserInfoContext } from '../../../../state/context/UserInfoContext'
import { AzureIntegrationsState, getIntegrationStates } from '../../../../api/settings/profile'
import { Layout } from '../../../../layout/Layout'
import { ModalActions } from '../../../shared/modal/Modal'
import { Button, ButtonStyle, ButtonType } from '../../../shared/buttons/Button'
import { SpotterDocumentationLink } from '../../../login/Shared'
import { MissingDataNotification } from '../../../shared/MissingDataNotification'

export const AzureIntegrationDetails = () => {
  const { t } = useTranslation()
  const { createCancelToken } = useCancelToken()
  const { integrationStates, setIntegrationStates } = useContext(UserInfoContext)
  const { azureTenantId } = useParams<{
    azureTenantId: string
  }>()
  const { setMessage } = useContext(MessageContext)
  const { setModal } = useContext(ModalContext)
  const [selectedIntegration, setSelectedIntegration] = useState<AzureIntegrationsState | null>(null)
  const navigate = useNavigate()
  const handleError = useErrorHandling()

  useEffect(() => {
    if (azureTenantId) {
      setSelectedIntegration(integrationStates.azure?.find(i => i.azureTenantId === azureTenantId) || null)
    }
  }, [integrationStates, azureTenantId])

  if (!selectedIntegration || !azureTenantId) return <MissingDataNotification />

  const openDeleteModal = () => {
    const deleteAction = () => {
      deleteAzureIntegration(selectedIntegration.azureTenantId)
        .then(() => {
          getIntegrationStates(createCancelToken().token).then(setIntegrationStates).catch(handleError)
          setModal(null)
          setMessage({
            type: MessageType.SUCCESS,
            message: t('admin.integrations.azure.deleteSuccessToast')
          })
          navigate('/admin/integrations/azure')
        })
        .catch(handleError)
    }

    setModal({
      header: t('admin.integrations.confirmRemoveRequest'),
      body: (
        <DetailedIntegrationModal
          description={t('admin.integrations.integrationStopMessage', {
            target: t('admin.integrations.azure.azureTenant')
          })}
          detailRows={[
            { label: t('admin.integrations.azure.tenantId'), value: selectedIntegration.azureTenantId },
            { label: t('admin.integrations.name'), value: selectedIntegration.name }
          ]}
          action={deleteAction}
          actionLabel={t('admin.integrations.remove')}
        />
      )
    })
  }

  const recoverIntegration = () => {
    recoverAzureIntegration(azureTenantId)
      .then(resp => {
        setSelectedIntegration(resp)
        getIntegrationStates(createCancelToken().token).then(setIntegrationStates).catch(handleError)
        setModal(null)
        setMessage({
          type: MessageType.SUCCESS,
          message: t('admin.integrations.azure.recoverSuccessToast')
        })
      })
      .catch(handleError)
  }

  const editIntegration = (request: AzureIntegrationRequest) => {
    editAzureIntegration(request, createCancelToken().token).then(data => {
      setSelectedIntegration(data)
      getIntegrationStates(createCancelToken().token).then(setIntegrationStates).catch(handleError)
      setMessage({
        type: MessageType.SUCCESS,
        message: t('admin.integrations.azure.editSuccessToast')
      })
    })
  }

  return (
    <Layout type={'sub'}>
      <IntegrationDetailsPageHeader
        name={selectedIntegration.name}
        lastIntegrationAt={selectedIntegration.lastOrganizationIntegrationAt}
        buttonValue={selectedIntegration.deletedAt && t('admin.integrations.recover')}
        buttonAction={!!selectedIntegration?.deletedAt && recoverIntegration}
        deleteAction={!selectedIntegration?.deletedAt && openDeleteModal}
        backButtonPath={'/admin/integrations/azure'}
      />
      <AdminContentWrapper className={'max-h-[80vh] xl:max-h-[86vh]'}>
        <IntegrationDetails selectedIntegration={selectedIntegration} submitAction={editIntegration} />
      </AdminContentWrapper>
    </Layout>
  )
}

interface IntegrationDetailsProps {
  selectedIntegration: AzureIntegrationsState
  submitAction: (request: AzureIntegrationRequest) => void
}

const IntegrationDetails = ({ selectedIntegration, submitAction }: IntegrationDetailsProps) => {
  const [selectedTab, setSelectedTab] = useState<IntegrationsTab>(IntegrationsTab.DETAILS)
  return (
    <div>
      <IntegrationsTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} hasErrors={false} />
      <IntegrationDetailsWrapper>
        <IntegrationDetailsInfoSection removeDate={selectedIntegration.deletedAt} selectedTab={selectedTab} />
        {selectedTab === IntegrationsTab.DETAILS ? (
          <AzureTenantForm selectedIntegration={selectedIntegration} submitAction={submitAction} />
        ) : (
          <IntegrationTimestamps selectedIntegration={selectedIntegration} />
        )}
      </IntegrationDetailsWrapper>
    </div>
  )
}

interface AzureTenantFormProps {
  selectedIntegration: AzureIntegrationsState
  submitAction: (request: AzureIntegrationRequest) => void
}

const AzureTenantForm = ({ selectedIntegration, submitAction }: AzureTenantFormProps) => {
  const { t } = useTranslation()
  const { setModal } = useContext(ModalContext)
  const [defaultValues, setDefaultValues] = useState<AzureIntegrationRequest>({
    name: selectedIntegration.name,
    azureTenantId: selectedIntegration.azureTenantId
  })

  const EditModalBody = () => {
    const {
      register,
      handleSubmit,
      reset,
      formState: { isDirty, isValid, isSubmitting }
    } = useForm<AzureIntegrationRequest>({ defaultValues: defaultValues })

    const onSubmit = (data: AzureIntegrationRequest) => {
      submitAction(data)
      setDefaultValues(data)
      reset(data)
    }

    return (
      <>
        <div className={'pb-3'}>
          <CustomLabel>{t('admin.integrations.name')} *</CustomLabel>
          <CustomInput
            {...register('name', {
              required: true,
              minLength: 1
            })}
          />
        </div>
        <ModalActions>
          <Button
            buttonStyle={ButtonStyle.SECONDARY}
            type={ButtonType.FORM}
            clickHandler={() => {
              reset(defaultValues)
              setModal(null)
            }}
            value={t('common.cancel')}
          />
          <Button
            type={ButtonType.FORM}
            clickHandler={handleSubmit(onSubmit)}
            value={t('common.saveChanges')}
            disabled={!isDirty || !isValid || isSubmitting}
          />
        </ModalActions>
      </>
    )
  }

  return (
    <AdminTabContent>
      <IntegrationDetailsHeader
        header={t('admin.integrations.azure.azureTenant')}
        editAction={() =>
          setModal({
            header: t('admin.integrations.azure.editAzureTenant'),
            body: <EditModalBody />
          })
        }
      />
      <IntegrationDetailRowsWrapper
        hasActions={false}
        detailRows={
          <>
            <IntegrationDetailRow
              label={t('admin.integrations.azure.tenantId')}
              value={selectedIntegration.azureTenantId}
            />
            <IntegrationDetailRow label={t('admin.integrations.name')} value={selectedIntegration.name} />
          </>
        }
      />
    </AdminTabContent>
  )
}

interface IntegrationTimestampsProps {
  selectedIntegration: AzureIntegrationsState
}

const IntegrationTimestamps = ({ selectedIntegration }: IntegrationTimestampsProps) => {
  const { t } = useTranslation()
  const errorSolutions = []
  !selectedIntegration.lastOrganizationIntegrationAt &&
    !selectedIntegration.deletedAt &&
    errorSolutions.push(
      <Trans
        i18nKey='admin.integrations.azure.missingOrganizationData'
        components={{
          CustomLink: <SpotterDocumentationLink path={'azure-access-management'} />
        }}
      />
    )

  return (
    <IntegrationTimestampsSection
      timestampComponent={
        <AdminTabContent>
          <IntegrationDetailsHeader header={t('admin.integrations.azure.azureTenant')} />
          <IntegrationDetailRowsWrapper
            hasActions={false}
            detailRows={
              <>
                <IntegrationDetailRow
                  label={t('admin.integrations.organization')}
                  value={formatDate(selectedIntegration.lastOrganizationIntegrationAt, true, true, true)}
                  error={!selectedIntegration.lastOrganizationIntegrationAt}
                />
                <IntegrationDetailRow
                  label={t('sideNav.costs')}
                  value={formatDate(selectedIntegration.billing.lastIntegrationAt, true, true, true)}
                  error={!selectedIntegration.billing.lastIntegrationAt && !selectedIntegration.billing.dismissed}
                />
                <IntegrationDetailRow
                  label={t('sideNav.optimization')}
                  value={formatDate(selectedIntegration.recommendation.lastIntegrationAt, true, true, true)}
                  error={
                    !selectedIntegration.recommendation.lastIntegrationAt &&
                    !selectedIntegration.recommendation.dismissed
                  }
                />
                <IntegrationDetailRow
                  label={t('sideNav.compliance')}
                  value={formatDate(selectedIntegration.compliance.lastIntegrationAt, true, true, true)}
                  error={!selectedIntegration.compliance.lastIntegrationAt && !selectedIntegration.compliance.dismissed}
                />
                <IntegrationDetailRow
                  label={t('sideNav.infra')}
                  value={formatDate(selectedIntegration.lastOrganizationIntegrationAt, true, true, true)}
                  error={!selectedIntegration.lastOrganizationIntegrationAt}
                />
                <IntegrationDetailRow
                  label={t('sideNav.sustainability')}
                  value={formatDate(selectedIntegration.usage.lastIntegrationAt, true, true, true)}
                  error={!selectedIntegration.usage.lastIntegrationAt && !selectedIntegration.usage.dismissed}
                />
              </>
            }
          />
        </AdminTabContent>
      }
      errorSolutions={errorSolutions}
    />
  )
}
