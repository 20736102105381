import {
  IntegrationAccountStatusRow,
  IntegrationDetailRow,
  IntegrationDetailRowsWrapper,
  IntegrationDetailsHeader
} from '../Shared'
import React, { useContext, useState } from 'react'
import { AwsAccountType } from './AwsIntegrationDetails'
import { AwsMasterAccount } from '../../../../api/admin/integrations/aws'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { CustomInput, CustomLabel } from '../../../shared/filters/FormComponents'
import { AdminTabContent } from '../../adminStyles'
import { UserInfoContext } from '../../../../state/context/UserInfoContext'
import { ModalContext } from '../../../../state/context/ModalContext'
import { ModalActions } from '../../../shared/modal/Modal'
import { Button, ButtonStyle, ButtonType } from '../../../shared/buttons/Button'
import {
  AwsConfigAccountIntegration,
  AwsIntegrationsState,
  AwsSecurityAccountIntegration
} from '../../../../api/settings/profile'

interface AwsIntegrationAccountsProps {
  selectedIntegration: AwsIntegrationsState
  submitAction: (request: AwsMasterAccount) => void
  openAccountAddModal: (
    accountType: AwsAccountType,
    account?: AwsSecurityAccountIntegration | AwsConfigAccountIntegration
  ) => void
  openAccountDeleteModal: (
    accountType: AwsAccountType,
    account: AwsSecurityAccountIntegration | AwsConfigAccountIntegration
  ) => void
}

export const AwsIntegrationAccounts = ({
  selectedIntegration,
  submitAction,
  openAccountAddModal,
  openAccountDeleteModal
}: AwsIntegrationAccountsProps) => {
  return (
    <>
      <MasterAccountSection selectedIntegration={selectedIntegration} submitAction={submitAction} />
      <SecurityAccountSection
        selectedIntegration={selectedIntegration}
        openAccountAddModal={openAccountAddModal}
        openAccountDeleteModal={openAccountDeleteModal}
      />
      <ConfigAccountSection
        selectedIntegration={selectedIntegration}
        openAccountAddModal={openAccountAddModal}
        openAccountDeleteModal={openAccountDeleteModal}
      />
    </>
  )
}

interface EditMasterAccountProps {
  selectedIntegration: AwsIntegrationsState
  submitAction: (request: AwsMasterAccount) => void
}

const MasterAccountSection = ({ selectedIntegration, submitAction }: EditMasterAccountProps) => {
  const { t } = useTranslation()
  const { authInfo } = useContext(UserInfoContext)
  const { setModal } = useContext(ModalContext)
  const [defaultValues, setDefaultValues] = useState<AwsMasterAccount>({
    accountId: selectedIntegration.masterAccountId,
    name: selectedIntegration.masterAccountName,
    role: selectedIntegration.role
  } as AwsMasterAccount)

  const EditModalBody = () => {
    const {
      handleSubmit,
      register,
      reset,
      formState: { isDirty, isValid, isSubmitting }
    } = useForm<AwsMasterAccount>({
      defaultValues: defaultValues
    })

    const onSubmit = (data: AwsMasterAccount) => {
      submitAction(data)
      setDefaultValues(data)
      reset(data)
    }

    return (
      <>
        <form className={'flex flex-col gap-5 pb-3'}>
          <div>
            <CustomLabel>{t('admin.integrations.name')} *</CustomLabel>
            <CustomInput
              {...register('name', {
                required: true,
                minLength: 1
              })}
            />
          </div>
          <div>
            <CustomLabel>{t('admin.integrations.aws.role')} *</CustomLabel>
            <CustomInput
              {...register('role', {
                required: true,
                minLength: 1
              })}
            />
          </div>
        </form>
        <ModalActions>
          <Button
            buttonStyle={ButtonStyle.SECONDARY}
            type={ButtonType.FORM}
            clickHandler={() => {
              reset(defaultValues)
              setModal(null)
            }}
            value={t('common.cancel')}
          />
          <Button
            type={ButtonType.FORM}
            clickHandler={handleSubmit(onSubmit)}
            value={t('common.saveChanges')}
            disabled={!isDirty || !isValid || isSubmitting}
          />
        </ModalActions>
      </>
    )
  }

  return (
    <AdminTabContent>
      <IntegrationDetailsHeader
        header={t('admin.integrations.aws.account', { type: AwsAccountType.MASTER })}
        editAction={() =>
          setModal({
            header: t('admin.integrations.aws.editMasterAccount'),
            body: <EditModalBody />
          })
        }
      />
      <IntegrationDetailRowsWrapper
        hasActions={false}
        detailRows={
          <>
            <IntegrationDetailRow
              label={t('admin.integrations.accountId')}
              value={selectedIntegration.masterAccountId}
            />
            <IntegrationDetailRow label={t('admin.integrations.name')} value={selectedIntegration.masterAccountName} />
            <IntegrationDetailRow label={t('admin.integrations.aws.role')} value={selectedIntegration.role} />
            <IntegrationDetailRow label={t('admin.integrations.aws.externalId')} value={authInfo.tenant.id} />
          </>
        }
      />
    </AdminTabContent>
  )
}

interface AccountSectionProps {
  selectedIntegration: AwsIntegrationsState
  openAccountAddModal: (
    accountType: AwsAccountType,
    account?: AwsSecurityAccountIntegration | AwsConfigAccountIntegration
  ) => void
  openAccountDeleteModal: (
    accountType: AwsAccountType,
    account: AwsSecurityAccountIntegration | AwsConfigAccountIntegration
  ) => void
}

const SecurityAccountSection = ({
  selectedIntegration,
  openAccountAddModal,
  openAccountDeleteModal
}: AccountSectionProps) => {
  const { t } = useTranslation()
  return (
    <AdminTabContent>
      <IntegrationDetailsHeader
        header={t('admin.integrations.aws.account', { type: AwsAccountType.SECURITY })}
        addAction={() => openAccountAddModal(AwsAccountType.SECURITY)}
      />
      {selectedIntegration.compliance.map(account => (
        <IntegrationDetailRowsWrapper
          key={`${account.accountId}-${account.region.value}`}
          hasActions={true}
          isRemoved={!!account.deletedAt}
          statusAction={() => {
            account.deletedAt
              ? openAccountAddModal(AwsAccountType.SECURITY, account)
              : openAccountDeleteModal(AwsAccountType.SECURITY, account)
          }}
          detailRows={
            <>
              <IntegrationDetailRow label={t('admin.integrations.accountId')} value={account.accountId} />
              <IntegrationDetailRow label={t('common.region_one')} value={account.region} />
              <IntegrationAccountStatusRow accountDeleteDate={account.deletedAt} />
            </>
          }
        />
      ))}
    </AdminTabContent>
  )
}

const ConfigAccountSection = ({
  selectedIntegration,
  openAccountAddModal,
  openAccountDeleteModal
}: AccountSectionProps) => {
  const { t } = useTranslation()
  return (
    <AdminTabContent>
      <IntegrationDetailsHeader
        header={t('admin.integrations.aws.account', { type: AwsAccountType.CONFIG })}
        addAction={() => openAccountAddModal(AwsAccountType.CONFIG)}
      />
      {selectedIntegration.resources.map(account => (
        <IntegrationDetailRowsWrapper
          key={`${account.accountId}-${account.aggregatorName}-${account.region.value}`}
          hasActions={true}
          isRemoved={!!account.deletedAt}
          statusAction={() => {
            account.deletedAt
              ? openAccountAddModal(AwsAccountType.CONFIG, account)
              : openAccountDeleteModal(AwsAccountType.CONFIG, account)
          }}
          detailRows={
            <>
              <IntegrationDetailRow label={t('admin.integrations.accountId')} value={account.accountId} />
              <IntegrationDetailRow label={t('common.region_one')} value={account.region} />
              <IntegrationDetailRow label={t('admin.integrations.aws.aggregatorName')} value={account.aggregatorName} />
              <IntegrationAccountStatusRow accountDeleteDate={account.deletedAt} />
            </>
          }
        />
      ))}
    </AdminTabContent>
  )
}
