import { CancelToken } from 'axios'
import { client } from './client'
import { CloudProject } from '../utils/classes'
import { Vendor } from '../utils/vendors'
import { CurrencyId } from '../utils/Currency'
import { CostAnomalyLimitData } from './settings/notifications'

export const getCostAnomalyNotifications = async (cancelToken: CancelToken): Promise<CostAnomalyNotification[]> => {
  return await client
    .get<CostAnomalyNotification[]>('notifications/cost-anomalies', { cancelToken })
    .then(resp => getDateFormattedResponse(resp.data))
}

export const updateCostAnomalyNotificationsStatus = async (
  notifications: CostAnomalyNotification[],
  status: NotificationState,
  cancelToken: CancelToken
): Promise<CostAnomalyNotification[]> => {
  return await client
    .put<CostAnomalyNotification[]>(
      `notifications/cost-anomalies/mark-as-${status}`,
      { notifications: notifications },
      {
        cancelToken
      }
    )
    .then(resp => getDateFormattedResponse(resp.data))
}

export const moveCostAnomalyNotificationsToBin = async (
  notifications: CostAnomalyNotification[],
  cancelToken: CancelToken
): Promise<CostAnomalyNotification[]> => {
  return await client
    .put<CostAnomalyNotification[]>(
      `notifications/cost-anomalies/move-to-bin`,
      { notifications: notifications },
      {
        cancelToken
      }
    )
    .then(resp => getDateFormattedResponse(resp.data))
}

export const restoreCostAnomalyNotificationsToInbox = async (
  notifications: CostAnomalyNotification[],
  cancelToken: CancelToken
): Promise<CostAnomalyNotification[]> => {
  return await client
    .put<
      CostAnomalyNotification[]
    >(`notifications/cost-anomalies/restore-to-inbox`, { notifications: notifications }, { cancelToken })
    .then(resp => getDateFormattedResponse(resp.data))
}

export const deleteCostAnomalyNotificationsPermanently = async (
  notifications: CostAnomalyNotification[]
): Promise<CostAnomalyNotification[]> => {
  return await client({
    method: 'delete',
    url: 'notifications/cost-anomalies',
    data: {
      notifications
    }
  }).then(resp => getDateFormattedResponse(resp.data))
}

const getDateFormattedResponse = (resp: CostAnomalyNotification[]) =>
  resp.map(notification => ({
    ...notification,
    date: new Date(notification.date),
    deletedAt: notification.deletedAt ? new Date(notification.deletedAt) : null,
    disabledAt: notification.disabledAt ? new Date(notification.disabledAt) : null
  }))

export interface CostAnomalyNotification {
  vendor: Vendor
  notificationId: string
  project: CloudProject
  service: string | null
  monthlyBudget: number | null
  lastMonthDailyCost: number
  currentMonthDailyCost: number
  spendCurrentMonth: number
  estTotalSpendCurrentMonth: number
  currency: CurrencyId
  changePercent: number
  monthlyImpact: number
  date: Date
  anomalyState: CostAnomalyState
  notificationState: NotificationState
  anomalyLimits: CostAnomalyLimitData
  projectLimits: CostAnomalyLimitData
  serviceLimits: CostAnomalyLimitData | null
  emailSubscriptions: CostAnomalyEmailSubscriptionOptions | null
  deletedAt: Date | null
  disabledAt: Date | null
}

interface CostAnomalyEmailSubscriptionOptions {
  project: boolean
  service: boolean
}

export interface DisabledCostNotificationData {
  vendor: Vendor
  project: CloudProject
  disabledAt?: Date
}

export enum NotificationState {
  NEW = 'NEW',
  UNREAD = 'UNREAD',
  READ = 'READ'
}

export enum CostAnomalyState {
  EXCEEDING_BUDGET_ALERT_THRESHOLD = 'EXCEEDING_BUDGET_ALERT_THRESHOLD',
  EXCEEDING_BUDGET_MINOR = 'EXCEEDING_BUDGET_MINOR',
  EXCEEDING_BUDGET_MODERATE = 'EXCEEDING_BUDGET_MODERATE',
  EXCEEDING_BUDGET_MAJOR = 'EXCEEDING_BUDGET_MAJOR',
  EXCEEDING_BUDGET_CRITICAL = 'EXCEEDING_BUDGET_CRITICAL',
  INCREASING = 'INCREASING',
  INCREASING_MORE = 'INCREASING_MORE',
  RECOVERING_FROM_INCREASE = 'RECOVERING_FROM_INCREASE',
  DECREASING = 'DECREASING',
  DECREASING_MORE = 'DECREASING_MORE',
  RECOVERED = 'RECOVERED'
}

export enum ChangeType {
  INCREASING = 'INCREASING',
  DECREASING = 'DECREASING'
}

export const BUDGET_COST_ANOMALY_STATES = [
  CostAnomalyState.EXCEEDING_BUDGET_ALERT_THRESHOLD,
  CostAnomalyState.EXCEEDING_BUDGET_MINOR,
  CostAnomalyState.EXCEEDING_BUDGET_MODERATE,
  CostAnomalyState.EXCEEDING_BUDGET_MAJOR,
  CostAnomalyState.EXCEEDING_BUDGET_CRITICAL
]

export const BUDGET_EXCEEDED_COST_ANOMALY_STATES = [
  CostAnomalyState.EXCEEDING_BUDGET_MINOR,
  CostAnomalyState.EXCEEDING_BUDGET_MODERATE,
  CostAnomalyState.EXCEEDING_BUDGET_MAJOR,
  CostAnomalyState.EXCEEDING_BUDGET_CRITICAL
]

export const INCREASING_COST_ANOMALY_STATES = [
  CostAnomalyState.INCREASING,
  CostAnomalyState.INCREASING_MORE,
  CostAnomalyState.RECOVERING_FROM_INCREASE
]

export const DECREASING_COST_ANOMALY_STATES = [CostAnomalyState.DECREASING, CostAnomalyState.DECREASING_MORE]

export const WORST_COST_ANOMALY_STATES = [
  CostAnomalyState.INCREASING_MORE,
  CostAnomalyState.EXCEEDING_BUDGET_MODERATE,
  CostAnomalyState.EXCEEDING_BUDGET_MAJOR,
  CostAnomalyState.EXCEEDING_BUDGET_CRITICAL
]

export const BAD_COST_ANOMALY_STATES = [CostAnomalyState.INCREASING, CostAnomalyState.EXCEEDING_BUDGET_MINOR]
