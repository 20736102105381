import React from 'react'
import { useTranslation } from 'react-i18next'
import { TicketStatus, TicketSummary } from '../../api/tickets'
import { InfoTooltip } from '../shared/tooltips/InfoTooltip'
import { useMousePosition } from '../../hooks/useMousePosition'
import { DataContainer } from '../shared/containers/DataContainer'
import { GradientText, GrayText, Heading2 } from '../shared/TextComponents'
import { CountUp } from '../shared/CountUp'
import { roundNumber } from '../../utils/formats'
import { ticketStatusColor } from './ticket/TicketsStatusContainer'

interface TicketsHeaderProps {
  ticketCount: TicketSummary
}

export const TicketsHeader = ({ ticketCount }: TicketsHeaderProps) => {
  const { t } = useTranslation()
  const mousePos = useMousePosition()

  return (
    <DataContainer looseSpacing={true}>
      <div className={'flex flex-col w-full gap-4'}>
        <div className={'flex gap-2 items-center justify-center lg:justify-start'}>
          <Heading2 className={'max-w-max'}>{t('tickets.header.title')}</Heading2>
          <InfoTooltip useIcon={true} styles={'max-w-80'} x={mousePos.x} y={mousePos.y}>
            {t('tickets.header.info')}
          </InfoTooltip>
        </div>
        <div className={'flex flex-row items-center justify-center lg:justify-start gap-10'}>
          <HeaderCounter value={ticketCount.open} status={TicketStatus.OPEN} />
          <HeaderCounter value={ticketCount.pending} status={TicketStatus.PENDING} />
          <HeaderCounter value={ticketCount.resolved} status={TicketStatus.RESOLVED} />
          <HeaderCounter value={ticketCount.closed} status={TicketStatus.CLOSED} />
        </div>
      </div>
    </DataContainer>
  )
}

interface HeaderCounterProps {
  value: number
  status: TicketStatus
  id?: string
}

export const HeaderCounter = ({ value, status, id }: HeaderCounterProps) => {
  return (
    <div className={'flex flex-col min-w-20 items-center justify-center'}>
      <GradientText className={`font-black text-200 ${ticketStatusColor[status].gradient}`}>
        <CountUp id={id} countTo={roundNumber(value, 0)} />
      </GradientText>
      <GrayText className={'text-90'}>{status}</GrayText>
    </div>
  )
}
