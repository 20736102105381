import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCancelToken } from '../../../../api/client'
import { useErrorHandling } from '../../../../hooks/handleError'
import { createGcpIntegration, GcpIntegrationRequest } from '../../../../api/admin/integrations/gcp'
import { ScrollTableRowItem } from '../../../shared/containers/ScrollTable'
import { formatDate } from '../../../../utils/formats'
import { Button, ButtonSize, ButtonStyle, ButtonType } from '../../../shared/buttons/Button'
import { ModalContext } from '../../../../state/context/ModalContext'
import { ModalActions } from '../../../shared/modal/Modal'
import { FieldErrorsImpl, useForm, UseFormRegister } from 'react-hook-form'
import {
  IntegrationModalHighlight,
  IntegrationsModalBody,
  IntegrationsModalInputs,
  IntegrationsModalTitle
} from '../Shared'
import { CustomInput, CustomLabel } from '../../../shared/filters/FormComponents'
import { MessageContext, MessageType } from '../../../../state/context/MessageContext'
import { IntegrationsLayout } from '../IntegrationsLayout'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { CapitalizedText, ErrorText } from '../../../shared/TextComponents'
import { firstLogin } from '../../../../state/storage'
import { StepMeter } from '../../../shared/StepMeter'
import { UserInfoContext } from '../../../../state/context/UserInfoContext'
import { Alert } from '../../../shared/indicators/Alert'
import { GcpIntegrationsState, getIntegrationStates } from '../../../../api/settings/profile'

export const GcpIntegrations = () => {
  const { createCancelToken } = useCancelToken()
  const { t } = useTranslation()
  const { setMessage } = useContext(MessageContext)
  const { setModal } = useContext(ModalContext)
  const { integrationStates, setIntegrationStates } = useContext(UserInfoContext)
  const navigate = useNavigate()
  const handleError = useErrorHandling()
  const [searchParams] = useSearchParams()
  const isFirst = Boolean(searchParams.get('first'))
  const [loading, setLoading] = useState<boolean>(true)
  const [step, setStep] = useState(isFirst ? 1 : 0)
  const [stepTitle, setStepTitle] = useState<string>(t('admin.integrations.gcp.organizationDetails'))

  useEffect(() => {
    setStepTitle(
      step === 1 ? t('admin.integrations.gcp.organizationDetails') : t('admin.integrations.gcp.accountDetails')
    )

    if (isFirst || step >= 1) {
      openAddModal()
    }

    return () => setLoading(false)
  }, [step, stepTitle, createCancelToken, handleError])

  const openAddModal = () => {
    const createAction = (request: GcpIntegrationRequest) => {
      const cancelToken = createCancelToken()
      createGcpIntegration(request, cancelToken.token)
        .then(resp => {
          getIntegrationStates(cancelToken.token).then(setIntegrationStates).catch(handleError)
          setModal(null)
          setMessage({
            type: MessageType.SUCCESS,
            message: t('admin.integrations.gcp.addSuccessToast')
          })
          !firstLogin() && navigate(resp.organizationId)
          isFirst && searchParams.delete('first')
        })
        .catch(handleError)
    }

    setModal({
      header: <StepMeter currentStep={step} totalSteps={2} description={t('admin.integrations.gcp.createTitle')} />,
      returnAction: isFirst ? () => navigate(-1) : undefined,
      body: <GcpIntegrationsAddModal submitAction={createAction} step={step} setStep={setStep} title={stepTitle} />
    })
  }

  return (
    <IntegrationsLayout
      noData={!integrationStates?.gcp?.length}
      loading={loading}
      heading={t('admin.integrations.gcp.title')}
      headerActions={
        <Button
          clickHandler={() => {
            setStep(1)
            openAddModal()
          }}
          value={t('admin.integrations.addNew')}
        />
      }
      scrollTableProps={{
        smallScreenTitle: t('admin.integrations.integrations'),
        sortable: false,
        customColumns: 'repeat(4, 1fr) minmax(90px, auto)',
        titles: [
          t('admin.integrations.name'),
          t('admin.integrations.gcp.organizationId'),
          t('admin.integrations.lastIntegrationAt'),
          t('admin.integrations.status')
        ],
        rows:
          integrationStates?.gcp?.map(organization => (
            <>
              <ScrollTableRowItem>{organization.organizationName}</ScrollTableRowItem>
              <ScrollTableRowItem>{organization.organizationId}</ScrollTableRowItem>
              <ScrollTableRowItem>
                {formatDate(organization.lastOrganizationIntegrationAt, true, true, true)}
              </ScrollTableRowItem>
              <ScrollTableRowItem>
                {organization.deletedAt ? (
                  <ErrorText>{t('common.status.removed')}</ErrorText>
                ) : (
                  <CapitalizedText className={'text-gray-100/90'}>{t('common.status.active')}</CapitalizedText>
                )}
                {hasGcpIntegrationErrors(integrationStates?.gcp || []) && <Alert />}
              </ScrollTableRowItem>
              <Button
                value={t('admin.integrations.viewDetails')}
                clickHandler={() => navigate(organization.organizationId)}
                buttonStyle={ButtonStyle.SECONDARY}
                size={ButtonSize.XSMALL}
              />
            </>
          )) || []
      }}
    />
  )
}

type AddModalProps = {
  submitAction: (request: GcpIntegrationRequest) => void
  selectedIntegration?: GcpIntegrationsState
} & (
  | {
      accountOnly: true
      step?: never
      setStep?: never
      title?: never
    }
  | {
      accountOnly?: false
      step: number
      setStep: (step: number) => void
      title: string
    }
)

export const GcpIntegrationsAddModal = ({
  submitAction,
  selectedIntegration,
  step,
  setStep,
  title,
  accountOnly
}: AddModalProps) => {
  const { t } = useTranslation()
  const { integrationStates } = useContext(UserInfoContext)
  const [organizationId, setOrganizationId] = useState<string>()
  const [accountId, setAccountId] = useState<string>()
  const [projectId, setProjectId] = useState<string>()
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { isValid, isSubmitting, errors }
  } = useForm<GcpIntegrationRequest>({
    defaultValues: {
      organizationId: selectedIntegration?.organizationId,
      name: selectedIntegration?.organizationName,
      accountId: '',
      projectId: '',
      tableId: ''
    }
  })

  useEffect(() => {
    integrationStates.gcp?.some(i => i.organizationId === organizationId)
      ? setError('organizationId', { type: 'unique', message: t('admin.integrations.gcp.organizationExistsError') })
      : clearErrors('organizationId')

    if (
      integrationStates.gcp?.some(
        i =>
          (i.organizationId === selectedIntegration?.organizationId || i.organizationId === organizationId) &&
          i.billingAccounts.some(a => a.accountId === accountId && a.projectId === projectId)
      )
    ) {
      setError('accountId', { type: 'unique', message: t('admin.integrations.gcp.accountExistsError') })
    } else {
      clearErrors('accountId')
    }
  }, [accountId, projectId, organizationId, integrationStates, setError, t, clearErrors, selectedIntegration])

  const onSubmit = (data: GcpIntegrationRequest) => {
    submitAction(data)
  }

  return (
    <form key={step} className={'max-w-116'}>
      <IntegrationsModalBody>
        {!accountOnly && <IntegrationsModalTitle>{title}</IntegrationsModalTitle>}
        <IntegrationModalHighlight type={'create'} path={'gcp-get-started-guide'} />
        {step === 1 ? (
          <OrganizationModal onChange={setOrganizationId} register={register} errors={errors} />
        ) : (
          <BillingAccountModal
            register={register}
            errors={errors}
            setAccountId={setAccountId}
            setProjectId={setProjectId}
          />
        )}
      </IntegrationsModalBody>
      <ModalActions>
        {step === 1 ? (
          <Button
            disabled={!isValid || isSubmitting || Object.keys(errors).length > 0}
            value={t('common.next')}
            size={ButtonSize.SMALL}
            type={ButtonType.FORM}
            buttonStyle={ButtonStyle.GHOST}
            clickHandler={event => {
              setStep(step + 1)
              event.preventDefault()
            }}
          />
        ) : (
          <>
            {setStep && (
              <Button
                disabled={isSubmitting}
                value={t('common.prev')}
                size={ButtonSize.SMALL}
                type={ButtonType.FORM}
                buttonStyle={ButtonStyle.GHOST}
                clickHandler={event => {
                  event.preventDefault()
                  setStep(step - 1)
                }}
              />
            )}
            <Button
              disabled={!isValid || isSubmitting || Object.keys(errors).length > 0}
              value={t('admin.integrations.addNew')}
              size={ButtonSize.SMALL}
              type={ButtonType.FORM}
              clickHandler={handleSubmit(onSubmit)}
            />
          </>
        )}
      </ModalActions>
    </form>
  )
}

interface OrganizationModalProps {
  register: UseFormRegister<GcpIntegrationRequest>
  onChange: (value: string) => void
  errors: FieldErrorsImpl<GcpIntegrationRequest>
}

const OrganizationModal = ({ register, onChange, errors }: OrganizationModalProps) => {
  const { t } = useTranslation()

  return (
    <IntegrationsModalInputs>
      <div>
        <CustomLabel>{t('admin.integrations.gcp.organizationId')} *</CustomLabel>
        <CustomInput
          type={'number'}
          {...register('organizationId', {
            required: true
          })}
          onChange={e => onChange(e.target.value)}
          autoFocus={true}
        />
      </div>
      <div>
        <CustomLabel>{t('admin.integrations.name')} *</CustomLabel>
        <CustomInput
          {...register('name', {
            required: true,
            minLength: 1
          })}
        />
      </div>
      {errors.organizationId?.message && <ErrorText className={'text-90'}>{errors.organizationId.message}</ErrorText>}
    </IntegrationsModalInputs>
  )
}

interface BillingAccountModalProps {
  register: UseFormRegister<GcpIntegrationRequest>
  errors: FieldErrorsImpl<GcpIntegrationRequest>
  setAccountId: (value: string) => void
  setProjectId: (value: string) => void
}

export const BillingAccountModal = ({ register, errors, setAccountId, setProjectId }: BillingAccountModalProps) => {
  const { t } = useTranslation()
  return (
    <IntegrationsModalInputs>
      <div>
        <CustomLabel>{t('admin.integrations.gcp.billingAccountId')} *</CustomLabel>
        <CustomInput
          {...register('accountId', {
            required: true,
            validate: value => {
              const billingAccountRegExp = new RegExp('^[0-9a-fA-F]{6}-[0-9a-fA-F]{6}-[0-9a-fA-F]{6}$')
              return billingAccountRegExp.test(value)
            }
          })}
          onChange={e => setAccountId(e.target.value)}
        />
      </div>
      <div>
        <CustomLabel>{t('admin.integrations.gcp.projectId')} *</CustomLabel>
        <CustomInput
          {...register('projectId', {
            required: true,
            minLength: 1
          })}
          onChange={e => setProjectId(e.target.value)}
        />
      </div>
      <div>
        <CustomLabel>{t('admin.integrations.gcp.bigQueryTableId')} *</CustomLabel>
        <CustomInput
          {...register('tableId', {
            required: true,
            minLength: 1
          })}
        />
      </div>
      {errors.accountId?.message && <ErrorText className={'text-90'}>{errors.accountId.message}</ErrorText>}
    </IntegrationsModalInputs>
  )
}

export const hasGcpIntegrationErrors = (integrationStates: GcpIntegrationsState[] | null) =>
  integrationStates?.some(
    i =>
      !i.deletedAt &&
      (!i.lastOrganizationIntegrationAt ||
        (!i.recommendation.lastIntegrationAt && !i.recommendation.dismissed) ||
        (!i.compliance.lastIntegrationAt && !i.compliance.dismissed) ||
        i.billingAccounts.some(
          a =>
            (!a.billing.lastIntegrationAt && !a.billing.dismissed) || (!a.usage.lastIntegrationAt && !a.usage.dismissed)
        ))
  ) || false
