import React, { useContext, useEffect, useRef, useState } from 'react'
import { useCancelToken } from '../../../api/client'
import { useErrorHandling } from '../../../hooks/handleError'
import {
  CostSummaryTabResponse,
  CostVendor,
  downloadCostsCsv,
  getCostBreakdown,
  getCostSummaryTabData
} from '../../../api/costs'
import { getVendor, Vendor } from '../../../utils/vendors'
import { VendorTabs } from '../../shared/tabs/VendorTabs'
import { TabContent } from '../../shared/tabs/TabSharedComponents'
import { CostsVendorTab } from './CostsVendorTab'
import { FilterOption } from '../../shared/filters/FilterSelect'
import { CostsSummaryTab } from './CostsSummaryTab'
import { Button, ButtonStyle, ButtonType } from '../../shared/buttons/Button'
import { ModalContext, ModalState } from '../../../state/context/ModalContext'
import { RadioButton } from '../../shared/buttons/RadioButton'
import { useTranslation } from 'react-i18next'
import { MessageContext, MessageType } from '../../../state/context/MessageContext'
import { ModalActions } from '../../shared/modal/Modal'
import { CurrencyId } from '../../../utils/Currency'
import { TimeframeOption } from '../../../utils/classes'
import { ToggleGroup, ToggleGroupOption } from '../../shared/buttons/ToggleGroup'
import { MeatballsMenu } from '../../shared/MeatballsMenu'
import { TabsContainer } from '../../shared/containers/DataContainer'
import { UserInfoContext } from '../../../state/context/UserInfoContext'
import { CheckboxOption } from '../../shared/buttons/CheckboxOption'
import { Heading4 } from '../../shared/TextComponents'

interface CostsTabsProps {
  selectedVendors: Vendor[]
  selectedProjects: string[]
  selectedResourceGroups: string[]
  selectedServices: string[]
  selectedTags: FilterOption[]
  selectedCurrency: CurrencyId
  selectedTimeframe: TimeframeOption
  setSelectedTimeframe: (timeframe: TimeframeOption) => void
}

export const CostsTabs = ({
  selectedVendors,
  selectedProjects,
  selectedResourceGroups,
  selectedServices,
  selectedTags,
  selectedCurrency,
  selectedTimeframe,
  setSelectedTimeframe
}: CostsTabsProps) => {
  const { createCancelToken } = useCancelToken()
  const { t } = useTranslation()
  const { setModal } = useContext<ModalState>(ModalContext)
  const handleError = useErrorHandling()
  const isInitialMount = useRef(true)
  const [summaryLoading, setSummaryLoading] = useState(true)
  const [vendorsLoading, setVendorsLoading] = useState(true)
  const [downloading, setDownloading] = useState(false)
  const [summaryData, setSummaryData] = useState<CostSummaryTabResponse | null>(null)
  const [vendorData, setVendorData] = useState<CostVendor[]>([])
  const [selectedTab, setSelectedTab] = useState<Vendor>(Vendor.ALL)
  const currentTimeframe = useRef(selectedTimeframe)

  useEffect(() => {
    const cancelToken = createCancelToken()
    if (isInitialMount.current || currentTimeframe.current !== selectedTimeframe) {
      isInitialMount.current = false
      currentTimeframe.current = selectedTimeframe
      setSummaryLoading(true)
      setVendorsLoading(true)
    }

    Promise.all([
      getCostSummaryTabData(
        selectedVendors,
        selectedProjects,
        selectedResourceGroups,
        selectedServices,
        selectedTags.map(tag => ({
          vendor: tag.vendor ?? '',
          tagKey: tag.label,
          tagValues: tag.nest?.map(value => value.value) ?? []
        })),
        selectedCurrency,
        selectedTimeframe,
        cancelToken.token
      ).then(resp => {
        setSummaryData(resp)
        setSummaryLoading(false)
      }),
      getCostBreakdown(
        selectedVendors,
        selectedProjects,
        selectedResourceGroups,
        selectedServices,
        selectedTags.map(tag => ({
          vendor: tag.vendor ?? '',
          tagKey: tag.label,
          tagValues: tag.nest?.map(value => value.value) ?? []
        })),
        selectedCurrency,
        selectedTimeframe,
        cancelToken.token
      ).then(resp => {
        setVendorData(resp)
        setVendorsLoading(false)
      })
    ])
      .catch(handleError)
      .finally(() => {
        setSummaryLoading(false)
        setVendorsLoading(false)
      })

    return () => {
      setVendorsLoading(false)
      setSummaryLoading(false)
      cancelToken.cancel()
    }
  }, [
    selectedTimeframe,
    selectedServices,
    selectedProjects,
    selectedResourceGroups,
    selectedVendors,
    selectedTags,
    selectedCurrency,
    createCancelToken,
    handleError
  ])

  const timeframeOptions: ToggleGroupOption[] = [
    {
      clickHandler: () => setSelectedTimeframe(TimeframeOption.FROM_LAST_YEAR),
      checked: selectedTimeframe === TimeframeOption.FROM_LAST_YEAR,
      value: t(`common.timeframeOptions.${TimeframeOption.FROM_LAST_YEAR}`)
    },
    {
      clickHandler: () => setSelectedTimeframe(TimeframeOption.LAST_MONTH),
      checked: selectedTimeframe === TimeframeOption.LAST_MONTH,
      value: t(`common.timeframeOptions.${TimeframeOption.LAST_MONTH}`)
    }
  ]

  const vendorProjectList = vendorData?.find(item => getVendor(item.vendor) === selectedTab)
  const isSummaryTab = selectedTab === Vendor.ALL

  const exportAction = () =>
    setModal({
      header: t('costs.export.modalHeader'),
      body: (
        <ExportCsvModal
          selectedVendors={selectedVendors}
          selectedProjects={selectedProjects}
          selectedResourceGroups={selectedResourceGroups}
          selectedServices={selectedServices}
          selectedTags={selectedTags}
          selectedCurrency={selectedCurrency}
          downloading={downloading}
          setDownloading={setDownloading}
        />
      )
    })

  const menuOptions = [
    {
      type: 'secondary',
      label: t('common.timeframeOptions.LAST_YEAR'),
      group: 'timeframe',
      checked: selectedTimeframe === TimeframeOption.FROM_LAST_YEAR,
      clickHandler: () => setSelectedTimeframe(TimeframeOption.FROM_LAST_YEAR)
    },
    {
      type: 'secondary',
      label: t('common.timeframeOptions.LAST_MONTH'),
      group: 'timeframe',
      checked: selectedTimeframe === TimeframeOption.LAST_MONTH,
      clickHandler: () => setSelectedTimeframe(TimeframeOption.LAST_MONTH)
    },
    {
      label: t('common.export'),
      clickHandler: exportAction
    }
  ]

  return (
    <TabsContainer>
      <VendorTabs
        id={'qa-costs'}
        showSummaryTab={true}
        selectedVendor={selectedTab}
        handleSelection={setSelectedTab}
        actionComponent={
          selectedTab === Vendor.ALL ? (
            <ToggleGroup minWidth={'12rem'} maxWidth={'13rem'} buttons={timeframeOptions} />
          ) : (
            <MeatballsMenu options={menuOptions} />
          )
        }
      />
      <TabContent verticalPadding={isSummaryTab}>
        {isSummaryTab ? (
          <CostsSummaryTab
            selectedVendors={selectedVendors}
            selectedTimeframe={selectedTimeframe}
            loading={summaryLoading}
            data={summaryData}
          />
        ) : (
          <CostsVendorTab
            selectedVendors={selectedVendors}
            projectList={vendorProjectList}
            selectedTab={selectedTab}
            selectedTimeframe={selectedTimeframe}
            loading={vendorsLoading}
          />
        )}
      </TabContent>
    </TabsContainer>
  )
}

interface ExportCsvModalProps {
  selectedVendors: Vendor[]
  selectedProjects: string[]
  selectedResourceGroups: string[]
  selectedServices: string[]
  selectedTags: FilterOption[]
  selectedCurrency: CurrencyId
  downloading: boolean
  setDownloading: (downloading: boolean) => void
}

const ExportCsvModal = ({
  selectedVendors,
  selectedProjects,
  selectedResourceGroups,
  selectedServices,
  selectedTags,
  selectedCurrency,
  downloading,
  setDownloading
}: ExportCsvModalProps) => {
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const { userSettings } = useContext(UserInfoContext)
  const { setMessage } = useContext(MessageContext)
  const { setModal } = useContext(ModalContext)
  const { t } = useTranslation()
  const [timeFrame, setTimeFrame] = useState<TimeframeOption>(TimeframeOption.LAST_MONTH)
  const [exportedVendors, setExportedVendors] = useState<Vendor[]>(selectedVendors)
  const [includeTags, setIncludeTags] = useState(false)

  const download = () => {
    if (timeFrame) {
      setDownloading(true)
      const cancelToken = createCancelToken()
      downloadCostsCsv(
        exportedVendors,
        selectedProjects,
        selectedResourceGroups,
        selectedServices,
        selectedTags.map(tag => ({
          vendor: tag.vendor ?? '',
          tagKey: tag.label,
          tagValues: tag.nest?.map(value => value.value) ?? []
        })),
        selectedCurrency,
        timeFrame,
        includeTags,
        cancelToken.token
      )
        .then(() => {
          setModal(null)
          setMessage({
            message: t('common.downloadSuccess'),
            type: MessageType.SUCCESS
          })
        })
        .catch(handleError)
        .finally(() => setDownloading(false))

      return () => {
        setDownloading(false)
        cancelToken.cancel()
      }
    }
  }

  return (
    <>
      <div className={'flex flex-col gap-6 items-center py-2'}>
        <div className={'flex gap-16 justify-evenly w-full'}>
          <div className={'flex flex-col gap-3'}>
            <Heading4 className={'text-left'}>{t('costs.export.chooseVendors')}</Heading4>
            <div className={'flex flex-col gap-4 px-2'}>
              {userSettings.visibleVendors.map(vendor => (
                <RadioButton
                  key={vendor}
                  type={'secondary'}
                  checked={exportedVendors.includes(vendor)}
                  onChange={() =>
                    exportedVendors.includes(vendor)
                      ? setExportedVendors(exportedVendors.filter(v => v !== vendor))
                      : setExportedVendors([...exportedVendors, vendor])
                  }
                  label={t(`vendors.${vendor}.short`)}
                />
              ))}
            </div>
          </div>
          <div className={'flex flex-col gap-3'}>
            <Heading4 className={'text-left'}>{t('costs.export.chooseTimeframe')}</Heading4>
            <div className={'flex flex-col gap-4 px-2'}>
              <RadioButton
                type={'secondary'}
                checked={timeFrame === TimeframeOption.LAST_MONTH}
                onChange={() => setTimeFrame(TimeframeOption.LAST_MONTH)}
                label={t('costs.export.timeFrame.lastMonth')}
              />
              <RadioButton
                type={'secondary'}
                checked={timeFrame === TimeframeOption.CURRENT_YEAR}
                onChange={() => setTimeFrame(TimeframeOption.CURRENT_YEAR)}
                label={t('costs.export.timeFrame.currentYear')}
              />
              <RadioButton
                type={'secondary'}
                checked={timeFrame === TimeframeOption.FROM_LAST_YEAR}
                onChange={() => setTimeFrame(TimeframeOption.FROM_LAST_YEAR)}
                label={t('costs.export.timeFrame.lastYear')}
              />
            </div>
          </div>
        </div>
      </div>
      <ModalActions>
        <Button
          type={ButtonType.FORM}
          buttonStyle={ButtonStyle.GHOST}
          value={
            <CheckboxOption
              label={t('costs.export.includeTags')}
              type={'secondary'}
              checked={includeTags}
              clickHandler={() => setIncludeTags(!includeTags)}
            />
          }
        />
        <Button
          value={t('costs.export.buttonText')}
          clickHandler={download}
          disabled={!timeFrame || downloading}
          type={ButtonType.FORM}
        />
      </ModalActions>
    </>
  )
}
