import DatePicker, { DatePickerProps, registerLocale } from 'react-datepicker'
import React, { useRef, useState } from 'react'
import { CustomIcon } from '../CustomIcon'
import calendarIcon from '../../../assets/svg/objects/calendar.svg'
import { formatMonthLong } from '../../../utils/formats'
import { Button, ButtonStyle, ButtonType } from '../buttons/Button'
import caretIcon from '../../../assets/svg/directional/caret-heavy.svg'
import { GrayText, WhiteText } from '../TextComponents'
import { ScrollContainer } from '../containers/ScrollContainer'
import { selectMenuListStyles } from './ReactSelectStyles'
import { enGB } from 'date-fns/locale'
import { useOnClickOutside } from '../../../hooks/useOnClickOutside'
import { useTranslation } from 'react-i18next'
import { MeatballsMenu, MeatballsMenuOption } from '../MeatballsMenu'

interface DateRangePickerProps {
  label?: string
  minDate: Date
  startDate: Date | null
  setStartDate: (date: Date | null) => void
  endDate: Date | null
  setEndDate: (date: Date | null) => void
  presets?: MeatballsMenuOption[]
}

export const DateRangePicker = ({
  label,
  minDate,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  presets
}: DateRangePickerProps) => {
  const { t } = useTranslation()
  registerLocale('en-GB', enGB)
  const ref = useRef(null)
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  useOnClickOutside(ref, () => setMenuIsOpen(false))

  return (
    <div ref={ref} onClick={() => setMenuIsOpen(!menuIsOpen)} className={'flex flex-col gap-0.5 w-full'}>
      <GrayText className={'text-90'}>{label || t('filters.dateRange')}</GrayText>
      <div className={`flex gap-1.5 items-center`}>
        <CustomDatePicker
          id={'start'}
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          minDate={minDate}
          onSelect={date => setStartDate(date)}
          onChange={date => setStartDate(date)}
        />
        <CustomIcon path={caretIcon} styles={'w-[18px] h-[18px] -rotate-90 bg-gray-200'} />
        <CustomDatePicker
          id={'end'}
          selected={endDate}
          minDate={minDate}
          startDate={startDate}
          endDate={endDate}
          onSelect={date => setEndDate(date)}
          onChange={date => setEndDate(date)}
        />
        {presets?.length ? <MeatballsMenu options={presets} /> : null}
      </div>
    </div>
  )
}

const CustomDatePicker = ({
  id,
  selected,
  onSelect,
  onChange,
  startDate,
  endDate,
  minDate,
  maxDate
}: DatePickerProps) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const getMonthYearOptions = (minDate: Date, maxDate: Date) => {
    const options = []
    const firstMonth = new Date(minDate.getFullYear(), minDate.getMonth(), 1)
    const lastMonth = new Date(maxDate.getFullYear(), maxDate.getMonth(), 1)

    while (firstMonth <= lastMonth) {
      options.push({
        value: new Date(firstMonth),
        label: firstMonth.toLocaleString('default', { month: 'long', year: 'numeric' })
      })
      firstMonth.setMonth(firstMonth.getMonth() + 1)
    }
    return options
  }

  return (
    <div
      className={
        'group flex items-center text-gray-100 justify-center h-fit w-max max-w-124 transition-all ease-in-out duration-200 cursor-pointer bg-gray-700/60 border rounded gap-2 py-1.5 px-2 border-gray-400/60 hover:shadow-input-focus'
      }
      onClick={() => document.getElementById(`${id}-date-picker`)?.focus()}
    >
      <CustomIcon
        path={calendarIcon}
        styles={'w-4 h-4 bg-gray-200 transition-all ease-in-out duration-200 group-hover:bg-gray-50'}
      />
      <DatePicker
        id={`${id}-date-picker`}
        locale={'en-GB'}
        showMonthYearDropdown={true}
        minDate={minDate || new Date()}
        maxDate={maxDate || new Date()}
        shouldCloseOnSelect={false}
        selected={selected || new Date()}
        onSelect={date => onSelect && onSelect(date)}
        onChange={date => onChange && onChange(date as Date & [Date | null, Date | null] & Date[])}
        onBlur={date => (!selected || !date) && onSelect && onSelect(new Date())}
        startDate={startDate}
        endDate={endDate}
        dateFormat={'dd MMM yyyy'}
        className={'text-90 w-24 bg-transparent focus:outline-none'}
        enableTabLoop={false}
        showWeekNumbers={true}
        renderCustomHeader={({ date, decreaseMonth, increaseMonth, changeMonth, changeYear }) => (
          <>
            <div className={'flex justify-between items-center border-b border-gray-500 border-dotted p-2 pt-0'}>
              <Button
                clickHandler={decreaseMonth}
                type={ButtonType.ICON}
                buttonStyle={ButtonStyle.GHOST}
                value={<CustomIcon styles={'w-5 h-5 bg-gray-50 rotate-90'} path={caretIcon} />}
              />
              <div className={'group flex items-center gap-1.5 '}>
                <WhiteText
                  className={'text-80 font-bold tracking-wide cursor-pointer'}
                  onClick={() => setMenuIsOpen(!menuIsOpen)}
                >
                  {formatMonthLong(date, true)}
                </WhiteText>
                <CustomIcon path={caretIcon} styles={`w-4 h-4 bg-gray-50 ${menuIsOpen ? 'rotate-180' : 'rotate-0'}`} />
              </div>
              <Button
                clickHandler={increaseMonth}
                type={ButtonType.ICON}
                buttonStyle={ButtonStyle.GHOST}
                value={<CustomIcon styles={'w-5 h-5 bg-gray-50 -rotate-90'} path={caretIcon} />}
              />
            </div>
            {menuIsOpen && minDate && (
              <ScrollContainer className={`absolute mx-auto max-h-60 w-full ${selectMenuListStyles}`}>
                {getMonthYearOptions(minDate, maxDate || new Date()).map(option => (
                  <div
                    key={option.value.toString()}
                    onClick={() => {
                      changeMonth(option.value.getMonth())
                      changeYear(option.value.getFullYear())
                      setMenuIsOpen(false)
                    }}
                    className={'hover:bg-gray-600 cursor-pointer py-3 px-4'}
                  >
                    {option.label}
                  </div>
                ))}
              </ScrollContainer>
            )}
          </>
        )}
      />
    </div>
  )
}

export const getMinDate = (dates: Date[]) => new Date(Math.min(...dates.map(d => d.getTime())))
export const getMaxDate = (dates: Date[]) => new Date(Math.max(...dates.map(d => d.getTime())))
