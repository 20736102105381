export interface CloudRegion {
  id: string
  name: string
}

export interface CloudProject {
  id: string
  name: string
}

export interface CloudResource {
  id: string
  type: string
  name: string | null | undefined
}

export enum TimeframeOption {
  THIRTY_DAYS = 'THIRTY_DAYS',
  LAST_MONTH = 'LAST_MONTH',
  SIX_MONTHS = 'SIX_MONTHS',
  TWELVE_MONTHS = 'TWELVE_MONTHS',
  CURRENT_YEAR = 'CURRENT_YEAR',
  FROM_LAST_YEAR = 'FROM_LAST_YEAR',
  LAST_YEAR = 'LAST_YEAR'
}
