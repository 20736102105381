import { VendorTabs } from '../../shared/tabs/VendorTabs'
import { Vendor, Vendors } from '../../../utils/vendors'
import { ExportButton } from '../../shared/buttons/ExportButton'
import { Button } from '../../shared/buttons/Button'
import { TabContent } from '../../shared/tabs/TabSharedComponents'
import { ComplianceVendorTab } from './ComplianceVendorTab'
import { ComplianceSummaryTab } from './ComplianceSummaryTab'
import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  AwsAzureComplianceSummaryTabData,
  AwsComplianceFindingsByControl,
  AzureComplianceFindingsByControl,
  downloadComplianceCsv,
  GcpComplianceFindingsByControl,
  GcpComplianceSummaryTabData,
  getComplianceAwsTabFindings,
  getComplianceAzureTabFindings,
  getComplianceGcpTabFindings,
  getComplianceSummaryTabData
} from '../../../api/compliance/tabs'
import { useTranslation } from 'react-i18next'
import { ModalContext, ModalState } from '../../../state/context/ModalContext'
import { useCancelToken } from '../../../api/client'
import { useErrorHandling } from '../../../hooks/handleError'
import { MessageContext, MessageType } from '../../../state/context/MessageContext'
import { ModalActions, ModalText } from '../../shared/modal/Modal'
import awsIcon from '../../../assets/svg/vendors/aws.svg'
import azureIcon from '../../../assets/svg/vendors/azure.svg'
import gcpIcon from '../../../assets/svg/vendors/gcp.svg'
import { CustomIcon, IconType } from '../../shared/CustomIcon'
import { CapitalizedText } from '../../shared/TextComponents'
import { CheckboxOption } from '../../shared/buttons/CheckboxOption'
import { TabsContainer } from '../../shared/containers/DataContainer'
import { UserInfoContext } from '../../../state/context/UserInfoContext'
import { ComplianceChartData, getComplianceSummaryChartResults } from '../../../api/compliance/common'
import { TimeframeOption } from '../../../utils/classes'

interface ComplianceTabsProps {
  selectedVendors: Vendor[]
  selectedProjects: string[]
  selectedResourceGroups: string[]
  selectedStandards: string[]
  selectedRegions: string[]
  chartTimeframe: TimeframeOption
  setChartTimeframe: (timeframe: TimeframeOption) => void
}

export const ComplianceTabs = ({
  selectedVendors,
  selectedProjects,
  selectedResourceGroups,
  selectedStandards,
  selectedRegions,
  chartTimeframe,
  setChartTimeframe
}: ComplianceTabsProps) => {
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const { t } = useTranslation()
  const { userSettings } = useContext(UserInfoContext)
  const { setModal } = useContext<ModalState>(ModalContext)
  const [selectedTab, setSelectedTab] = useState<Vendor>(Vendor.ALL)
  const [awsLoading, setAwsLoading] = useState(false)
  const [azureLoading, setAzureLoading] = useState(false)
  const [gcpLoading, setGcpLoading] = useState(false)
  const [awsAzureData, setAwsAzureData] = useState<AwsAzureComplianceSummaryTabData | null>(null)
  const [gcpData, setGcpData] = useState<GcpComplianceSummaryTabData | null>(null)
  const [chartData, setChartData] = useState<ComplianceChartData[]>([])
  const [summaryLoading, setSummarySummaryLoading] = useState(true)
  const [chartLoading, setChartLoading] = useState(true)
  const selectedTimeframe = useRef(chartTimeframe)
  const [awsFindings, setAwsFindings] = useState<AwsComplianceFindingsByControl[]>([])
  const [azureFindings, setAzureFindings] = useState<AzureComplianceFindingsByControl[]>([])
  const [gcpFindings, setGcpFindings] = useState<GcpComplianceFindingsByControl[]>([])
  const [downloading, setDownloading] = useState(false)

  useEffect(() => {
    const cancelToken = createCancelToken()
    if (selectedTimeframe.current !== chartTimeframe) {
      setChartLoading(true)
      selectedTimeframe.current = chartTimeframe
      getComplianceSummaryChartResults(
        selectedVendors,
        selectedProjects,
        selectedResourceGroups,
        selectedStandards,
        selectedRegions,
        chartTimeframe,
        cancelToken.token
      )
        .then(resp => {
          setChartData(resp)
          setChartLoading(false)
        })
        .catch(handleError)
    } else {
      setAwsLoading(true)
      setAzureLoading(true)
      setGcpLoading(true)
      setChartLoading(true)
      setSummarySummaryLoading(true)
      Promise.all([
        getComplianceSummaryTabData(
          selectedVendors,
          selectedProjects,
          selectedResourceGroups,
          selectedStandards,
          selectedRegions,
          cancelToken.token
        ).then(data => {
          setAwsAzureData(data.awsAzure)
          setGcpData(data.gcp)
          setSummarySummaryLoading(false)
        }),
        getComplianceSummaryChartResults(
          selectedVendors,
          selectedProjects,
          selectedResourceGroups,
          selectedStandards,
          selectedRegions,
          chartTimeframe,
          cancelToken.token
        ).then(resp => {
          setChartData(resp)
          setChartLoading(false)
        }),
        getComplianceAwsTabFindings(
          selectedVendors,
          selectedProjects,
          selectedResourceGroups,
          selectedStandards,
          selectedRegions,
          cancelToken.token
        ).then(data => {
          setAwsFindings(data)
          setAwsLoading(false)
        }),
        getComplianceAzureTabFindings(
          selectedVendors,
          selectedProjects,
          selectedResourceGroups,
          selectedStandards,
          selectedRegions,
          cancelToken.token
        ).then(data => {
          setAzureFindings(data)
          setAzureLoading(false)
        }),
        getComplianceGcpTabFindings(
          selectedVendors,
          selectedProjects,
          selectedResourceGroups,
          selectedStandards,
          selectedRegions,
          cancelToken.token
        ).then(data => {
          setGcpFindings(data)
          setGcpLoading(false)
        })
      ]).catch(handleError)
    }

    return () => {
      cancelToken.cancel()
      setAwsFindings([])
      setAzureFindings([])
      setGcpFindings([])
      setAwsLoading(false)
      setAzureLoading(false)
      setGcpLoading(false)
      setChartLoading(false)
      setSummarySummaryLoading(false)
    }
  }, [
    chartTimeframe,
    createCancelToken,
    handleError,
    selectedProjects,
    selectedResourceGroups,
    selectedStandards,
    selectedVendors,
    selectedRegions
  ])

  useEffect(() => {
    !userSettings.visibleVendors.find(v => !selectedVendors.some(sv => sv === v)) && setSelectedTab(Vendor.ALL)
  }, [selectedVendors, userSettings.visibleVendors])

  const exportAction = () =>
    setModal({
      header: t('compliance.export.modalHeader'),
      body: (
        <ExportCsvModal
          downLoadInFlight={downloading}
          setDownloadInFlight={setDownloading}
          selectedVendors={selectedVendors}
          selectedProjects={selectedProjects}
          selectedResourceGroups={selectedResourceGroups}
          selectedStandards={selectedStandards}
          selectedRegions={selectedRegions}
        />
      )
    })

  return (
    <TabsContainer>
      <VendorTabs
        id={'qa-compliance'}
        selectedVendor={selectedTab}
        handleSelection={setSelectedTab}
        showSummaryTab={true}
        actionComponent={
          selectedTab !== Vendor.ALL && <ExportButton clickHandler={exportAction} disabled={downloading} />
        }
      />
      <TabContent verticalPadding={selectedTab === Vendor.ALL}>
        {selectedTab !== Vendor.ALL ? (
          <ComplianceVendorTab
            selectedVendors={selectedVendors}
            findings={
              selectedTab === Vendor.AWS
                ? awsFindings
                : selectedTab === Vendor.AZURE
                  ? azureFindings
                  : selectedTab === Vendor.GCP
                    ? gcpFindings
                    : []
            }
            vendor={selectedTab}
            loading={
              selectedTab === Vendor.AWS
                ? awsLoading
                : selectedTab === Vendor.AZURE
                  ? azureLoading
                  : selectedTab === Vendor.GCP
                    ? gcpLoading
                    : false
            }
          />
        ) : (
          <ComplianceSummaryTab
            chartData={chartData}
            chartTimeframe={chartTimeframe}
            setChartTimeframe={setChartTimeframe}
            chartLoading={chartLoading}
            loading={summaryLoading}
            selectedVendors={selectedVendors}
            awsAzureData={awsAzureData}
            gcpData={gcpData}
          />
        )}
      </TabContent>
    </TabsContainer>
  )
}

interface ExportCsvModalProps {
  downLoadInFlight: boolean
  setDownloadInFlight: (downloadInFlight: boolean) => void
  selectedVendors: Vendor[]
  selectedProjects: string[]
  selectedResourceGroups: string[]
  selectedStandards: string[]
  selectedRegions: string[]
}

const ExportCsvModal = ({
  downLoadInFlight,
  setDownloadInFlight,
  selectedVendors,
  selectedProjects,
  selectedResourceGroups,
  selectedStandards,
  selectedRegions
}: ExportCsvModalProps) => {
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const { setMessage } = useContext(MessageContext)
  const { setModal } = useContext(ModalContext)
  const { t } = useTranslation()
  const [includedVendors, setIncludedVendors] = useState(selectedVendors)

  const download = () => {
    setDownloadInFlight(true)
    const cancelToken = createCancelToken()
    downloadComplianceCsv(
      includedVendors,
      selectedProjects,
      selectedResourceGroups,
      selectedStandards,
      selectedRegions,
      cancelToken.token
    )
      .then(() => {
        setMessage({
          message: t('common.downloadSuccess'),
          type: MessageType.SUCCESS
        })
        setModal(null)
        setDownloadInFlight(false)
      })
      .catch(handleError)

    return () => {
      setDownloadInFlight(false)
      cancelToken.cancel()
    }
  }

  return (
    <>
      <div className={'flex flex-col gap-6'}>
        <ModalText>{t('compliance.tabs.common.exportData')}</ModalText>
        <div className={'flex gap-5 w-full py-3'}>
          {Vendors.map(vendor => (
            <CheckboxOption
              type={'container'}
              key={vendor}
              checked={includedVendors.includes(vendor)}
              clickHandler={() =>
                setIncludedVendors(
                  includedVendors.includes(vendor)
                    ? includedVendors.filter(v => v !== vendor)
                    : [...includedVendors, vendor]
                )
              }
              label={
                <div className={'flex items-center w-full gap-4'}>
                  <CustomIcon
                    iconType={IconType.VECTOR}
                    path={vendor === Vendor.AWS ? awsIcon : vendor === Vendor.AZURE ? azureIcon : gcpIcon}
                    styles={'w-6 h-6 bg-gray-50'}
                  />
                  <CapitalizedText className={'text-gray-50'}>{t(`vendors.${vendor}.short`)}</CapitalizedText>
                </div>
              }
            />
          ))}
        </div>
      </div>
      <ModalActions>
        <Button value={t('costs.export.buttonText')} clickHandler={download} disabled={downLoadInFlight} />
      </ModalActions>
    </>
  )
}
