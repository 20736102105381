import { useCancelToken } from '../../../../api/client'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import React, { useContext, useEffect, useState } from 'react'
import { MessageContext, MessageType } from '../../../../state/context/MessageContext'
import { ModalContext } from '../../../../state/context/ModalContext'
import { useErrorHandling } from '../../../../hooks/handleError'
import {
  addAwsConfigAccount,
  addAwsSecurityAccount,
  AwsMasterAccount,
  editAwsMasterAccount,
  getAwsRegionOptions,
  recoverAwsConfigAccount,
  recoverAwsIntegration,
  recoverAwsSecurityAccount,
  softDeleteAwsConfigAccount,
  softDeleteAwsIntegration,
  softDeleteAwsSecurityAccount
} from '../../../../api/admin/integrations/aws'
import { AdminContentWrapper } from '../../adminStyles'
import {
  DetailedIntegrationModal,
  IntegrationDetailsInfoSection,
  IntegrationDetailsPageHeader,
  IntegrationDetailsWrapper,
  IntegrationModalHighlight,
  IntegrationsModalBody,
  IntegrationsModalInputs,
  IntegrationsTab,
  IntegrationsTabs
} from '../Shared'
import { CustomInput, CustomLabel } from '../../../shared/filters/FormComponents'
import { Controller, useForm } from 'react-hook-form'
import { FilterOption } from '../../../shared/filters/FilterSelect'
import { Button, ButtonStyle, ButtonType } from '../../../shared/buttons/Button'
import { ModalActions } from '../../../shared/modal/Modal'
import { AwsRegionMenu, hasAwsIntegrationErrors } from './AwsIntegrations'
import { AwsIntegrationAccounts } from './AwsIntegrationAccounts'
import { AwsIntegrationTimestamps } from './AwsIntegrationTimestamps'
import { UserInfoContext } from '../../../../state/context/UserInfoContext'
import {
  AwsConfigAccountIntegration,
  AwsIntegrationsState,
  AwsSecurityAccountIntegration,
  getIntegrationStates
} from '../../../../api/settings/profile'
import { Layout } from '../../../../layout/Layout'
import { MissingDataNotification } from '../../../shared/MissingDataNotification'
import { ErrorText } from '../../../shared/TextComponents'

export enum AwsAccountType {
  MASTER = 'master',
  SECURITY = 'security',
  CONFIG = 'config'
}

export const AwsIntegrationDetails = () => {
  const { createCancelToken } = useCancelToken()
  const { t } = useTranslation()
  const { integrationStates, setIntegrationStates } = useContext(UserInfoContext)
  const navigate = useNavigate()
  const { masterAccountId } = useParams<{
    masterAccountId: string
  }>()
  const { setMessage } = useContext(MessageContext)
  const { setModal } = useContext(ModalContext)
  const handleError = useErrorHandling()
  const [selectedIntegration, setSelectedIntegration] = useState<AwsIntegrationsState | null>(null)

  useEffect(() => {
    if (masterAccountId) {
      setSelectedIntegration(
        integrationStates.aws?.find(integration => integration.masterAccountId === masterAccountId) || null
      )
    }
  }, [masterAccountId, createCancelToken, handleError, integrationStates])

  if (!selectedIntegration || !masterAccountId) return <MissingDataNotification />

  const editIntegration = (request: AwsMasterAccount) => {
    editAwsMasterAccount(request)
      .then(resp => {
        setSelectedIntegration(resp)
        getIntegrationStates(createCancelToken().token).then(setIntegrationStates).catch(handleError)
        setModal(null)
        setMessage({
          type: MessageType.SUCCESS,
          message: t('admin.integrations.aws.editSuccessToast')
        })
      })
      .catch(handleError)
  }

  const openDeleteModal = () => {
    const cancelToken = createCancelToken()
    const deleteAction = () =>
      softDeleteAwsIntegration(selectedIntegration.masterAccountId, cancelToken.token)
        .then(() => {
          getIntegrationStates(cancelToken.token).then(setIntegrationStates).catch(handleError)
          setModal(null)
          setMessage({
            type: MessageType.SUCCESS,
            message: t('admin.integrations.aws.accountRemoveSuccessToast', { type: AwsAccountType.MASTER })
          })
          navigate('/admin/integrations/aws')
        })
        .catch(handleError)

    setModal({
      header: t('admin.integrations.confirmRemoveRequest'),
      body: (
        <DetailedIntegrationModal
          description={t('admin.integrations.integrationStopMessage', {
            target: t('admin.integrations.aws.account', { type: AwsAccountType.MASTER })
          })}
          detailRows={[
            { label: t('admin.integrations.accountId'), value: selectedIntegration.masterAccountId },
            { label: t('admin.integrations.name'), value: selectedIntegration.masterAccountName }
          ]}
          action={deleteAction}
          actionLabel={t('admin.integrations.remove')}
        />
      )
    })
  }

  const recoverIntegration = () => {
    const cancelToken = createCancelToken()
    recoverAwsIntegration(selectedIntegration.masterAccountId, cancelToken.token)
      .then(resp => {
        setSelectedIntegration(resp)
        getIntegrationStates(cancelToken.token).then(setIntegrationStates).catch(handleError)
        setModal(null)
        setMessage({
          type: MessageType.SUCCESS,
          message: t('admin.integrations.aws.accountRecoverSuccessToast', { type: AwsAccountType.MASTER })
        })
      })
      .catch(handleError)
  }

  const openAccountAddModal = (
    type: AwsAccountType,
    account?: AwsSecurityAccountIntegration | AwsConfigAccountIntegration
  ) => {
    const recoverAccount = (account: AwsSecurityAccountIntegration | AwsConfigAccountIntegration) => {
      type === 'config'
        ? recoverAwsConfigAccount(masterAccountId, account as AwsConfigAccountIntegration)
            .then(resp => {
              setSelectedIntegration(resp)
              getIntegrationStates(createCancelToken().token).then(setIntegrationStates).catch(handleError)
              setModal(null)
              setMessage({
                type: MessageType.SUCCESS,
                message: t('admin.integrations.aws.accountRecoverSuccessToast', { type: type })
              })
            })
            .catch(handleError)
        : recoverAwsSecurityAccount(masterAccountId, account as AwsSecurityAccountIntegration)
            .then(resp => {
              setSelectedIntegration(resp)
              getIntegrationStates(createCancelToken().token).then(setIntegrationStates).catch(handleError)
              setModal(null)
              setMessage({
                type: MessageType.SUCCESS,
                message: t('admin.integrations.aws.accountRecoverSuccessToast', { type: type })
              })
            })
            .catch(handleError)
    }

    const addAccount = (account: AwsSecurityAccountIntegration | AwsConfigAccountIntegration) => {
      if (type === 'config') {
        addAwsConfigAccount(masterAccountId, account as AwsConfigAccountIntegration)
          .then(resp => {
            setSelectedIntegration(resp)
            getIntegrationStates(createCancelToken().token).then(setIntegrationStates).catch(handleError)
            setModal(null)
            setMessage({
              type: MessageType.SUCCESS,
              message: t('admin.integrations.aws.accountAddSuccessToast', { type: type })
            })
          })
          .catch(handleError)
      } else
        addAwsSecurityAccount(masterAccountId, account as AwsSecurityAccountIntegration)
          .then(resp => {
            setSelectedIntegration(resp)
            getIntegrationStates(createCancelToken().token).then(setIntegrationStates).catch(handleError)
            setModal(null)
            setMessage({
              type: MessageType.SUCCESS,
              message: t('admin.integrations.aws.accountAddSuccessToast', { type: type })
            })
          })
          .catch(handleError)
    }

    if (account) {
      recoverAccount(account)
    } else
      setModal({
        header: t('admin.integrations.aws.addAccount', { type: type }),
        body: <AddAccountModal masterAccountId={masterAccountId} submitAction={addAccount} type={type} />
      })
  }

  const openAccountDeleteModal = (
    type: AwsAccountType,
    account: AwsSecurityAccountIntegration | AwsConfigAccountIntegration
  ) => {
    const deleteAction = () => {
      type === 'config'
        ? softDeleteAwsConfigAccount(masterAccountId, account as AwsConfigAccountIntegration)
            .then(resp => {
              setSelectedIntegration(resp)
              getIntegrationStates(createCancelToken().token).then(setIntegrationStates).catch(handleError)
              setModal(null)
              setMessage({
                type: MessageType.SUCCESS,
                message: t('admin.integrations.aws.accountRemoveSuccessToast', { type: type })
              })
            })
            .catch(handleError)
        : softDeleteAwsSecurityAccount(masterAccountId, account as AwsSecurityAccountIntegration)
            .then(resp => {
              setSelectedIntegration(resp)
              getIntegrationStates(createCancelToken().token).then(setIntegrationStates).catch(handleError)
              setModal(null)
              setMessage({
                type: MessageType.SUCCESS,
                message: t('admin.integrations.aws.accountRemoveSuccessToast', { type: type })
              })
            })
            .catch(handleError)
    }

    const detailRows = [
      { label: t('admin.integrations.accountId'), value: account.accountId },
      { label: t('common.region_one'), value: account.region.label }
    ]
    'aggregatorName' in account &&
      detailRows.push({ label: t('admin.integrations.aws.aggregatorName'), value: account.aggregatorName })

    setModal({
      header: t('admin.integrations.confirmRemoveRequest'),
      body: (
        <DetailedIntegrationModal
          description={t('admin.integrations.integrationStopMessage', {
            target: t('admin.integrations.aws.account', { type: type })
          })}
          detailRows={detailRows}
          action={deleteAction}
          actionLabel={t('admin.integrations.remove')}
        />
      )
    })
  }

  return (
    <Layout type={'sub'}>
      <IntegrationDetailsPageHeader
        name={selectedIntegration.masterAccountName}
        lastIntegrationAt={selectedIntegration.lastOrganizationIntegrationAt}
        buttonValue={selectedIntegration.deletedAt && t('admin.integrations.recover')}
        buttonAction={!!selectedIntegration?.deletedAt && recoverIntegration}
        deleteAction={!selectedIntegration?.deletedAt && openDeleteModal}
        backButtonPath={'/admin/integrations/aws'}
      />
      <AdminContentWrapper>
        <IntegrationDetails
          selectedIntegration={selectedIntegration}
          submitAction={editIntegration}
          openAccountAddModal={openAccountAddModal}
          openAccountDeleteModal={openAccountDeleteModal}
        />
      </AdminContentWrapper>
    </Layout>
  )
}

interface IntegrationDetailsProps {
  selectedIntegration: AwsIntegrationsState
  submitAction: (request: AwsMasterAccount) => void
  openAccountAddModal: (
    type: AwsAccountType,
    account?: AwsSecurityAccountIntegration | AwsConfigAccountIntegration
  ) => void
  openAccountDeleteModal: (
    type: AwsAccountType,
    account: AwsSecurityAccountIntegration | AwsConfigAccountIntegration
  ) => void
}

const IntegrationDetails = ({
  selectedIntegration,
  submitAction,
  openAccountAddModal,
  openAccountDeleteModal
}: IntegrationDetailsProps) => {
  const { integrationStates } = useContext(UserInfoContext)
  const [selectedTab, setSelectedTab] = useState<IntegrationsTab>(IntegrationsTab.DETAILS)
  return (
    <>
      <IntegrationsTabs
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        hasErrors={hasAwsIntegrationErrors(integrationStates.aws || [])}
      />
      <IntegrationDetailsWrapper>
        <IntegrationDetailsInfoSection removeDate={selectedIntegration.deletedAt} selectedTab={selectedTab} />

        {selectedTab === IntegrationsTab.DETAILS ? (
          <AwsIntegrationAccounts
            selectedIntegration={selectedIntegration}
            submitAction={submitAction}
            openAccountAddModal={openAccountAddModal}
            openAccountDeleteModal={openAccountDeleteModal}
          />
        ) : (
          <AwsIntegrationTimestamps selectedIntegration={selectedIntegration} />
        )}
      </IntegrationDetailsWrapper>
    </>
  )
}

interface AddAccountModalProps {
  type: AwsAccountType
  masterAccountId: string
  submitAction: (request: AwsSecurityAccountIntegration | AwsConfigAccountIntegration) => void
}

export const AddAccountModal = ({ masterAccountId, type, submitAction }: AddAccountModalProps) => {
  const { t } = useTranslation()
  const { setModal } = useContext(ModalContext)
  const { integrationStates } = useContext(UserInfoContext)
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const [regions, setRegions] = useState<FilterOption[]>([])
  const [accountId, setAccountId] = useState<string>('')
  const [selectedRegion, setSelectedRegion] = useState<FilterOption | null>(null)
  const [aggregatorName, setAggregatorName] = useState<string | undefined>(undefined)
  const {
    register,
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { isValid, isSubmitting, errors }
  } = useForm<AwsSecurityAccountIntegration | AwsConfigAccountIntegration>({
    defaultValues: {
      accountId: '',
      region: { label: '', value: '' },
      aggregatorName: type === 'config' ? '' : undefined
    }
  })

  useEffect(() => {
    getAwsRegionOptions(createCancelToken().token).then(setRegions).catch(handleError)
  }, [createCancelToken, handleError])

  useEffect(() => {
    if (type === AwsAccountType.CONFIG) {
      const configAccountExists = integrationStates.aws?.find(
        integration =>
          integration.masterAccountId === masterAccountId &&
          integration.resources.some(
            configAccount =>
              configAccount.accountId === accountId &&
              configAccount.region.value === selectedRegion?.value &&
              configAccount.aggregatorName === aggregatorName
          )
      )
      if (configAccountExists) {
        setError('accountId', { type: 'manual', message: t('admin.integrations.aws.accountExistsError') })
        setError('aggregatorName', { type: 'manual', message: t('admin.integrations.aws.accountExistsError') })
        setError('region', { type: 'manual', message: t('admin.integrations.aws.accountExistsError') })
      } else {
        clearErrors('accountId')
        clearErrors('aggregatorName')
        clearErrors('region')
      }
    } else {
      const securityAccountExists = integrationStates.aws?.find(
        integration =>
          integration.masterAccountId === masterAccountId &&
          integration.compliance.some(
            securityAccount =>
              securityAccount.accountId === accountId && securityAccount.region.value === selectedRegion?.value
          )
      )
      if (securityAccountExists) {
        setError('accountId', { type: 'manual', message: t('admin.integrations.aws.accountExistsError') })
        setError('region', { type: 'manual', message: t('admin.integrations.aws.accountExistsError') })
      } else {
        clearErrors('accountId')
        clearErrors('region')
      }
    }
  }, [
    accountId,
    aggregatorName,
    clearErrors,
    errors,
    integrationStates.aws,
    masterAccountId,
    selectedRegion,
    setError,
    t,
    type
  ])

  const onSubmit = (data: AwsSecurityAccountIntegration) => {
    submitAction(data)
  }

  return (
    <form>
      <IntegrationsModalBody>
        <IntegrationModalHighlight type={'create'} path={'aws-get-started-guide'} />
        <IntegrationsModalInputs>
          <div>
            <CustomLabel>{t('admin.integrations.accountId')} *</CustomLabel>
            <CustomInput
              type={'number'}
              {...register('accountId', {
                required: true
              })}
              onChange={e => setAccountId(e.target.value)}
              autoFocus={true}
            />
          </div>
          <div>
            <CustomLabel>{t('common.region_one')} *</CustomLabel>
            <Controller
              name={'region'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <AwsRegionMenu
                  regions={regions}
                  value={value}
                  onChange={onChange}
                  setSelectedRegion={setSelectedRegion}
                />
              )}
              rules={{ required: true }}
            />
          </div>
          {type === 'config' && (
            <div>
              <CustomLabel>{t('admin.integrations.aws.aggregatorName')} *</CustomLabel>
              <CustomInput
                {...register('aggregatorName', {
                  required: true,
                  minLength: 1
                })}
                onChange={e => setAggregatorName(e.target.value)}
              />
            </div>
          )}
          {Object.keys(errors).length > 0 && (
            <ErrorText className={'text-90'}>
              {t('admin.integrations.aws.accountExistsError', { type: type })}
            </ErrorText>
          )}
        </IntegrationsModalInputs>
      </IntegrationsModalBody>
      <ModalActions>
        <Button
          value={t('common.cancel')}
          clickHandler={() => setModal(null)}
          type={ButtonType.FORM}
          buttonStyle={ButtonStyle.SECONDARY}
        />
        <Button
          disabled={!isValid || isSubmitting || Object.keys(errors).length > 0}
          value={t('admin.integrations.addNew')}
          clickHandler={handleSubmit(onSubmit)}
          type={ButtonType.FORM}
        />
      </ModalActions>
    </form>
  )
}
