import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { TicketPriority } from '../../../api/tickets'

export enum SeverityLevel {
  UNSPECIFIED = 'UNSPECIFIED',
  INFORMATIONAL = 'INFORMATIONAL',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  CRITICAL = 'CRITICAL'
}

interface SeverityLevelIndicatorProps {
  level: SeverityLevel | TicketPriority
}

export const SeverityLevelIndicator = ({ level }: SeverityLevelIndicatorProps) => {
  const { t } = useTranslation()
  const color =
    level === SeverityLevel.CRITICAL || level === TicketPriority.URGENT
      ? 'bg-error-500/50 text-primary-500'
      : level === SeverityLevel.HIGH || level === TicketPriority.HIGH
        ? 'bg-primary-600/35 text-primary-500'
        : level === SeverityLevel.MEDIUM || level === TicketPriority.MEDIUM
          ? 'bg-warn-500/35 text-warn-500'
          : level === SeverityLevel.LOW || level === TicketPriority.LOW
            ? 'bg-tertiary-500/35 text-tertiary-500'
            : level === SeverityLevel.INFORMATIONAL
              ? 'bg-success-500/40 text-tertiary-200'
              : 'bg-gray-300/40 text-gray-200'

  return (
    <IndicatorWrapper key={level} className={`${color}`}>
      {level === SeverityLevel.UNSPECIFIED ? t('common.n_a') : level}
    </IndicatorWrapper>
  )
}

export const IndicatorWrapper = styled.div`
  ${tw`h-fit px-2 py-1 rounded-full w-max leading-none text-75 font-semibold tracking-wide uppercase`}
`
