import React, { useContext, useEffect, useState } from 'react'
import { TFunction, Trans, useTranslation } from 'react-i18next'
import {
  BAD_COST_ANOMALY_STATES,
  BUDGET_COST_ANOMALY_STATES,
  BUDGET_EXCEEDED_COST_ANOMALY_STATES,
  CostAnomalyNotification,
  CostAnomalyState,
  DECREASING_COST_ANOMALY_STATES,
  DisabledCostNotificationData,
  getCostAnomalyNotifications,
  NotificationState,
  restoreCostAnomalyNotificationsToInbox,
  updateCostAnomalyNotificationsStatus,
  WORST_COST_ANOMALY_STATES
} from '../../api/notifications'
import { NotificationsContentCol, ViewNotificationCostsButton } from './SharedComponents'
import { VendorIndicator, VendorIndicatorState } from '../shared/indicators/VendorIndicator'
import { GrayText, Heading4, WhiteText } from '../shared/TextComponents'
import { formatDate, formatMonthLong, formatNumber } from '../../utils/formats'
import { ModalContext } from '../../state/context/ModalContext'
import { NotificationsContext } from '../../state/context/NotificationsContext'
import { MessageContext, MessageType } from '../../state/context/MessageContext'
import { useErrorHandling } from '../../hooks/handleError'
import { useCancelToken } from '../../api/client'
import { MeatballsMenu, MeatballsMenuOption } from '../shared/MeatballsMenu'
import { CostNotificationModalBody, CostNotificationModalHeader } from './CostNotificationModal'
import {
  disableCostNotifications,
  enableCostNotifications,
  subscribeProjectEmailNotifications,
  subscribeServiceEmailNotifications,
  unsubscribeProjectEmailNotifications,
  unsubscribeServiceEmailNotifications
} from '../../api/settings/notifications'
import { CustomIcon, IconType } from '../shared/CustomIcon'
import envelope from '../../assets/svg/objects/envelope.svg'
import { getCurrency } from '../../utils/Currency'
import { NotificationsTab } from './Notifications'
import { MissingDataNotification } from '../shared/MissingDataNotification'
import styled from 'styled-components'
import tw from 'twin.macro'
import { primary500, success100 } from '../../design/constants'

interface NotificationsListProps {
  selectedTab: NotificationsTab.INBOX | NotificationsTab.DELETED
  tabNotifications: CostAnomalyNotification[]
  filteredNotifications: CostAnomalyNotification[]
  updateReadStatus: (notifications: CostAnomalyNotification[], state: NotificationState) => void
  moveToBin: (notifications: CostAnomalyNotification[]) => void
  restoreToInbox: (notifications: CostAnomalyNotification[]) => void
  deletePermanently: (notifications: CostAnomalyNotification[]) => void
}

export const CostNotifications = ({
  selectedTab,
  tabNotifications,
  filteredNotifications,
  updateReadStatus,
  moveToBin,
  restoreToInbox,
  deletePermanently
}: NotificationsListProps) => {
  const { t } = useTranslation()

  const wrapper = document.getElementById('cost-anomaly-notifications-list')
  const listContainerHeight = window.innerHeight - 32 - (wrapper?.offsetTop ?? 0)

  return (
    <NotificationsContentCol
      id={'cost-anomaly-notifications-list'}
      style={{ height: listContainerHeight }}
      className={'px-4 overflow-y-auto'}
    >
      {!tabNotifications.length || !filteredNotifications.length ? (
        <MissingDataNotification
          justify={'start'}
          displayText={
            !tabNotifications.length
              ? t(`notifications.${selectedTab}.noNotifications`)
              : t(`notifications.noMatchingNotifications`)
          }
        />
      ) : (
        filteredNotifications.map(notification => (
          <CostNotification
            key={notification.notificationId}
            selectedTab={selectedTab}
            notification={notification}
            updateReadStatus={updateReadStatus}
            moveToBin={moveToBin}
            restoreToInbox={restoreToInbox}
            deletePermanently={deletePermanently}
          />
        ))
      )}
    </NotificationsContentCol>
  )
}

interface NotificationProps {
  selectedTab: NotificationsTab.INBOX | NotificationsTab.DELETED
  notification: CostAnomalyNotification
  updateReadStatus: (notifications: CostAnomalyNotification[], status: NotificationState) => void
  moveToBin: (notifications: CostAnomalyNotification[]) => void
  restoreToInbox: (notifications: CostAnomalyNotification[]) => void
  deletePermanently: (notifications: CostAnomalyNotification[]) => void
}

const CostNotification = ({
  selectedTab,
  notification,
  updateReadStatus,
  moveToBin,
  restoreToInbox,
  deletePermanently
}: NotificationProps) => {
  const { t } = useTranslation()

  return (
    <NotificationWrapper>
      <VendorIndicator
        type={'icon'}
        vendor={notification.vendor}
        padding={1.5}
        onClick={() => {
          notification.notificationState === NotificationState.UNREAD
            ? updateReadStatus([notification], NotificationState.READ)
            : updateReadStatus([notification], NotificationState.UNREAD)
        }}
        state={
          notification.notificationState === NotificationState.READ
            ? VendorIndicatorState.INACTIVE
            : VendorIndicatorState.ACTIVE
        }
      />
      <NotificationHeader
        notification={notification}
        selectedTab={selectedTab}
        updateReadStatus={updateReadStatus}
        moveToBin={moveToBin}
        restoreToInbox={restoreToInbox}
        deletePermanently={deletePermanently}
      />
      <div className={'flex flex-col gap-4 row-start-2 col-start-2'}>
        <NotificationTexts notification={notification} />
        <div className={'flex justify-between items-center w-full gap-12'}>
          <ViewNotificationCostsButton notification={notification} updateReadState={updateReadStatus} />
          <GrayText className={'text-right text-80'}>
            {notification.disabledAt &&
              t('notifications.costAnomalies.costAnomaly.disabledTimestamp', {
                project: t(`vendors.${notification.vendor}.projectPhrase_one`),
                time: formatDate(new Date(notification.disabledAt))
              })}
          </GrayText>
        </div>
      </div>
    </NotificationWrapper>
  )
}

const NotificationWrapper = styled.div`
  ${tw`grid gap-x-4 gap-y-3 py-4 items-center`}
  grid-template-columns: auto 1fr;

  div {
    ${tw`transition-all ease-in-out duration-300`}
  }
`

interface NotificationHeaderProps {
  notification: CostAnomalyNotification
  selectedTab: NotificationsTab.INBOX | NotificationsTab.DELETED
  updateReadStatus: (notifications: CostAnomalyNotification[], status: NotificationState) => void
  moveToBin: (notifications: CostAnomalyNotification[]) => void
  restoreToInbox: (notifications: CostAnomalyNotification[]) => void
  deletePermanently: (notifications: CostAnomalyNotification[]) => void
}

const NotificationHeader = ({
  notification,
  selectedTab,
  updateReadStatus,
  moveToBin,
  restoreToInbox,
  deletePermanently
}: NotificationHeaderProps) => {
  const { t } = useTranslation()
  const { setModal } = useContext(ModalContext)
  const { setNotifications } = useContext(NotificationsContext)
  const { setMessage } = useContext(MessageContext)
  const handleError = useErrorHandling()
  const { createCancelToken } = useCancelToken()
  const [loading, setLoading] = useState(false)
  const emailsTooltip =
    notification.emailSubscriptions?.project && notification.emailSubscriptions.service
      ? t('notifications.costAnomalies.costAnomaly.bothEmailsSubscribed', {
          project: t(`vendors.${notification.vendor}.projectPhrase_one`)
        })
      : notification.emailSubscriptions?.project
        ? t('notifications.costAnomalies.costAnomaly.projectEmailsSubscribed', {
            project: t(`vendors.${notification.vendor}.projectPhrase_one`)
          })
        : notification.emailSubscriptions?.service
          ? t('notifications.costAnomalies.costAnomaly.serviceEmailsSubscribed')
          : undefined
  const [options, setOptions] = useState<MeatballsMenuOption[]>([])
  const [showNestedOptions, setShowNestedOptions] = useState(false)

  const restoreAs = (notifications: CostAnomalyNotification[], status: NotificationState) => {
    const cancelToken = createCancelToken()
    restoreCostAnomalyNotificationsToInbox(notifications, cancelToken.token)
      .then(() => {
        updateCostAnomalyNotificationsStatus(notifications, status, cancelToken.token)
          .then(resp => {
            setNotifications(resp)
            setMessage({
              type: MessageType.SUCCESS,
              message: t('notifications.costAnomalies.toastText.restoreSuccess')
            })
          })
          .catch(handleError)
      })
      .catch(handleError)
  }

  const changeNotificationLimits = (notification: CostAnomalyNotification) => {
    setModal({
      index: 1000,
      header: <CostNotificationModalHeader notification={notification} />,
      body: <CostNotificationModalBody notification={notification} />
    })
  }

  const disableNotifications = (notification: CostAnomalyNotification) => {
    const cancelToken = createCancelToken()
    const projectData: DisabledCostNotificationData = {
      vendor: notification.vendor,
      project: notification.project
    }
    disableCostNotifications(projectData, cancelToken.token)
      .then(() => {
        setLoading(true)
        getCostAnomalyNotifications(cancelToken.token)
          .then(setNotifications)
          .catch(handleError)
          .finally(() => {
            setLoading(false)
            setMessage({
              type: MessageType.SUCCESS,
              message: t('notifications.costAnomalies.toastText.notificationsDisableSuccess')
            })
          })
      })
      .catch(handleError)
  }

  const enableNotifications = (notification: CostAnomalyNotification) => {
    const cancelToken = createCancelToken()
    const data = {
      vendor: notification.vendor,
      project: notification.project
    }
    enableCostNotifications([data], cancelToken.token)
      .then(() => {
        setLoading(true)
        getCostAnomalyNotifications(cancelToken.token)
          .then(setNotifications)
          .catch(handleError)
          .finally(() => {
            setLoading(false)
            setMessage({
              type: MessageType.SUCCESS,
              message: t('notifications.costAnomalies.toastText.notificationsEnableSuccess')
            })
          })
      })
      .catch(handleError)
  }

  const enableServiceEmailNotifications = (notification: CostAnomalyNotification) => {
    const cancelToken = createCancelToken()
    notification.service &&
      subscribeServiceEmailNotifications(notification.vendor, notification.service, cancelToken.token)
        .then(() => {
          setLoading(true)
          getCostAnomalyNotifications(cancelToken.token)
            .then(setNotifications)
            .catch(handleError)
            .finally(() => {
              setLoading(false)
              setMessage({
                type: MessageType.SUCCESS,
                message: t('notifications.costAnomalies.toastText.emailsSubscribed')
              })
            })
        })
        .catch(handleError)
  }

  const disableServiceEmailNotifications = (notification: CostAnomalyNotification) => {
    const cancelToken = createCancelToken()
    notification.service &&
      unsubscribeServiceEmailNotifications(notification.vendor, notification.service, cancelToken.token)
        .then(() => {
          setLoading(true)
          getCostAnomalyNotifications(cancelToken.token)
            .then(setNotifications)
            .catch(handleError)
            .finally(() => {
              setLoading(false)
              setMessage({
                type: MessageType.SUCCESS,
                message: t('notifications.costAnomalies.toastText.emailsUnsubscribed')
              })
            })
        })
        .catch(handleError)
  }

  const enableProjectEmailNotifications = (notification: CostAnomalyNotification) => {
    const cancelToken = createCancelToken()
    subscribeProjectEmailNotifications(notification.vendor, notification.project.id, cancelToken.token)
      .then(() => {
        setLoading(true)
        getCostAnomalyNotifications(cancelToken.token)
          .then(setNotifications)
          .catch(handleError)
          .finally(() => {
            setLoading(false)
            setMessage({
              type: MessageType.SUCCESS,
              message: t('notifications.costAnomalies.toastText.emailsSubscribed')
            })
          })
      })
      .catch(handleError)
  }

  const disableProjectEmailNotifications = (notification: CostAnomalyNotification) => {
    const cancelToken = createCancelToken()
    unsubscribeProjectEmailNotifications(notification.vendor, notification.project.id, cancelToken.token)
      .then(() => {
        setLoading(true)
        getCostAnomalyNotifications(cancelToken.token)
          .then(setNotifications)
          .catch(handleError)
          .finally(() => {
            setLoading(false)
            setMessage({
              type: MessageType.SUCCESS,
              message: t('notifications.costAnomalies.toastText.emailsUnsubscribed')
            })
          })
      })
      .catch(handleError)
  }

  useEffect(() => {
    if (!showNestedOptions)
      setOptions(
        getNotificationMenuOptions(
          t,
          loading,
          notification,
          selectedTab,
          updateReadStatus,
          moveToBin,
          restoreToInbox,
          restoreAs,
          deletePermanently,
          disableNotifications,
          enableNotifications,
          changeNotificationLimits,
          setShowNestedOptions
        )
      )
    else {
      setOptions([
        {
          label: t('common.return'),
          clickHandler: () => setShowNestedOptions(false)
        },
        {
          label: t('notifications.costAnomalies.costAnomaly.menuOptions.projectEmailNotifications', {
            project: t(`vendors.${notification.vendor}.projectPhrase_one`)
          }),
          isCheckbox: true,
          checked: notification.emailSubscriptions?.project,
          isDisabled: loading,
          clickHandler: () =>
            notification.emailSubscriptions?.project
              ? disableProjectEmailNotifications(notification)
              : enableProjectEmailNotifications(notification)
        }
      ])
      notification.service &&
        options.push({
          label: t('notifications.costAnomalies.costAnomaly.menuOptions.serviceEmailNotifications'),
          isDisabled: loading,
          isCheckbox: true,
          checked: notification.emailSubscriptions?.service,
          clickHandler: () =>
            notification.emailSubscriptions?.service
              ? disableServiceEmailNotifications(notification)
              : enableServiceEmailNotifications(notification)
        })
    }
  }, [
    loading,
    deletePermanently,
    moveToBin,
    showNestedOptions,
    notification,
    restoreToInbox,
    selectedTab,
    t,
    updateReadStatus
  ])

  return (
    <div className={'flex justify-between items-center gap-6'}>
      <div>
        <Heading4>{t(`notifications.costAnomalies.costAnomaly.heading.${notification.anomalyState}`)}</Heading4>
        <div className={'flex items-center gap-2'}>
          <GrayText className={'text-80'}>{formatDate(new Date(notification.date), true, false)}</GrayText>
          {notification.emailSubscriptions && (
            <CustomIcon
              path={envelope}
              iconType={IconType.VECTOR}
              styles={'w-4 h-4 bg-gray-300'}
              tooltipText={emailsTooltip}
              tooltipStyles={'min-w-44 mt-3'}
            />
          )}
        </div>
      </div>
      <MeatballsMenu options={options} iconSize={'w-6 h-6'} />
    </div>
  )
}

interface NotificationTextsProps {
  notification: CostAnomalyNotification
}

const NotificationTexts = ({ notification }: NotificationTextsProps) => {
  const { t } = useTranslation()
  const prevMonth = new Date(notification.date)
  prevMonth.setMonth(prevMonth.getMonth() - 1)
  const currencySymbol = getCurrency(notification.currency).symbol
  const anomalyColor = WORST_COST_ANOMALY_STATES.includes(notification.anomalyState)
    ? 'text-primary-500'
    : BAD_COST_ANOMALY_STATES.includes(notification.anomalyState)
      ? 'text-primary-300'
      : [CostAnomalyState.RECOVERING_FROM_INCREASE, CostAnomalyState.EXCEEDING_BUDGET_ALERT_THRESHOLD].includes(
            notification.anomalyState
          )
        ? 'text-primary-200'
        : DECREASING_COST_ANOMALY_STATES.includes(notification.anomalyState)
          ? 'text-success-100'
          : 'text-gray-50'

  return (
    <div className={'flex flex-col gap-4'}>
      <GrayText className={'text-90'}>
        <Trans>
          {notification.service
            ? t('notifications.costAnomalies.costAnomaly.serviceAnomalyMessageIntro', {
                projectPhrase: t(`vendors.${notification.vendor}.projectPhrase_one`),
                projectName: notification.project.name,
                service: notification.service
              })
            : BUDGET_COST_ANOMALY_STATES.includes(notification.anomalyState)
              ? t('notifications.costAnomalies.costAnomaly.budgetAnomalyMessageIntro', {
                  projectPhrase: t(`vendors.${notification.vendor}.projectPhrase_one`),
                  projectName: notification.project.name
                })
              : null}
        </Trans>{' '}
        <span className={`inline-block font-semibold ${anomalyColor}`}>
          {t(`notifications.costAnomalies.costAnomaly.state.${notification.anomalyState}`)}
        </span>
      </GrayText>
      {BUDGET_COST_ANOMALY_STATES.includes(notification.anomalyState) && (
        <BudgetAnomalyContent notification={notification} currencySymbol={currencySymbol} prevMonth={prevMonth} />
      )}
      <CommonAnomalyContent notification={notification} currencySymbol={currencySymbol} prevMonth={prevMonth} />
    </div>
  )
}

interface AnomalyContentProps {
  notification: CostAnomalyNotification
  currencySymbol: string
  prevMonth: Date
}

const BudgetAnomalyContent = ({ notification, currencySymbol }: AnomalyContentProps) => {
  const { t } = useTranslation()
  if (!notification.monthlyBudget) return null

  const year = notification.date.getFullYear()
  const month = notification.date.getMonth()
  const daysLeft = new Date(year, month + 1, 0).getDate() - notification.date.getDate()
  const remainingBudget = notification.monthlyBudget - notification.spendCurrentMonth
  const estUpcomingCosts = notification.currentMonthDailyCost * daysLeft
  const budgetWillLast = remainingBudget > estUpcomingCosts
  const daysBudgetWillLast = Math.round(remainingBudget / notification.currentMonthDailyCost)
  const overrun = notification.spendCurrentMonth - notification.monthlyBudget

  return (
    <div className={'flex flex-col max-w-max'}>
      <AnomalySubHeading>{t('notifications.costAnomalies.costAnomaly.budgetStatus')}</AnomalySubHeading>
      <>
        <>
          <AnomalyCostRow
            label={t('notifications.costAnomalies.costAnomaly.monthlyBudget', {
              month: formatMonthLong(notification.date)
            })}
            value={`${formatNumber(notification.monthlyBudget, 2)} ${currencySymbol}`}
          />
          <AnomalyCostRow
            label={t('notifications.costAnomalies.costAnomaly.spendCurrentMonth', {
              month: formatMonthLong(notification.date)
            })}
            value={`${formatNumber(notification.spendCurrentMonth, 2)} ${currencySymbol}`}
          />
        </>
        {BUDGET_EXCEEDED_COST_ANOMALY_STATES.includes(notification.anomalyState) ? (
          <div className={'pt-1'}>
            <AnomalyCostRow
              label={t('notifications.costAnomalies.costAnomaly.overrun')}
              value={`${formatNumber(overrun, 2, true)} ${currencySymbol}`}
            />
            <GrayText className={'text-80 pt-0.5'}>
              <Trans
                i18nKey='notifications.costAnomalies.costAnomaly.overrunPercent'
                values={{ percent: formatNumber((overrun / notification.monthlyBudget) * 100, 0) }}
                components={{
                  ColorSpan: (
                    <strong style={{ color: primary500 }}>
                      {t('notifications.costAnomalies.costAnomaly.beExceeded')}
                    </strong>
                  )
                }}
              />
            </GrayText>
          </div>
        ) : (
          <div className={'pt-1'}>
            <AnomalyCostRow
              label={t('notifications.costAnomalies.costAnomaly.remaining')}
              value={`${formatNumber(remainingBudget)} ${currencySymbol}`}
            />
            <AnomalyCostRow
              label={t('notifications.costAnomalies.costAnomaly.spent')}
              value={`${formatNumber((notification.spendCurrentMonth / notification.monthlyBudget) * 100, 0)} %`}
            />
            <GrayText className={'text-80 pt-1'}>
              {budgetWillLast ? (
                <Trans
                  i18nKey='notifications.costAnomalies.costAnomaly.budgetLasts'
                  components={{
                    ColorSpan: (
                      <strong style={{ color: success100 }}>
                        {t('notifications.costAnomalies.costAnomaly.lastForMonth')}
                      </strong>
                    )
                  }}
                />
              ) : (
                <Trans
                  i18nKey='notifications.costAnomalies.costAnomaly.budgetMightExceed'
                  values={{ days: daysBudgetWillLast }}
                  components={{
                    ColorSpan: (
                      <strong style={{ color: primary500 }}>
                        {t('notifications.costAnomalies.costAnomaly.beExceeded')}
                      </strong>
                    )
                  }}
                />
              )}
            </GrayText>
          </div>
        )}
      </>
    </div>
  )
}

const CommonAnomalyContent = ({ notification, currencySymbol, prevMonth }: AnomalyContentProps) => {
  const { t } = useTranslation()
  const costChangeColor =
    notification.changePercent === 0
      ? 'text-gray-50'
      : notification.currentMonthDailyCost > notification.lastMonthDailyCost
        ? WORST_COST_ANOMALY_STATES.includes(notification.anomalyState)
          ? 'text-primary-500'
          : 'text-primary-300'
        : 'text-success-100'

  const changeTranslation =
    notification.changePercent !== 0
      ? notification.currentMonthDailyCost > notification.lastMonthDailyCost
        ? t('notifications.costAnomalies.increase')
        : t('notifications.costAnomalies.decrease')
      : t('common.change')

  return (
    <>
      <div className={'flex flex-col max-w-max'}>
        <AnomalySubHeading>{t('notifications.costAnomalies.costAnomaly.monthlyEstimates')}</AnomalySubHeading>
        <AnomalyCostRow
          label={t('notifications.costAnomalies.costAnomaly.estTotalCosts', {
            month: formatMonthLong(notification.date)
          })}
          value={`${formatNumber(notification.estTotalSpendCurrentMonth, 2)} ${currencySymbol}`}
        />
        <AnomalyCostRow
          label={t(`notifications.costAnomalies.costAnomaly.changeFrom`, {
            month: formatMonthLong(prevMonth)
          })}
          value={`${formatNumber(notification.monthlyImpact, 2, true)} ${currencySymbol}`}
        />
      </div>
      <div className={'flex flex-col max-w-max'}>
        <AnomalySubHeading>{t('notifications.costAnomalies.costAnomaly.dailyCosts')}</AnomalySubHeading>
        <AnomalyCostRow
          label={t('notifications.costAnomalies.costAnomaly.monthCost', {
            month: formatMonthLong(prevMonth)
          })}
          value={`${formatNumber(notification.lastMonthDailyCost, 2)} ${currencySymbol}`}
        />
        <AnomalyCostRow
          label={t('notifications.costAnomalies.costAnomaly.monthCost', {
            month: formatMonthLong(notification.date)
          })}
          value={`${formatNumber(notification.currentMonthDailyCost, 2)} ${currencySymbol}`}
        />
        <GrayText className={'pt-1 text-90'}>
          <Trans
            i18nKey='notifications.costAnomalies.costAnomaly.changeInDailyCosts'
            values={{
              percent: formatNumber(Math.abs(notification.changePercent)),
              change: changeTranslation
            }}
            components={{
              Percent: (
                <span className={`text-80 inline-block font-semibold ${costChangeColor}`}>
                  {t('notifications.costAnomalies.costAnomaly.changePercent', {
                    percent: formatNumber(Math.abs(notification.changePercent)),
                    change: changeTranslation
                  })}
                </span>
              )
            }}
          />
        </GrayText>
      </div>
    </>
  )
}

const AnomalySubHeading = styled(WhiteText)`
  ${tw`pb-1 text-90 font-semibold first-letter:capitalize`}
`

interface AnomalyCostRowProps {
  label: string
  value: string
}

const AnomalyCostRow = ({ label, value }: AnomalyCostRowProps) => {
  return (
    <div className={'flex justify-between gap-6 w-full text-90'}>
      <GrayText>{label}</GrayText>
      <WhiteText className={'font-semibold'}>{value}</WhiteText>
    </div>
  )
}

const getNotificationMenuOptions = (
  t: TFunction,
  loading: boolean,
  notification: CostAnomalyNotification,
  selectedTab: NotificationsTab,
  updateReadStatus: (notifications: CostAnomalyNotification[], status: NotificationState) => void,
  moveToBin: (notifications: CostAnomalyNotification[]) => void,
  restoreToInbox: (notifications: CostAnomalyNotification[]) => void,
  restoreAs: (notifications: CostAnomalyNotification[], status: NotificationState) => void,
  deletePermanently: (notifications: CostAnomalyNotification[]) => void,
  disableNotifications: (request: CostAnomalyNotification) => void,
  enableNotifications: (notification: CostAnomalyNotification) => void,
  changeNotificationLimits: (notification: CostAnomalyNotification) => void,
  setShowNestedOptions: (showNested: boolean) => void
): MeatballsMenuOption[] => {
  const options: MeatballsMenuOption[] = [
    {
      label:
        notification.notificationState === NotificationState.READ
          ? t('notifications.costAnomalies.costAnomaly.menuOptions.markAsUnread')
          : t('notifications.costAnomalies.costAnomaly.menuOptions.markAsRead'),
      isDisabled: loading,
      clickHandler: () =>
        updateReadStatus(
          [notification],
          notification.notificationState === NotificationState.READ ? NotificationState.UNREAD : NotificationState.READ
        )
    }
  ]

  selectedTab === NotificationsTab.INBOX
    ? options.push({
        label: t('notifications.costAnomalies.costAnomaly.menuOptions.delete'),
        isDisabled: loading,
        clickHandler: () => moveToBin([notification])
      })
    : options.push(
        {
          label: t('notifications.costAnomalies.costAnomaly.menuOptions.restore'),
          isDisabled: loading,
          clickHandler: () => restoreToInbox([notification])
        },
        {
          label:
            notification.notificationState === NotificationState.READ
              ? t('notifications.costAnomalies.costAnomaly.menuOptions.restoreAsUnread')
              : t('notifications.costAnomalies.costAnomaly.menuOptions.restoreAsRead'),
          isDisabled: loading,
          clickHandler: () => {
            restoreAs(
              [notification],
              notification.notificationState === NotificationState.READ
                ? NotificationState.UNREAD
                : NotificationState.READ
            )
          }
        },
        {
          label: t('notifications.costAnomalies.costAnomaly.menuOptions.deletePermanently'),
          isDisabled: loading,
          clickHandler: () => deletePermanently([notification])
        }
      )

  options.push(
    {
      label: t('notifications.costAnomalies.costAnomaly.menuOptions.changeLimits'),
      isDisabled: loading,
      clickHandler: () => changeNotificationLimits(notification)
    },
    {
      label: t('notifications.emailNotifications'),
      clickHandler: () => setShowNestedOptions(true),
      nestedOptions: true
    },
    {
      label: t('notifications.costAnomalies.costAnomaly.menuOptions.getNotifications', {
        projectPhrase: t(`vendors.${notification.vendor}.projectPhrase_one`)
      }),
      isCheckbox: true,
      checked: !notification.disabledAt,
      isDisabled: loading,
      clickHandler: () =>
        !notification.disabledAt ? disableNotifications(notification) : enableNotifications(notification)
    }
  )

  return options
}
