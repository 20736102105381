import React, { useContext, useState } from 'react'
import { InfraMap } from './InfraMap'
import { InfraFilters } from './InfraFilters'
import { FilterOption } from '../shared/filters/FilterSelect'
import { InfraTabs } from './tabs/InfraTabs'
import { Vendor } from '../../utils/vendors'
import { useTranslation } from 'react-i18next'
import { UserInfoContext } from '../../state/context/UserInfoContext'
import { Layout } from '../../layout/Layout'
import { getVendorFilterOptions } from '../shared/filters/Filters'

export const Infra = () => {
  const { t } = useTranslation()
  const { userSettings } = useContext(UserInfoContext)
  const [selectedVendors, setSelectedVendors] = useState<FilterOption[]>(getVendorFilterOptions(userSettings, t))
  const [selectedRegions, setSelectedRegions] = useState<FilterOption[]>([])
  const [selectedProjects, setSelectedProjects] = useState<FilterOption[]>([])
  const [selectedResourceGroups, setSelectedResourceGroups] = useState<FilterOption[]>([])
  const [selectedResources, setSelectedResources] = useState<FilterOption[]>([])

  return (
    <Layout>
      <InfraFilters
        selectedVendors={selectedVendors}
        selectedRegions={selectedRegions}
        selectedProjects={selectedProjects}
        selectedResourceGroups={selectedResourceGroups}
        selectedResources={selectedResources}
        setSelectedVendors={setSelectedVendors}
        setSelectedRegions={setSelectedRegions}
        setSelectedProjects={setSelectedProjects}
        setSelectedResourceGroups={setSelectedResourceGroups}
        setSelectedResources={setSelectedResources}
      />
      <InfraMap
        selectedVendors={selectedVendors.map(v => v.value as Vendor)}
        selectedRegions={selectedRegions.map(r => r.value)}
        selectedProjects={selectedProjects.map(p => p.value)}
        selectedResourceGroups={selectedResourceGroups.map(rg => rg.value)}
        selectedResources={selectedResources.map(r => r.value)}
      />
      <InfraTabs
        selectedVendors={selectedVendors.map(v => v.value as Vendor)}
        selectedRegions={selectedRegions.map(r => r.value)}
        selectedProjects={selectedProjects.map(p => p.value)}
        selectedResourceGroups={selectedResourceGroups.map(rg => rg.value)}
        selectedResources={selectedResources.map(r => r.value)}
      />
    </Layout>
  )
}
