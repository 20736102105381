import { SideDrawer } from '../layout/SideDrawer'
import React, { useContext, useEffect, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { ModalContext, ModalState } from '../../../state/context/ModalContext'
import { FilterOption, FilterSelect, filterSelectAllOption, GroupedOptions } from './FilterSelect'
import tw from 'twin.macro'
import styled from 'styled-components'
import { ModalActions, ModalText } from '../modal/Modal'
import { Button, ButtonSize, ButtonStyle, ButtonType } from '../buttons/Button'
import closeIcon from '../../../assets/svg/actions/close-heavy.svg'
import { GrayText, WhiteText } from '../TextComponents'
import { useKeyPress } from '../../../hooks/useKeyPress'
import { CustomInput, CustomLabel } from './FormComponents'
import { CurrencyId } from '../../../utils/Currency'
import { FiltersContext } from '../../../state/context/FiltersContext'
import { NotificationsContext } from '../../../state/context/NotificationsContext'
import { UserInfoContext } from '../../../state/context/UserInfoContext'
import { CustomIcon } from '../CustomIcon'
import { UserSettings } from '../../../api/settings/profile'
import { FilterToggle, FilterToggleProps } from './FilterToggle'

export interface CurrencyFilterOption {
  label: string
  value: CurrencyId
}

export enum FilterType {
  CURRENCY = 'currency',
  PRESETS = 'presets',
  PROJECTS = 'projects',
  REGIONS = 'regions',
  RECOMMENDATIONS = 'recommendations',
  RESOURCE_GROUPS = 'resourceGroups',
  RESOURCE_TYPES = 'resourceTypes',
  SERVICES = 'services',
  STANDARDS = 'standards',
  TAGS = 'tags',
  TIMEFRAME = 'timeframe',
  VENDORS = 'vendors'
}

export interface FilterMenu {
  type: FilterType
  value: FilterOption[]
  options: FilterOption[] | GroupedOptions[]
  onChange: (args: any) => void
}

export interface SelectedFilterOption {
  options: FilterOption[]
  clickHandler: (args: any) => void
  type: FilterType | string
}

const tagColors = [
  'bg-primary-500 hover:bg-primary-400',
  'bg-gray-500 hover:bg-gray-400',
  'bg-gray-400 hover:bg-gray-400',
  'bg-gray-400 hover:bg-gray-300',
  'bg-gray-300 hover:bg-gray-200',
  'bg-gray-200 hover:bg-gray-200',
  'bg-gray-200 hover:bg-gray-100',
  'bg-gray-100 hover:bg-gray-100',
  'bg-gray-100 hover:bg-gray-50'
]

interface FilterProps {
  selectedVendors: FilterOption[]
  setSelectedVendors: (vendors: FilterOption[]) => void
  filterMenuOptions: FilterMenu[]
  selectedOptions: SelectedFilterOption[]
  saveAction: (name: string) => void
  deleteAction: (name: string) => void
  selectedPreset: FilterOption | null
  setSelectedPreset: (option: FilterOption | null) => void
  savedPresets: string[]
  loading: boolean
  toggleFilter?: FilterToggleProps
}

export const Filters = ({
  selectedVendors,
  setSelectedVendors,
  filterMenuOptions,
  selectedOptions,
  saveAction,
  deleteAction,
  selectedPreset,
  setSelectedPreset,
  savedPresets,
  loading,
  toggleFilter
}: FilterProps) => {
  const { userSettings } = useContext(UserInfoContext)
  const { t } = useTranslation()
  const visibleVendorOptions = getVendorFilterOptions(userSettings, t)
  const { activeFilters, setActiveFilters, filtersOpen, setFiltersOpen } = useContext(FiltersContext)
  selectedOptions = selectedOptions.filter(option => option.type !== FilterType.CURRENCY)
  const [showTags, setShowTags] = useState(true)
  const { notificationsOpen } = useContext(NotificationsContext)
  const { setModal } = useContext<ModalState>(ModalContext)

  useEffect(() => {
    const customSelection = selectedOptions.filter(
      tag =>
        (tag.type === 'vendors' &&
          userSettings.visibleVendors.length > 0 &&
          tag.options.length !== userSettings.visibleVendors.length) ||
        (tag.type !== 'vendors' && tag.options.length > 0)
    )

    setActiveFilters(customSelection.length)
  }, [selectedOptions, userSettings.visibleVendors])

  useEffect(() => {
    userSettings.visibleVendors.length > 0 && !selectedVendors.length && setSelectedVendors(visibleVendorOptions)
  }, [selectedVendors])

  const openDeleteModal = (presetName: string) =>
    setModal({
      header: t('filters.modalDeletePresetHeader'),
      body: <DeletePresetModal deleteAction={deleteAction} selectedPreset={presetName} />
    })

  const vendorOptions = {
    type: FilterType.VENDORS,
    value: selectedVendors ?? visibleVendorOptions,
    options: visibleVendorOptions,
    onChange: (selected: FilterOption[]) => setSelectedVendors(selected)
  }

  const selectedVendorTags = {
    type: FilterType.VENDORS,
    options: vendorOptions.value,
    clickHandler: vendorOptions.onChange
  }

  return (
    <SideDrawer
      id={'filters-drawer'}
      drawerOpen={!notificationsOpen ? filtersOpen : false}
      setDrawerOpen={setFiltersOpen}
      paddingX={3}
      transitionStyles={'origin-right right-0'}
      content={
        <div className={'flex flex-col gap-4 max-w-130'}>
          {toggleFilter && (
            <FilterToggle type={toggleFilter.type} header={toggleFilter.header} options={toggleFilter.options} />
          )}
          {[vendorOptions, ...filterMenuOptions].map((select, index) => (
            <FilterSelect
              key={`filter-${select.type}`}
              type={select.type}
              header={t(`filters.${select.type}`)}
              isMulti={select.type !== FilterType.CURRENCY}
              placeholder={t('common.search')}
              value={select.value}
              indicatorColor={tagColors[index]}
              options={select.options}
              loading={loading}
              styles={{ menu: base => ({ ...base, position: 'relative' }) }}
              changeUserCurrency={select.type === FilterType.CURRENCY}
              // @ts-ignore
              onChange={selected => select.onChange(selected)}
            />
          ))}
          <FilterSelect
            deletePresetAction={openDeleteModal}
            type={FilterType.PRESETS}
            header={t('filters.presets')}
            value={selectedPreset}
            placeholder={t('common.search')}
            indicatorColor={'bg-primary-700 hover:bg-primary-600'}
            styles={{ menu: base => ({ ...base, position: 'relative' }) }}
            options={savedPresets.map(preset => ({
              label: preset,
              value: preset
            }))}
            // @ts-ignore
            onChange={selected => setSelectedPreset(selected)}
          />
          {activeFilters > 0 && (
            <div className={'flex w-full items-center justify-between'}>
              <Button
                buttonStyle={ButtonStyle.GHOST}
                size={ButtonSize.XSMALL}
                value={showTags ? t('filters.hideTags') : t('filters.showTags')}
                clickHandler={() => setShowTags(!showTags)}
              />
              <div className={'flex w-full justify-end'}>
                <Button
                  buttonStyle={ButtonStyle.SECONDARY}
                  size={ButtonSize.XSMALL}
                  value={t('filters.savePreset')}
                  clickHandler={() =>
                    setModal({
                      header: t('filters.modalHeader'),
                      body: (
                        <SavePresetModal
                          saveAction={saveAction}
                          selectedPreset={selectedPreset?.value ?? ''}
                          savedPresets={savedPresets}
                        />
                      )
                    })
                  }
                />
                <Button
                  buttonStyle={ButtonStyle.GHOST}
                  size={ButtonSize.XSMALL}
                  value={t('filters.clearAll')}
                  clickHandler={() => {
                    setSelectedPreset(null)
                    filterMenuOptions
                      .filter(option => option.type !== FilterType.CURRENCY)
                      .forEach(option => {
                        option.onChange([])
                      })
                  }}
                />
              </div>
            </div>
          )}
          <div className={'flex flex-col w-full divide-y divide-gray-500'}>
            {[selectedVendorTags, ...selectedOptions].map((tag, index) => (
              <div key={`tag-${tag.type}`} className={'flex flex-col gap-2 py-3'}>
                <GrayText className={'text-80'}>{t(`filters.${tag.type}`)}</GrayText>
                <TagsContainer
                  showTags={showTags}
                  color={tagColors[index]}
                  clickHandler={tag.clickHandler}
                  options={tag.options.filter(option => option.value !== filterSelectAllOption.value)}
                  type={tag.type}
                />
              </div>
            ))}
          </div>
        </div>
      }
    />
  )
}

interface TagContainerProps {
  showTags?: boolean
  clickHandler: (options: FilterOption[]) => void
  options: FilterOption[]
  type: string
  color: string
}

const TagsContainer = ({ showTags, clickHandler, options, type, color }: TagContainerProps) => {
  const { t } = useTranslation()

  const DeleteTagIcon = () => <CustomIcon path={closeIcon} styles={'w-2.5 h-2.5 bg-gray-50'} />

  return (
    <div className={'flex flex-wrap gap-2'}>
      {showTags ? (
        options.map((option, index) =>
          option.nest && option.nest.length > 0 ? (
            option.nest.map(tagValue => (
              <Tag
                className={color}
                key={`${type}-${option.value}-${tagValue.value}-${index}`}
                onClick={() => {
                  clickHandler(
                    option.nest!!.length > 1
                      ? (options = options
                          .map(o => ({
                            vendor: o.vendor,
                            value: o.value,
                            label: o.label,
                            nest: o.nest?.filter(n => {
                              if (o.vendor === option.vendor && o.value === option.value)
                                return n.value !== tagValue.value
                              else return true
                            })
                          }))
                          .filter(o => o.nest?.length !== 0))
                      : (options = options.filter(o => JSON.stringify(o) !== JSON.stringify(option)))
                  )
                }}
              >
                <div className={'flex divide-x-2'}>
                  <WhiteText className={'pr-2'}>{option.label}</WhiteText>
                  <WhiteText className={'pl-2'}>{tagValue.label}</WhiteText>
                </div>
                <DeleteTagIcon />
              </Tag>
            ))
          ) : (
            <Tag
              className={color}
              key={`${type}-${option.value}-${index}`}
              onClick={() => clickHandler(options.filter(o => o.value !== option.value))}
            >
              <WhiteText>{option.label}</WhiteText>
              <DeleteTagIcon />
            </Tag>
          )
        )
      ) : (
        <div className={'text-90 text-gray-100'}>
          {options.length} {t('filters.selected')}
        </div>
      )}
    </div>
  )
}

const Tag = styled.span`
  ${tw`inline-flex py-1 px-2.5 max-w-max rounded-xl text-80 hover:cursor-pointer gap-2.5 leading-none items-center justify-between`}
`

interface SavePresetModalProps {
  saveAction: (filterName: string) => void
  selectedPreset: string
  savedPresets: string[]
}

const SavePresetModal = ({ saveAction, selectedPreset, savedPresets }: SavePresetModalProps) => {
  const { setModal } = useContext(ModalContext)
  const [newPresetName, setNewPresetName] = useState<string>(selectedPreset)
  const { t } = useTranslation()
  const [requestInFlight, setRequestInFlight] = useState<boolean>(false)
  const [nameIsValid, setNameIsValid] = useState(false)

  useEffect(() => {
    const regExp = new RegExp('[a-öA-Ö0-9+?!@#$%^&*-_.:;]')
    setNameIsValid(regExp.test(newPresetName))
  }, [newPresetName])

  const saveButtonText = () =>
    nameIsValid && savedPresets.includes(newPresetName)
      ? t('filters.modalSaveOld', { name: newPresetName })
      : t('filters.modalSaveNew')

  const savePresetAction = () => {
    setRequestInFlight(true)
    saveAction(newPresetName)
  }

  useKeyPress(() => savePresetAction(), ['Enter'])

  return (
    <>
      <div>
        <CustomLabel>{t('filters.modalNameLabel')} *</CustomLabel>
        <CustomInput
          required={true}
          autoFocus={true}
          placeholder={''}
          type={'text'}
          value={newPresetName}
          onChange={e => setNewPresetName(e.currentTarget.value)}
        />
      </div>
      <ModalActions>
        <Button
          disabled={requestInFlight || !newPresetName || !nameIsValid}
          value={t('common.cancel')}
          clickHandler={() => setModal(null)}
          type={ButtonType.FORM}
          buttonStyle={ButtonStyle.SECONDARY}
        />
        <Button
          disabled={requestInFlight || !newPresetName || !nameIsValid}
          value={saveButtonText()}
          clickHandler={savePresetAction}
          type={ButtonType.FORM}
        />
      </ModalActions>
    </>
  )
}

interface DeletePresetModalProps {
  deleteAction: (filterName: string) => void
  selectedPreset: string
}

const DeletePresetModal = ({ deleteAction, selectedPreset }: DeletePresetModalProps) => {
  const { t } = useTranslation()
  const [requestInFlight, setRequestInFlight] = useState<boolean>(false)

  return (
    <>
      <div className={'flex flex-col gap-2'}>
        <ModalText>{t('filters.modalDeletePresetText')}:</ModalText>
        <div className={'text-center text-gray-50 font-semibold'}>{selectedPreset}</div>
      </div>
      <ModalActions>
        <Button
          disabled={requestInFlight}
          value={t('common.delete')}
          clickHandler={() => {
            setRequestInFlight(true)
            deleteAction(selectedPreset)
          }}
        />
      </ModalActions>
    </>
  )
}

export const getVendorFilterOptions = (userSettings: UserSettings, t: TFunction) => {
  return userSettings.visibleVendors.map(v => ({
    label: t(`vendors.${v}.short`),
    value: v
  }))
}
