import { CostSummaryTabResponse, TopCostsByLabel } from '../../../api/costs'
import { DataBlock } from '../../shared/containers/DataBlock'
import { useTranslation } from 'react-i18next'
import { CustomIcon, IconType } from '../../shared/CustomIcon'
import moneyCase from '../../../assets/svg/flat-colored/money-case.svg'
import service from '../../../assets/svg/flat-colored/service.svg'
import tag from '../../../assets/svg/flat-colored/tags.svg'
import styled from 'styled-components'
import tw from 'twin.macro'
import React from 'react'
import { Vendor } from '../../../utils/vendors'
import { TimeframeOption } from '../../../utils/classes'

interface SummaryTabProps {
  selectedVendors: Vendor[]
  selectedTimeframe: TimeframeOption
  loading: boolean
  data: CostSummaryTabResponse | null
}

export const CostsSummaryTab = ({ selectedVendors, selectedTimeframe, loading, data }: SummaryTabProps) => {
  return (
    <div className={'flex flex-col w-full gap-6'}>
      <div className={'flex flex-col gap-8 lg:flex-row lg:gap-12 w-full'}>
        <DataBlockColumn
          loading={loading}
          data={data?.lastMonth}
          selectedVendors={selectedVendors}
          heading={selectedTimeframe === TimeframeOption.LAST_MONTH ? 'lastMonth' : 'lastYear'}
        />
        <DataBlockColumn
          loading={loading}
          data={data?.currentMonth}
          selectedVendors={selectedVendors}
          heading={selectedTimeframe === TimeframeOption.LAST_MONTH ? 'currentMonth' : 'currentYear'}
        />
      </div>
    </div>
  )
}

interface MonthColumnProps {
  loading: boolean
  data?: TopCostsByLabel
  selectedVendors: Vendor[]
  heading: 'lastMonth' | 'currentMonth' | 'lastYear' | 'currentYear'
}

const DataBlockColumn = ({ loading, data, selectedVendors, heading }: MonthColumnProps) => {
  const { t } = useTranslation()

  const qaKey =
    heading === 'lastMonth'
      ? 'last-month'
      : heading === 'currentMonth'
        ? 'current-month'
        : heading === 'lastYear'
          ? 'last-year'
          : 'current-year'

  const projectPhrases = selectedVendors
    .map(vendor => t('costs.tabs.summary.byVendorProject.project', { context: vendor }))
    .join(' / ')

  return (
    <Column>
      <Heading>{t(`costs.tabs.summary.${heading}`)}</Heading>
      <Blocks>
        <DataBlock
          id={`qa-${qaKey}-costs-by-project`}
          headerIcon={<CustomIcon iconType={IconType.FLAT} path={moneyCase} styles={'w-9 h-9'} />}
          heading={projectPhrases}
          subHeading={
            heading === 'currentYear'
              ? t('costs.tabs.summary.changeFromLastYear')
              : heading === 'currentMonth'
                ? t('costs.tabs.summary.forecastedChange')
                : undefined
          }
          data={data?.projects ?? []}
          loading={loading}
          forecastIndicator={heading === 'currentMonth'}
          changeIndicator={heading === 'currentYear'}
          changeIndicatorTooltip={t('costs.tabs.summary.vsLastYearTooltip')}
        />
        <DataBlock
          id={`qa-${qaKey}-costs-by-service`}
          headerIcon={<CustomIcon iconType={IconType.FLAT} path={service} styles={'w-9 h-9'} />}
          heading={t('common.service_other')}
          subHeading={
            heading === 'currentYear'
              ? t('costs.tabs.summary.changeFromLastYear')
              : heading === 'currentMonth'
                ? t('costs.tabs.summary.changeFromLastMonth')
                : undefined
          }
          data={data?.services ?? []}
          loading={loading}
          changeIndicator={heading === 'currentYear' || heading === 'currentMonth'}
          changeIndicatorTooltip={
            heading === 'currentMonth'
              ? t('costs.tabs.summary.vsLastMonthTooltip')
              : t('costs.tabs.summary.vsLastYearTooltip')
          }
        />
        <DataBlock
          id={`qa-${qaKey}-costs-by-tag`}
          headerIcon={<CustomIcon iconType={IconType.FLAT} path={tag} styles={'w-9 h-9'} />}
          heading={t('costs.tabs.summary.tags')}
          subHeading={
            heading === 'currentYear'
              ? t('costs.tabs.summary.changeFromLastYear')
              : heading === 'currentMonth'
                ? t('costs.tabs.summary.changeFromLastMonth')
                : undefined
          }
          data={data?.tags ?? []}
          loading={loading}
          changeIndicator={heading === 'currentYear' || heading === 'currentMonth'}
          changeIndicatorTooltip={
            heading === 'currentMonth'
              ? t('costs.tabs.summary.vsLastMonthTooltip')
              : t('costs.tabs.summary.vsLastYearTooltip')
          }
        />
      </Blocks>
    </Column>
  )
}

const Column = styled.div`
  ${tw`flex flex-col gap-6 w-full`}
`

const Blocks = styled.div`
  ${tw`flex flex-col justify-between h-full gap-8 w-full lg:gap-12`}
`

const Heading = styled.div`
  ${tw`font-semibold text-gray-50 first-letter:capitalize text-112 md:text-125`}
`
