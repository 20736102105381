import React from 'react'
import { useTranslation } from 'react-i18next'
import { CustomIcon, IconType } from './CustomIcon'
import skull from '../../assets/svg/flat-colored/skull.svg'
import { GrayText } from './TextComponents'

interface MissingDataNotificationProps {
  justify?: 'center' | 'start'
  paddingY?: number
  icon?: boolean
  displayText?: string
  size?: 5 | 6
}

export const MissingDataNotification = ({
  justify = 'center',
  paddingY = 8,
  icon = true,
  displayText,
  size = 6
}: MissingDataNotificationProps) => {
  const { t } = useTranslation()

  return (
    <div
      className={`flex items-center justify-${justify} w-full py-${paddingY} ${size === 5 ? 'text-90 gap-2' : 'gap-3'}`}
    >
      {icon && <CustomIcon styles={`w-${size} h-${size}`} iconType={IconType.FLAT} path={skull} />}
      <GrayText>{displayText ?? t('error.noData')}</GrayText>
    </div>
  )
}
