import React, { useContext, useEffect, useState } from 'react'
import { TFunction, Trans, useTranslation } from 'react-i18next'
import {
  BUDGET_COST_ANOMALY_STATES,
  CostAnomalyNotification,
  deleteCostAnomalyNotificationsPermanently,
  getCostAnomalyNotifications,
  moveCostAnomalyNotificationsToBin,
  NotificationState,
  restoreCostAnomalyNotificationsToInbox,
  updateCostAnomalyNotificationsStatus
} from '../../api/notifications'
import { useCancelToken } from '../../api/client'
import { useErrorHandling } from '../../hooks/handleError'
import { CostNotifications } from './CostNotifications'
import { SideDrawer } from '../shared/layout/SideDrawer'
import { MessageContext, MessageState, MessageType, SpotterMessage } from '../../state/context/MessageContext'
import { Button, ButtonSize, ButtonStyle, ButtonType } from '../shared/buttons/Button'
import { toast } from 'react-toastify'
import { NotificationsContentCol, ViewNotificationCostsButton } from './SharedComponents'
import { NavbarLinkId } from '../../layout/navigation/Sidenav'
import { ModalContext, ModalState } from '../../state/context/ModalContext'
import { ModalActions, ModalText } from '../shared/modal/Modal'
import { MeatballsMenuOption } from '../shared/MeatballsMenu'
import { formatDate } from '../../utils/formats'
import { NotificationsContext } from '../../state/context/NotificationsContext'
import { NotificationsTabOptions, TabDescriptionBlock } from './NotificationsTabs'
import { CustomIcon, IconType } from '../shared/CustomIcon'
import closeIcon from '../../assets/svg/actions/close.svg'
import settingsIcon from '../../assets/svg/objects/cog.svg'
import { ToastActionComponent } from '../../global-message/GlobalMessage'
import { useNavigate } from 'react-router-dom'
import { Loading } from '../shared/Loading'
import { Heading3 } from '../shared/TextComponents'
import { UserInfoContext } from '../../state/context/UserInfoContext'

export enum NotificationsTab {
  INBOX = 'INBOX',
  DELETED = 'DELETED'
}

export const Notifications = () => {
  const handleError = useErrorHandling()
  const { t } = useTranslation()
  const { createCancelToken } = useCancelToken()
  const { modal } = useContext(ModalContext)
  const { setMessage } = useContext<MessageState>(MessageContext)
  const { authInfo } = useContext(UserInfoContext)
  const { notifications, setNotifications, notificationsOpen, setNotificationsOpen } = useContext(NotificationsContext)
  const [selectedTab, setSelectedTab] = useState<NotificationsTab>(NotificationsTab.INBOX)
  const [inboxNotifications, setInboxNotifications] = useState<CostAnomalyNotification[]>([])
  const [deletedNotifications, setDeletedNotifications] = useState<CostAnomalyNotification[]>([])
  const [loading, setLoading] = useState(false)

  const updateReadStatus = (notifications: CostAnomalyNotification[], status: NotificationState) => {
    const cancelToken = createCancelToken()
    updateCostAnomalyNotificationsStatus(notifications, status, cancelToken.token)
      .then(setNotifications)
      .catch(handleError)
  }

  useEffect(() => {
    if (!notifications.length) {
      setLoading(true)
      getCostAnomalyNotifications(createCancelToken().token)
        .then(setNotifications)
        .catch(handleError)
        .finally(() => setLoading(false))
    }
  }, [authInfo])

  useEffect(() => {
    notifications.find(n => n.notificationState === NotificationState.NEW) &&
      notifyFromNewCostAnomalies(setMessage, notifications, updateReadStatus, t)

    setInboxNotifications(notifications.filter(n => n.deletedAt === null))
    setDeletedNotifications(notifications.filter(n => n.deletedAt !== null))
  }, [notifications])

  return (
    <SideDrawer
      id={'notifications-drawer'}
      drawerOpen={notificationsOpen}
      setDrawerOpen={setNotificationsOpen}
      paddingX={3}
      transitionStyles={'origin-right right-0'}
      closeOnClickOutside={!modal}
      content={
        <NotificationsContentCol>
          <NotificationsHeader />
          <NotificationsContent
            loading={loading}
            setLoading={setLoading}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            inboxNotifications={inboxNotifications}
            deletedNotifications={deletedNotifications}
            updateReadStatus={updateReadStatus}
          />
        </NotificationsContentCol>
      }
    />
  )
}

const NotificationsHeader = () => {
  const { t } = useTranslation()
  const { setNotificationsOpen } = useContext(NotificationsContext)
  const navigate = useNavigate()

  return (
    <div className={'flex w-full items-center justify-between p-4 pt-0 gap-8'}>
      <Heading3>{t('sideNav.notifications')}</Heading3>
      <div className={'flex gap-4'}>
        <CustomIcon
          iconType={IconType.VECTOR}
          path={settingsIcon}
          styles={'w-5 h-5 bg-gray-100 hover:bg-gray-50'}
          onClick={() => {
            navigate('/settings/notifications')
            setNotificationsOpen(false)
          }}
        />
        <CustomIcon
          iconType={IconType.VECTOR}
          path={closeIcon}
          styles={'w-5 h-5 bg-gray-100 hover:bg-gray-50'}
          onClick={() => setNotificationsOpen(false)}
        />
      </div>
    </div>
  )
}

interface DrawerContentProps {
  loading: boolean
  setLoading: (loading: boolean) => void
  selectedTab: NotificationsTab
  setSelectedTab: (tab: NotificationsTab) => void
  inboxNotifications: CostAnomalyNotification[]
  deletedNotifications: CostAnomalyNotification[]
  updateReadStatus: (notifications: CostAnomalyNotification[], status: NotificationState) => void
}

const NotificationsContent = ({
  loading,
  setLoading,
  selectedTab,
  setSelectedTab,
  inboxNotifications,
  deletedNotifications,
  updateReadStatus
}: DrawerContentProps) => {
  const { t } = useTranslation()
  const handleError = useErrorHandling()
  const { setNotifications } = useContext(NotificationsContext)
  const { setMessage } = useContext<MessageState>(MessageContext)
  const { setModal } = useContext<ModalState>(ModalContext)
  const { createCancelToken } = useCancelToken()
  const [searchText, setSearchText] = useState<string>('')
  const [filteredNotifications, setFilteredNotifications] = useState<CostAnomalyNotification[]>(
    selectedTab === NotificationsTab.INBOX ? inboxNotifications : deletedNotifications
  )

  useEffect(() => {
    const tabNotifications = selectedTab === NotificationsTab.INBOX ? inboxNotifications : deletedNotifications
    setFilteredNotifications(
      tabNotifications.filter(
        notification =>
          notification.project.name.toLowerCase().includes(searchText.toLowerCase()) ||
          notification?.service?.toLowerCase().includes(searchText.toLowerCase()) ||
          notification.vendor.toLowerCase().includes(searchText.toLowerCase()) ||
          formatDate(new Date(notification.date)).toLowerCase().includes(searchText.toLowerCase())
      )
    )
  }, [deletedNotifications, inboxNotifications, searchText, selectedTab])

  const moveToBin = (notifications: CostAnomalyNotification[]) => {
    const cancelToken = createCancelToken()
    notifications.length > 1 && setLoading(true)
    moveCostAnomalyNotificationsToBin(notifications, cancelToken.token)
      .then(resp => {
        setNotifications(resp)
        setSearchText('')
        setMessage({
          type: MessageType.SUCCESS,
          message: t('notifications.costAnomalies.toastText.deleteSuccess')
        })
      })
      .catch(handleError)
      .finally(() => setLoading(false))
  }

  const restoreToInbox = (notifications: CostAnomalyNotification[]) => {
    const cancelToken = createCancelToken()
    notifications.length > 1 && setLoading(true)
    restoreCostAnomalyNotificationsToInbox(notifications, cancelToken.token)
      .then(resp => {
        setNotifications(resp)
        setMessage({
          type: MessageType.SUCCESS,
          message: t('notifications.costAnomalies.toastText.restoreSuccess')
        })
      })
      .catch(handleError)
      .finally(() => setLoading(false))
  }

  const deletePermanently = (notifications: CostAnomalyNotification[]) => {
    setModal({
      index: 1000,
      header: t('notifications.costAnomalies.costAnomaly.menuOptions.deletePermanently', {
        count: notifications.length
      }),
      body: (
        <>
          <div>
            <ModalText>
              <Trans>
                {t('notifications.costAnomalies.costAnomaly.permanentDeleteModal.deletionConfirmation', {
                  count: notifications.length
                })}
              </Trans>
            </ModalText>
          </div>
          <ModalActions>
            <Button
              size={ButtonSize.XSMALL}
              clickHandler={() => setModal(null)}
              type={ButtonType.FORM}
              value={t('common.cancel')}
              buttonStyle={ButtonStyle.SECONDARY}
            />
            <Button
              size={ButtonSize.XSMALL}
              type={ButtonType.FORM}
              clickHandler={() => {
                notifications.length > 1 && setLoading(true)
                deleteCostAnomalyNotificationsPermanently(notifications)
                  .then(resp => {
                    setNotifications(resp)
                    setSearchText('')
                    setMessage({
                      type: MessageType.SUCCESS,
                      message: t('notifications.costAnomalies.toastText.permanentDeleteSuccess')
                    })
                  })
                  .catch(handleError)
                  .finally(() => {
                    setLoading(false)
                    setModal(null)
                  })
              }}
              value={t('notifications.costAnomalies.costAnomaly.permanentDeleteModal.confirmDelete')}
            />
          </ModalActions>
        </>
      )
    })
  }

  const menuOptions = getMassOperationMenuOptions(
    t,
    loading,
    filteredNotifications,
    selectedTab,
    updateReadStatus,
    moveToBin,
    restoreToInbox,
    deletePermanently
  )

  return (
    <div className={'flex flex-col w-full divide-y divide-gray-500'}>
      <div>
        <NotificationsTabOptions
          inboxUnread={
            inboxNotifications.filter(
              n => n.notificationState === NotificationState.UNREAD || n.notificationState === NotificationState.NEW
            ).length
          }
          deletedUnread={deletedNotifications.filter(n => n.notificationState === NotificationState.UNREAD).length}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <TabDescriptionBlock
          selectedTab={selectedTab}
          searchText={searchText}
          setSearchText={setSearchText}
          menuOptions={menuOptions}
          notifications={filteredNotifications}
        />
      </div>
      {loading ? (
        <Loading height={64} paddingY={'2rem'} />
      ) : (
        <CostNotifications
          key={selectedTab}
          selectedTab={selectedTab}
          tabNotifications={selectedTab === NotificationsTab.INBOX ? inboxNotifications : deletedNotifications}
          filteredNotifications={filteredNotifications}
          updateReadStatus={updateReadStatus}
          moveToBin={moveToBin}
          restoreToInbox={restoreToInbox}
          deletePermanently={deletePermanently}
        />
      )}
    </div>
  )
}

const notifyFromNewCostAnomalies = (
  setMessage: (message: SpotterMessage) => void,
  notifications: CostAnomalyNotification[],
  updateReadStatus: (notifications: CostAnomalyNotification[], status: NotificationState) => void,
  t: TFunction
) => {
  const newNotifications = notifications.filter(n => n.notificationState === NotificationState.NEW)
  const newNotificationStates = newNotifications.map(n => n.anomalyState)
  const onlyBudgetAnomalies = newNotificationStates.every(s => BUDGET_COST_ANOMALY_STATES.includes(s))
  const notificationCount = newNotifications.length

  notificationCount > 0 &&
    setMessage({
      type: MessageType.WARNING,
      heading: onlyBudgetAnomalies
        ? t('notifications.costAnomalies.toastText.budgetAnomalyHeading', { count: notificationCount })
        : t('notifications.costAnomalies.toastText.serviceAnomalyHeading', { count: notificationCount }),
      message: onlyBudgetAnomalies
        ? t('notifications.costAnomalies.toastText.newBudgetAnomalies', {
            count: notificationCount,
            projectName: newNotifications[0].project.name,
            state: t(`notifications.costAnomalies.costAnomaly.state.${newNotificationStates[0]}`)
          })
        : notificationCount === 1
          ? t('notifications.costAnomalies.toastText.newAnomalies_one', {
              projectName: newNotifications[0].project.name,
              service: newNotifications[0].service,
              state: t(`notifications.costAnomalies.costAnomaly.state.${newNotifications[0].anomalyState}`)
            })
          : t('notifications.costAnomalies.toastText.newAnomalies', { count: newNotifications.length }),
      action:
        notificationCount === 1 ? (
          <ViewNotificationCostsButton notification={newNotifications[0]} updateReadState={updateReadStatus} />
        ) : (
          <ToastActionComponent
            onClick={() => {
              document.getElementById(NavbarLinkId.NOTIFICATIONS)?.click()
              toast.dismiss()
            }}
            label={t('notifications.costAnomalies.toastText.viewNotifications')}
          />
        )
    })
}

const getMassOperationMenuOptions = (
  t: TFunction,
  loading: boolean,
  filteredNotifications: CostAnomalyNotification[],
  selectedTab: NotificationsTab,
  updateReadStatus: (notifications: CostAnomalyNotification[], status: NotificationState) => void,
  moveToBin: (notifications: CostAnomalyNotification[]) => void,
  restoreToInbox: (notifications: CostAnomalyNotification[]) => void,
  deletePermanently: (notifications: CostAnomalyNotification[]) => void
): MeatballsMenuOption[] => {
  const menuOptions: MeatballsMenuOption[] = []

  menuOptions.push(
    {
      label: t('notifications.menuOptions.markAllAs', { status: NotificationState.READ.toLowerCase() }),
      isDisabled: filteredNotifications.every(n => n.notificationState === NotificationState.READ) || loading,
      clickHandler: () => updateReadStatus(filteredNotifications, NotificationState.READ)
    },
    {
      label: t('notifications.menuOptions.markAllAs', { status: NotificationState.UNREAD.toLowerCase() }),
      isDisabled: filteredNotifications.every(n => n.notificationState === NotificationState.UNREAD) || loading,
      clickHandler: () => updateReadStatus(filteredNotifications, NotificationState.UNREAD)
    }
  )

  selectedTab === NotificationsTab.INBOX
    ? menuOptions.push({
        label: t('notifications.menuOptions.deleteAll'),
        isDisabled: loading,
        clickHandler: () => moveToBin(filteredNotifications)
      })
    : menuOptions.push(
        {
          label: t('notifications.menuOptions.restoreAll'),
          isDisabled: loading,
          clickHandler: () => restoreToInbox(filteredNotifications)
        },
        {
          label: t('notifications.menuOptions.deleteAllPermanently'),
          isDisabled: loading,
          clickHandler: () => deletePermanently(filteredNotifications)
        }
      )

  return menuOptions
}
